import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  Input,
  NgZone,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailType } from "src/app/Classes/email-type";
import { ScheduleInfoDay } from "src/app/Classes/schedule-info-day";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { BaseChartDirective } from "ng2-charts";
import {
  ChartConfiguration,
  ChartEvent,
  ChartOptions,
  ChartType,
  ChartData,
  Chart,
} from "chart.js";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
function randomValues(count, min, max) {
  const delta = max - min;
  return Array.from({ length: count }).map(() => Math.random() * delta + min);
}
import AnnotationPlugin, {
  AnnotationOptions,
  AnnotationPluginOptions,
} from "chartjs-plugin-annotation";
@Component({
  selector: "app-schedule-information-modal",
  templateUrl: "./schedule-information-modal.component.html",
  styleUrls: ["./schedule-information-modal.component.scss"],
})
export class ScheduleInformationModalComponent {
  edited: boolean = false;
  history: any[] = [];
  averages: any = {
    hours: 0,
    omzet: 0,
    cash: 0,
    pin: 0,
    internet: 0,
    euroPerHour: 0,
  };

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  totals: any = {
    hours: 0,
    omzet: 0,
    cash: 0,
    pin: 0,
    internet: 0,
    euroPerHour: 0,
  };

  numberOfWeeks: number = 13;

  page: number = 0;
  @Input("day") day: ScheduleInfoDay;

  public graph = {
    data: [],
    layout: { width: 750, height: 500, title: "Hourly Omzet" },
  };

  lineChartOptions: ChartOptions<"line"> = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },

    plugins: {
      annotation: {
        annotations: [
          {
            drawTime: "afterDatasetsDraw",
            type: "line",

            scaleID: "y",
            borderColor: "black",
            borderDash: [6, 6],
            borderDashOffset: 0,
            borderWidth: 2,

            value: 0,
          },
          {
            drawTime: "afterDatasetsDraw",
            type: "line",

            scaleID: "y",
            borderColor: "blue",
            borderWidth: 1,
            value: 0,
          },
          {
            drawTime: "afterDatasetsDraw",
            type: "line",

            scaleID: "y",
            borderColor: "blue",
            borderWidth: 1,
            value: 0,
          },
          {
            drawTime: "afterDatasetsDraw",
            type: "line",

            scaleID: "y",
            borderColor: "blue",
            borderWidth: 1,
            value: 0,
          },
          {
            drawTime: "afterDatasetsDraw",
            type: "line",

            scaleID: "y",
            borderColor: "blue",
            borderWidth: 1,
            value: 0,
          },
        ],
      },
    },
  };
  public lineChartPlugins = [AnnotationPlugin];

  width: number;
  height: number;
  gradient: any;
  getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (
      !this.gradient ||
      this.width !== chartWidth ||
      this.height !== chartHeight
    ) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      this.width = chartWidth;
      this.height = chartHeight;
      this.gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      this.gradient.addColorStop(0, "rgba(255, 99, 132, 0.25)");
      this.gradient.addColorStop(0.5, "rgba(255, 99, 132, 0.5)");
      this.gradient.addColorStop(1, "rgba(255, 99, 132, 0.75)");
    }

    return this.gradient;
  }

  timeout2: any;

  numberOfWeekChanges($event) {
    this.edited = true;
    clearTimeout(this.timeout2);
    this.timeout2 = setTimeout(() => {
      this.numberOfWeeks = $event;
      this.getData();
    }, 500);
  }

  average(ctx) {
    const dataset = ctx.chart.data.datasets[0].data;
    console.log("calculating average");
    if (dataset.length === 0) return 0;
    return dataset.reduce((a, b) => a + b, 0) / dataset.length;
  }

  lineChartType: ChartType = "line";
  lineChartData: ChartData<"line"> = {
    datasets: [
      {
        data: [],
        label: "Omzet",
        borderColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return this.getGradient(ctx, chartArea);
        },
        fill: false,
        yAxisID: "y",
      },
    ],
    labels: [],
  };
  lineChartLegend = true;

  constructor(
    public activeModal: NgbActiveModal,
    public loading: LoadingService,
    public alert: AlertsService,
    private changeDetection: ChangeDetectorRef,
    public db: StaffAppDatabase
  ) {
    Chart.register(AnnotationPlugin);
  }

  ngOnInit() {
    this.getData();
  }
  save() {
    this.activeModal.close(this.day);
  }

  async getData() {
    this.loading.show();
    //what day of the week is it?
    let day = Number(this.day.id);

    //get the last of this day
    var last = new Date();
    last.setDate(last.getDate() - 1);
    while (last.getDay() != day) {
      last.setDate(last.getDate() - 1);
    }
    last.setHours(11, 0, 0, 0);
    var days = [];
    //get the last 13 weeks
    for (let i = 0; i < this.numberOfWeeks; i++) {
      let date = new Date(last);
      date.setDate(date.getDate() - i * 7);
      days.push({
        date: new Date(date),
        hours: [],
        omzet: {},
      });
    }

    var promises = [];

    //get the hours
    for (let i = 0; i < days.length; i++) {
      var promise = new Promise(async (resolve, reject) => {
        //get the hours for this day
        let date = days[i].date;
        let hours = await this.db.getHoursForDate2(this.db.bar, date);
        days[i].hours = hours;

        let omzet = await this.db.getTillForDate2(this.db.bar, date);
        days[i].omzet = omzet;
        resolve(null);
      });
      promises.push(promise);
    }

    await Promise.all(promises);
    this.history = [];
    days.reverse().forEach((day) => {
      if (!day.omzet || !day.omzet.end) {
        this.alert.nextEmit(
          AlertsService.error(
            "Error",
            "No omzet found for " + day.date.toLocaleDateString()
          )
        );
        return;
      }
      var history = {
        date: day.date,
        hours: day.hours.reduce((a: number, b: any) => a + b.total, 0),
        omzet:
          (Number(day.omzet?.end?.realCash) || 0) +
          (Number(day.omzet?.end?.realPin) || 0) +
          (Number(day.omzet?.internet) || 0),
        cash: Number(day.omzet?.end?.realCash) || 0,
        pin: Number(day.omzet?.end?.realPin) || 0,
        internet: Number(day.omzet?.internet) || 0,
        euroPerHour: 0,
      };
      history.euroPerHour = Math.round(history.omzet / history.hours);
      this.history.push(history);
    });

    //calculate averages
    this.totals.hours = Math.round(
      this.history.reduce((a, b) => a + b.hours, 0)
    );

    this.totals.omzet = Math.round(
      this.history.reduce((a, b) => a + b.omzet, 0)
    );

    this.totals.cash = Math.round(this.history.reduce((a, b) => a + b.cash, 0));

    this.totals.pin = Math.round(this.history.reduce((a, b) => a + b.pin, 0));

    this.totals.internet = Math.round(
      this.history.reduce((a, b) => a + b.internet, 0)
    );

    this.totals.euroPerHour = null;

    this.averages.hours = this.totals.hours / this.history.length;
    this.averages.omzet = this.totals.omzet / this.history.length;
    this.averages.cash = this.totals.cash / this.history.length;
    this.averages.pin = this.totals.pin / this.history.length;
    this.averages.internet = this.totals.internet / this.history.length;
    this.averages.euroPerHour = this.totals.omzet / this.totals.hours;

    //set the chart data
    this.lineChartData.datasets[0].data = [
      ...this.history.map((x) => {
        var d = new Date(x.date);
        return { y: x.omzet, x: d.getTime() };
      }),
    ];

    this.lineChartData.labels = [
      ...this.history.map((x) => {
        var d = new Date(x.date);
        return d.toLocaleDateString();
      }),
    ];

    //set the annotation
    this.lineChartOptions.plugins.annotation.annotations[0].value =
      this.averages.omzet;

    this.lineChartOptions.plugins.annotation.annotations[1].value =
      this.day.bonusRequirement;
    this.lineChartOptions.plugins.annotation.annotations[2].value =
      this.day.bonusRequirement * 2;
    this.lineChartOptions.plugins.annotation.annotations[3].value =
      this.day.bonusRequirement * 3;
    this.lineChartOptions.plugins.annotation.annotations[4].value =
      this.day.bonusRequirement * 4;

    console.log(this.lineChartOptions.plugins.annotation.annotations[0].value);
    this.changeDetection.detectChanges();

    console.log("Data Updated With: " + this.history.length + " days");
    //update the chart
    this.refresh_chart();
    this.loading.hide();
  }

  hourlyData: any[] = [];
  statistics: any[] = [];
  clickHourly() {
    if (this.hourlyData.length == 0) {
      this.getHourly();
    }
  }
  async getHourly() {
    //fill boxplot with random data

    this.loading.show();

    //what day of the week is it?
    let day = Number(this.day.id);

    //get the last of this day
    var last = new Date();
    last.setDate(last.getDate() - 1);
    while (last.getDay() != day) {
      last.setDate(last.getDate() - 1);
    }
    last.setHours(11, 0, 0, 0);
    var days = [];
    //get the last 13 weeks
    for (let i = 0; i < 13; i++) {
      let date = new Date(last);
      date.setDate(date.getDate() - i * 7);
      days.push({
        date: new Date(date),
        hours: [],
        omzet: {},
      });
    }

    var promises = [];
    var hourlyData = [];
    //get the hours
    for (let i = 0; i < days.length; i++) {
      var promise = new Promise(async (resolve, reject) => {
        //get the hours for this day
        let date = days[i].date;
        let hourly = await this.db.getDetailedTurnover(date);
        console.log(hourly);
        if (!hourly || hourly.length == 0) {
          resolve(null);
          return;
        }
        hourlyData.push(this.calculateHourly(hourly[0]));
        resolve(null);
      });
      promises.push(promise);
    }

    await Promise.all(promises);
    this.hourlyData = hourlyData;
    console.log(hourlyData);

    var hours = [];

    for (let i = 0; i < 24; i++) {
      hours.push({
        count: 0,
        data: [],
        hour: i,
      });
    }

    for (let day of hourlyData) {
      for (let hour of day) {
        if (!hour || !hour.omzet) {
          continue;
        }
        hours[hour.hour].data.push(hour.omzet);
        hours[hour.hour].count++;
      }
    }

    for (let hour of hours) {
      while (hour.data.length < days.length) {
        hour.data.push(0);
        hour.count++;
      }
    }

    //rearange so it starts at 2pm and ends at 2pm
    var temp = hours.slice(0, 14);
    hours = hours.slice(14);
    hours = hours.concat(temp);

    hours = hours.filter((x) => {
      return x.hour < 5 || x.hour > 19;
    });

    console.log(hours);

    this.statistics = hours.reverse();

    this.graph.data = this.statistics.map((hour) => {
      return {
        x: hour.data,
        type: "box",
        name: hour.hour + ":00",
      };
    });

    this.graph.data.push({
      // cutoff line

      x: [this.day.twostaffreq, this.day.twostaffreq], // X values for the line (same value for start and end to create a vertical line)
      y: ["20:00", "4:00"], // Y values for the line (spanning the entire y-axis range of the box plots)
      type: "scatter",
      mode: "lines+markers",
      name: "Two Staff Requirement",
      line: {
        color: "red",
        width: 2,
        dash: "dash",
      },
    });
    this.graph.data.push({
      // cutoff line

      x: [this.day.fourstaffreq, this.day.fourstaffreq], // X values for the line (same value for start and end to create a vertical line)
      y: ["20:00", "4:00"], // Y values for the line (spanning the entire y-axis range of the box plots)
      type: "scatter",
      mode: "lines+markers",
      name: "Four Staff Requirement",
      line: {
        color: "orange",
        width: 2,
        dash: "dash",
      },
    }); //what is width of the parent of the chart
    var boxplot = document.getElementById("plotly-plot");
    if (boxplot) {
      this.width = boxplot.clientWidth;
      this.graph.layout.width = this.width;

      this.graph.layout.height = boxplot.parentElement.clientHeight - 50;
    }

    this.changeDetection.detectChanges();
    console.log(this.statistics);
    this.loading.hide();
  }

  median(data: any[]) {
    if (data.length == 0) return 0;
    if (data.length == 1) return data[0];
    data.sort((a, b) => a - b);
    var half = Math.floor(data.length / 2);

    if (data.length % 2) return data[half];

    return (data[half - 1] + data[half]) / 2.0;
  }

  lowerQuartile(data: any[]) {
    if (data.length == 0) return 0;
    if (data.length == 1) return data[0];
    data.sort((a, b) => a - b);
    var half = Math.floor(data.length / 2);

    if (data.length % 2) return this.median(data.slice(0, half));

    return this.median(data.slice(0, half));
  }

  upperQuartile(data: any[]) {
    if (data.length == 0) return 0;
    if (data.length == 1) return data[0];
    data.sort((a, b) => a - b);
    var half = Math.floor(data.length / 2);

    if (data.length % 2) return this.median(data.slice(half + 1, data.length));

    return this.median(data.slice(half, data.length));
  }

  calculateHourly(data: any[]) {
    // .openDateTime : "2025-02-02T15:06:37Z"
    // .closeDateTime : "2025-02-02T15:06:37Z"

    var hourly = [];
    var currentHour;
    for (let sale of data) {
      var datecurrentHour = new Date(sale.openDateTime);
      if (!currentHour) {
        currentHour = {
          date: datecurrentHour,
          hour: datecurrentHour.getHours(),
          omzet: 0,
          quantity: 0,
          orders: 0,
        };
      }

      if (currentHour.hour != datecurrentHour.getHours()) {
        hourly.push(currentHour);
        currentHour = {
          date: datecurrentHour,
          hour: datecurrentHour.getHours(),
          omzet: 0,
          quantity: 0,
          orders: 0,
        };
      }

      currentHour.omzet += sale.items.reduce((a, b) => a + b.price, 0);
      currentHour.quantity += sale.items.reduce((a, b) => a + b.quantity, 0);
      currentHour.orders += 1;
    }

    hourly.push(currentHour);
    return hourly;
  }

  numberOfStaffChanged($event) {
    this.edited = true;
    if (!this.day.startTimes) {
      this.day.startTimes = [];
    }

    if (this.day.startTimes.length > $event) {
      this.day.startTimes = this.day.startTimes.slice(0, $event);
    }

    if (this.day.startTimes.length < $event) {
      for (let i = this.day.startTimes.length; i < $event; i++) {
        this.day.startTimes.push("20:00");
      }
    }

    //update ng
    this.day.startTimes = [...this.day.startTimes];
    this.changeDetection.detectChanges();
  }

  timeout: any;
  startTimeChanged(event, index) {
    this.edited = true;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.length != 5) {
        this.alert.nextEmit(
          AlertsService.error("Error", "Time should be in HH:MM format")
        );
        this.day.startTimes[index] = "20:00";
        return;
      }
      if (!event.match(/^[0-9]{2}:[0-9]{2}$/)) {
        this.alert.nextEmit(
          AlertsService.error("Error", "Time should be in HH:MM format")
        );
        this.day.startTimes[index] = "20:00";
        return;
      }
      this.day.startTimes[index] = event;
    }, 500);
  }

  close() {
    this.activeModal.dismiss();
  }

  refresh_chart() {
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = [...this.lineChartData.labels];
        this.chart.chart.config.data.datasets = [
          ...this.lineChartData.datasets,
        ];
        //update average annotation
        this.chart.chart.config.options.plugins.annotation.annotations[0].value =
          this.averages.omzet;
        this.chart.chart.config.options.plugins.annotation.annotations[1].value =
          this.day.bonusRequirement;
        this.chart.chart.config.options.plugins.annotation.annotations[2].value =
          this.day.bonusRequirement * 2;
        this.chart.chart.config.options.plugins.annotation.annotations[3].value =
          this.day.bonusRequirement * 3;
        this.chart.chart.config.options.plugins.annotation.annotations[4].value =
          this.day.bonusRequirement * 4;

        this.chart.chart.update();
      }
    });
  }
}
