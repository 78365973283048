import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailType } from "src/app/Classes/email-type";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { EmailModalComponent } from "../email-modal/email-modal.component";

@Component({
  selector: "app-emails",
  templateUrl: "./emails.component.html",
  styleUrls: ["./emails.component.scss"],
})
export class EmailsComponent {
  sortBy: {
    key: string;
    reverse: boolean;
  } = {
    key: "name",
    reverse: true,
  };

  emailTypes: EmailType[] = [
    {
      id: "telling",
      name: "Telling",
      active: true,
      description: "Messages regarding telling",
      users: [],
    },
    {
      id: "declarations",
      name: "Declaration",
      active: true,
      description: "Messages regarding a declaration",
      users: [],
    },
    {
      id: "schedule",
      name: "Schedule",
      active: true,
      description: "Messages regarding a schedule",
      users: [],
    },
    {
      id: "hours",
      name: "Hours",
      active: true,
      description: "Messages regarding hours",
      users: [],
    },
    {
      id: "other",
      name: "Other",
      active: true,
      description: "Messages regarding other",
      users: [],
    },
  ];
  users: any[] = [];

  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public modal: NgbModal,
    public db: StaffAppDatabase
  ) {
    this.getData();
  }

  async getData() {
    this.loading.show();
    this.emailTypes = await this.db.getEmailTypes();

    this.users = await this.db.getStaffAsync().then((staff) => {
      return staff
        .filter((s: any) => s.access > 3)
        .map((s: any) => {
          return {
            id: s.uid,
            displayName: s.firstName + " " + s.lastName,
            email: s.workemail || s.email,
            active: false,
            access: s.access,
          };
        });
    });

    console.log("users", this.users);
    console.log("emailTypes", this.emailTypes);
    this.loading.hide();
  }

  openItem(item: EmailType) {
    console.log("openItem", item);
    const modal = this.modal.open(EmailModalComponent, {
      size: "lg",
    });
    modal.componentInstance.emailType = item;
    modal.componentInstance.users = this.users.map((u) => {
      u.active = item.users.find((i) => i.id === u.id)?.active || false;
      return u;
    });
    modal.result.then(
      (result) => {
        console.log("result", result);
        if (result) {
          this.db.saveEmailType(result).then(() => {
            this.getData();
          });
        }
      },
      (dismiss) => {
        console.log("dismiss", dismiss);
      }
    );
  }

  setSortBy(key, changeDirection = true) {
    console.log("sortuing on", key, changeDirection);
    if (this.sortBy.key === key && !changeDirection) {
    } else if (this.sortBy.key === key) {
      this.sortBy.reverse = !this.sortBy.reverse;
    } else {
      this.sortBy.key = key;
      this.sortBy.reverse = false;
    }

    this.emailTypes = this.emailTypes.sort((a, b) => {
      if (this.sortBy.key.includes(".length")) {
        var key = this.sortBy.key.split(".")[0];
        if (a[key].length < b[key].length) {
          return this.sortBy.reverse ? 1 : -1;
        } else if (a[key].length > b[key].length) {
          return this.sortBy.reverse ? -1 : 1;
        } else {
          return 0;
        }
      }

      if (a[this.sortBy.key] === undefined || a[this.sortBy.key] === null) {
        return 1;
      }
      if (b[this.sortBy.key] === undefined || b[this.sortBy.key] === null) {
        return -1;
      }

      if (a[this.sortBy.key] < b[this.sortBy.key]) {
        return this.sortBy.reverse ? 1 : -1;
      } else if (a[this.sortBy.key] > b[this.sortBy.key]) {
        return this.sortBy.reverse ? -1 : 1;
      } else {
        return 0;
      }
    });
  }
}
