export class ScheduleInfoDay {
  id: string; //id of the day i.e. 1 for monday, 2 for tuesday etc
  name: string; //name of the day i.e. monday, tuesday etc
  numberOfStaff: number; //number of staff needed
  startTimes: string[]; // times HH:MM
  openTime: string; //time HH:MM
  closeTime: string; //time HH:MM
  bonusRequirement: number; //financial requirement to be met to get bonus. total sales/numberofstaffworked >= bonusRequirement
  bonusAmount: number; // amount of hours to be added if bonusRequirement is met
  requiresSocialHygiene: boolean; //false or true
  twostaffreq: number = 125;
  fourstaffreq: number = 250;

  constructor() {}

  from(
    id: string,
    name: string,
    numberOfStaff: number,
    startTimes: string[],
    openTime: string,
    closeTime: string,
    bonusRequirement: number,
    bonusAmount: number,
    requiresSocialHygiene: boolean,
    twostaffreq: number = 125
  ) {
    this.id = id;
    this.name = name;
    this.numberOfStaff = numberOfStaff;
    this.startTimes = startTimes;
    this.openTime = openTime;
    this.closeTime = closeTime;
    this.bonusRequirement = bonusRequirement;
    this.bonusAmount = bonusAmount;
    this.requiresSocialHygiene = requiresSocialHygiene;
    this.twostaffreq = 125;
    return this;
  }

  pretty() {
    // return relevant information in a pretty format
    var prettyString = "Day: " + this.name + "\n";
    prettyString += "Number of Staff: " + this.numberOfStaff + "\n";
    prettyString += "Start Times: " + this.startTimes + "\n";
    prettyString += "Open Time: " + this.openTime + "\n";
    prettyString += "Close Time: " + this.closeTime + "\n";
    prettyString += "Bonus Requirement: " + this.bonusRequirement + "e\n";
    prettyString += "Bonus Amount: " + this.bonusAmount + "h\n";
    prettyString +=
      "Requires Social Hygiene: " + this.requiresSocialHygiene + "\n";
    return prettyString;
  }
}
