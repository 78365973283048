<div class="flex-rows">
  <div class="flex-columns">
    <h1>Manager</h1>

    <select #select [(value)]="manager" (change)="managerChanged(select)">
      <option disabled>Select Manager</option>
      <option *ngFor="let s of staff" [value]="s.key">
        <p *ngIf="s.firstName && s.lastName">
          {{ s.firstName }} {{ s.lastName }}
        </p>
        <p *ngIf="!s.firstName || !s.lastName">{{ s.displayName }}</p>
      </option>
    </select>

    <br />

    <button mat-raised-button (click)="saveManager()">Save</button>
  </div>
  <div class="flex-columns">
    <h1>Till Information</h1>
    <div *ngIf="tillInformation">
      <h3 style="width: 100%; padding-top: 5px; text-align: center">Details</h3>
      <form (ngSubmit)="save()">
        <div class="edit-row">
          <div class="edit-desc">Url:</div>
          <input class="edit" [(ngModel)]="tillInformation.url" name="URL" />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Username:</div>
          <input
            class="edit"
            [(ngModel)]="tillInformation.username"
            name="Username"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Token:</div>
          <input
            class="edit"
            [(ngModel)]="tillInformation.token"
            name="Token"
          />
        </div>

        <app-input
          class="edit-row"
          type="text"
          name="Path"
          (changed)="tillInformation.path = $event"
          [model]="tillInformation.path"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="Database"
          (changed)="tillInformation.database = $event"
          [model]="tillInformation.database"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="SalesAreaId"
          (changed)="tillInformation.salesAreaId = $event"
          [model]="tillInformation.salesAreaId"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="CashReference"
          (changed)="tillInformation.cashReference = $event"
          [model]="tillInformation.cashReference"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="InternetReference"
          (changed)="tillInformation.internetReference = $event"
          [model]="tillInformation.internetReference"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="PinReference"
          (changed)="tillInformation.pinReference = $event"
          [model]="tillInformation.pinReference"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="StaffReference"
          (changed)="tillInformation.staffReference = $event"
          [model]="tillInformation.staffReference"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="VIP Half"
          (changed)="tillInformation.vipHalf = $event"
          [model]="tillInformation.vipHalf"
        ></app-input>
        <app-input
          class="edit-row"
          type="text"
          name="VIP Hour"
          (changed)="tillInformation.vipHour = $event"
          [model]="tillInformation.vipHour"
        ></app-input>
      </form>
      <button (click)="cancelTill()">Cancel/Reset</button>
      <button (click)="saveTill()">Save</button>
    </div>
  </div>
  <div class="flex-columns">
    <h1>Calendar Information</h1>
    <div *ngIf="vipInformation">
      <h3 style="width: 100%; padding-top: 5px; text-align: center">Details</h3>
      <form (ngSubmit)="save()">
        <div class="edit-row">
          <div class="edit-desc">Url:</div>
          <input class="edit" [(ngModel)]="vipInformation.website" name="URL" />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Key:</div>
          <input class="edit" [(ngModel)]="vipInformation.key" name="Key" />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Password:</div>
          <input
            class="edit"
            [(ngModel)]="vipInformation.password"
            name="Password"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Secret:</div>
          <input
            class="edit"
            [(ngModel)]="vipInformation.secret"
            name="Secret"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Username:</div>
          <input
            class="edit"
            [(ngModel)]="vipInformation.username"
            name="Username"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">CustomerId:</div>
          <input
            class="edit"
            [(ngModel)]="vipInformation.customerId"
            name="CustomerId"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">AgendaId:</div>
          <input
            class="edit"
            [(ngModel)]="vipInformation.agendaId"
            name="AgendaId"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">AppointmentType:</div>
          <input
            class="edit"
            [(ngModel)]="vipInformation.appointmentType"
            name="AppointmentType"
          />
        </div>
      </form>
      <button (click)="cancelVIP()">Cancel/Reset</button>
      <button (click)="saveVIP()">Save</button>
    </div>
  </div>
  <div class="flex-columns">
    <h1>Bedrijfs Gegevens</h1>
    <div *ngIf="info">
      <div class="edit-row">
        <div class="edit-desc">Name:</div>
        <input class="edit" [(ngModel)]="info.name" placeholder="BarName" />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Street:</div>
        <input
          class="edit"
          [(ngModel)]="info.address"
          placeholder="Street Name"
        />
      </div>
      <div class="edit-row">
        <div class="edit-desc">Street Number:</div>
        <input
          class="edit"
          [(ngModel)]="info.number"
          placeholder="Street Number"
        />
      </div>
      <div class="edit-row">
        <div class="edit-desc">Post Code:</div>
        <input class="edit" [(ngModel)]="info.zip" placeholder="PostCode" />
      </div>
      <div class="edit-row">
        <div class="edit-desc">City:</div>
        <input class="edit" [(ngModel)]="info.city" placeholder="City" />
      </div>
      <div class="edit-row">
        <div class="edit-desc">Country:</div>
        <input class="edit" [(ngModel)]="info.country" placeholder="Country" />
      </div>
      <div class="edit-row">
        <div class="edit-desc">Email:</div>
        <input class="edit" [(ngModel)]="info.email" placeholder="Email" />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Factuur Email:</div>
        <input
          class="edit"
          [(ngModel)]="info.facturenemail"
          placeholder="Email"
        />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Accountant Email:</div>
        <input
          class="edit"
          [(ngModel)]="info.accountantemail"
          placeholder="Email"
        />
      </div>
      <div class="edit-row">
        <div class="edit-desc">IBAN:</div>
        <input class="edit" [(ngModel)]="info.iban" placeholder="PayMe" />
      </div>
      <div class="edit-row">
        <div class="edit-desc">KVK:</div>
        <input class="edit" [(ngModel)]="info.kvk" placeholder="Company" />
      </div>
      <div class="edit-row">
        <div class="edit-desc">BTW:</div>
        <input class="edit" [(ngModel)]="info.btw" placeholder="TaxMe" />
      </div>
    </div>
    <button (click)="cancelInfo()">Cancel/Reset</button>
    <button (click)="saveInfo()">Save</button>
  </div>
  <div class="flex-columns">
    <h1>
      Calendar API
      <i
        (click)="(ping)"
        style="font-size: medium"
        [style.color]="pinged ? 'green' : 'red'"
        [class]="pinged ? 'fas fa-thumbs-up' : 'fas fa-thumbs-down'"
      ></i>
    </h1>
    <div *ngIf="calendarAPI">
      <h3 style="width: 100%; padding-top: 5px; text-align: center">Details</h3>
      <form (ngSubmit)="save()">
        <div class="edit-row">
          <div class="edit-desc">Functions Url:</div>
          <input class="edit" [(ngModel)]="calendarAPI.functions" name="URL" />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Company:</div>
          <input class="edit" [(ngModel)]="calendarAPI.company" name="Key" />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Location:</div>
          <input
            class="edit"
            [(ngModel)]="calendarAPI.location"
            name="Location"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Emulator:</div>
          <input
            class="edit"
            [(ngModel)]="calendarAPI.emulator"
            name="Emulator"
          />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Email:</div>
          <input class="edit" [(ngModel)]="calendarAPI.email" name="Password" />
        </div>

        <div class="edit-row">
          <div class="edit-desc">Secret:</div>
          <input
            class="edit"
            [(ngModel)]="calendarAPI.password"
            name="Secret"
          />
        </div>
      </form>
      <button (click)="cancelCalendar()">Cancel/Reset</button>
      <button (click)="saveCalendar()">Save</button>
    </div>
  </div>
</div>
