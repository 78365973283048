<div class="my-table-header" style="padding: 0px 14px">
  <div class="my-table-item-imp">
    <div>Day</div>
  </div>
  <div class="my-table-item-imp">
    <div>Open</div>
  </div>
  <div class="my-table-item-imp">
    <div>Close</div>
  </div>
  <div class="my-table-item-imp">
    <div>No: Staff</div>
  </div>

  <div class="my-table-item-imp">
    <div>Social</div>
  </div>

  <div class="my-table-item-imp">
    <div>Bonus</div>
  </div>
</div>

<div class="my-table">
  <div
    *ngFor="let s of scheduleInfoDays"
    class="my-table-item"
    (click)="openItem(s)"
  >
    <div class="my-table-item-imp">
      {{ s.name }}
    </div>

    <div class="my-table-item-imp">
      {{ s.openTime }}
    </div>
    <div class="my-table-item-imp">
      {{ s.closeTime }}
    </div>

    <div class="my-table-item-imp">
      {{ s.numberOfStaff }}
    </div>

    <div class="my-table-item-imp">
      {{ s.requiresSocialHygiene }}
    </div>

    <div class="my-table-item-imp">
      <div>{{ s.bonusAmount | currency : "EUR" }}</div>
    </div>
  </div>
</div>
