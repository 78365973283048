import { EmailUsers } from "./email-users";

export class EmailType {
  id: string;
  name: string;
  active: boolean;
  description: string;

  users: EmailUsers[] = [];
}
