import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ScheduleInfoDay } from "src/app/Classes/schedule-info-day";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { ScheduleInformationModalComponent } from "../schedule-information-modal/schedule-information-modal.component";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-schedule-information",
  templateUrl: "./schedule-information.component.html",
  styleUrls: ["./schedule-information.component.scss"],
})
export class ScheduleInformationComponent {
  scheduleInfoDays: any[] = [];
  scheduleInfoDayOG: any[] = [];
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public modal: NgbModal,
    public db: StaffAppDatabase,
    public auth: Auth
  ) {
    this.getData();
  }

  async getData() {
    this.loading.show();
    try {
      var days = await this.db.getScheduleInfoDays();
      console.log(days);
      if (!days.length) {
        if (confirm("Would you like to create default schedule information?")) {
          await this.db.createDefaultScheduleInfoDays();
          await this.getData();
          return;
        }

        this.alert.nextEmit(
          AlertsService.error("Error", "No schedule information found")
        );
        this.loading.hide();
        return;
      }
      this.scheduleInfoDays = days;
      this.scheduleInfoDayOG = JSON.parse(
        JSON.stringify(this.scheduleInfoDays)
      );
    } catch (error) {
      this.alert.nextEmit(AlertsService.error("Error", error));
    }
    this.loading.hide();
  }

  save(day: ScheduleInfoDay) {
    this.loading.show();
    this.db.changedRecord(
      this.db.bar,
      this.auth.user.uid,
      this.scheduleInfoDays,
      "ScheduleInfo",
      "scheduleInfo",
      "edit",
      "instellingen/scheduleInfo",
      "scheduleInfo edited",
      this.db.getDifferences(this.scheduleInfoDays, this.scheduleInfoDayOG)
    );
    this.db.saveScheduleInfoDay(day).then(
      (res) => {
        this.loading.hide();
        this.alert.nextEmit(
          AlertsService.success("Success", "Schedule information updated")
        );
        this.getData();
      },
      (err) => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.error("Error", err));
        this.getData();
      }
    );
  }
  openItem(day: ScheduleInfoDay) {
    console.log(day);
    const modalRef = this.modal.open(ScheduleInformationModalComponent, {
      size: "xl",
    });

    modalRef.componentInstance.day = day;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.save(result);
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }
}
