import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "accessLevels",
})
export class AccessLevelsPipe implements PipeTransform {
  accessLevels: any[] = [
    { value: 0, name: "Guest" },
    { value: 1, name: "Staff" },
    { value: 2, name: "Assistant Manager / Junior Developer" },
    { value: 3, name: "Manager" },
    { value: 4, name: "Regional Manager" },
    { value: 5, name: "Hoofdkantoor" },
    { value: 6, name: "Directors" },
    { value: 7, name: "Senior Developer" },
    { value: 8, name: "Duncan" },
  ];
  transform(value: number, ...args: unknown[]): unknown {
    return this.accessLevels[value].name || "Unknown";
  }
}
