export class DeclarationReceipt {
  id: string;
  amount: number;
  date: number;
  description: string;
  merchant: string;
  declarationId: string;
  imageDownloadUrl: string;
  category: string;
  categoryOther: string;
  amount21: number;
  amount9: number;
  constructor() {
    this.date = new Date().getTime();
    this.category = "";
  }

  sum() {
    this.amount = this.amount21 + this.amount9;
    return this.amount;
  }
}
