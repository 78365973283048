import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InvoiceCompany } from "src/app/Classes/invoice-company";
import { InvoiceProduct } from "src/app/Classes/invoice-product";
import { InvoiceSave } from "src/app/Classes/invoice-save";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-invoice-maker",
  templateUrl: "./invoice-maker.component.html",
  styleUrls: ["./invoice-maker.component.css"],
})
export class InvoiceMakerComponent implements OnInit {
  saves: InvoiceSave[] = [];
  invoice: InvoiceSave = null;
  isEdit = false;
  product: InvoiceProduct;
  base64Image: string = null;
  viewInvoice = false;
  companiesStrings = [];

  companies: InvoiceCompany[] = [];
  company: InvoiceCompany;

  @ViewChild("pdf") pdfTable!: ElementRef;

  constructor(
    private alert: AlertsService,
    private loading: LoadingService,
    private db: StaffAppDatabase,
    private modal: NgbModal,
    private auth: Auth
  ) {}

  ngOnInit(): void {
    this.loading.nextEmit("on");
    this.getSaves();
  }

  async makePDF() {
    const canvas = await html2canvas(this.pdfTable.nativeElement);
    const imgData = canvas.toDataURL("image/png");
    const doc = new jsPDF();
    doc.addImage(imgData, "PNG", 0, 0, 210, 297);
    doc.save("invoice.pdf");
  }

  addCompany($event, isNew = true, company = null) {
    if (isNew) {
      this.company = new InvoiceCompany();
    } else {
      this.company = company;
    }
    console.log(this.company);

    this.modal
      .open($event, { size: "lg" })
      .result.then((a) => {
        console.log("result", a);
        switch (a) {
          case "save":
            this.saveCompany();
            return;
          case "delete":
            this.deleteCompany();
            return;
        }
      })
      .catch((e) => {
        console.log("dismissed");
      });
  }

  newSave() {
    this.isEdit = true;
    this.invoice = new InvoiceSave();
    this.invoice.company = this.companies[0];
    this.invoice.client = this.companies[1];

    console.log(this.invoice);
  }

  saveInvoice() {
    this.db
      .saveInvoice(this.auth.accessUser.uid, this.invoice)
      .then((a) => {
        this.alert.nextEmit(AlertsService.good("Invoice Saved", "Saved!"));
        this.invoice = null;
        this.isEdit = false;
        this.getSaves();
      })
      .catch((e) => {
        this.alert.nextEmit(AlertsService.error("Invoice not saved", e));
      });
  }

  async getSaves() {
    this.companies = await this.db
      .getInvoiceCompanies()
      .then((a) => a.map((b) => Object.assign(new InvoiceCompany(), b)))
      .catch((e) => null);
    this.companiesStrings = this.companies.map((a) => a.name);
    this.saves = await this.db.getInvoices(this.auth.accessUser.uid);
    this.loading.nextEmit(null);
    console.log(this.saves, this.companiesStrings);
  }

  logoChanged(event) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.company.logo = reader.result as string;
    };
  }

  async deleteInvoice() {
    if (!this.invoice.key) {
      this.invoice = null;
      this.isEdit = false;
      return;
    }
    const res = await this.db.deleteInvoice(
      this.auth.accessUser.uid,
      this.invoice.key
    );
    if (res) {
      this.alert.nextEmit(AlertsService.error("Invoice not deleted", res));
      return;
    }
    this.alert.nextEmit(AlertsService.good("Invoice Deleted", "Deleted!"));
    this.invoice = null;
    this.isEdit = false;
    this.getSaves();
  }
  chooseLogo() {}

  companyChanged(isCompany: boolean, id: string) {
    console.log(isCompany, id);

    setTimeout(() => {
      if (isCompany) {
        this.invoice.company = this.companies.find((x) => x.name == id);
      } else {
        this.invoice.client = this.companies.find((x) => x.name == id);
      }
    }, 10);
  }

  dateChanged(isDue: boolean, date: number) {
    const d = new Date(date);
    if (isDue) {
      this.invoice.dueDate = d.getTime();
    } else {
      this.invoice.date = d.getTime();
    }
  }

  vatTypes = [0, 9, 21];
  newProduct() {
    this.product = new InvoiceProduct();
  }

  addProduct() {
    this.invoice.products.push(this.product);
    this.invoice.calculateTotal();
    this.product = null;
  }

  removeProduct(product: InvoiceProduct) {
    this.invoice.products = this.invoice.products.filter((a) => a !== product);
    this.invoice.calculateTotal();
  }

  async saveCompany() {
    const res = await this.db
      .saveInvoiceCompany(this.company)
      .then((a) => null)
      .catch((e) => e);
    if (!res) {
      this.alert.nextEmit(AlertsService.good("Company Saved", "Saved!"));
      this.company = null;
      this.modal.dismissAll();

      this.getSaves();
      return;
    } else {
      this.alert.nextEmit(AlertsService.error("Company not saved", res));
    }
  }
  async deleteCompany() {
    const res = await this.db
      .deleteInvoiceCompany(this.company.id)
      .then((a) => null)
      .catch((e) => e);
    if (!res) {
      this.alert.nextEmit(AlertsService.good("Company Deleted", "Saved!"));
      this.company = null;
      this.modal.dismissAll();

      this.getSaves();
      return;
    } else {
      this.alert.nextEmit(AlertsService.error("Company not deleted", res));
    }
  }
}
