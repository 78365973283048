<!-- So list of orders, by date and status-->
<!-- Info of each order-->
<!-- Actions, mark is archived, mark as ordered, if not ordered can edit-->
<app-pdfmaker
  *ngIf="showInvoice"
  [type]="'itemsorder'"
  [showEmbellage]="false"
  [data]="invoice"
></app-pdfmaker>
<div class="flex-rows">
  <div class="flex-columns">
    <h1 class="flex-title">Orders</h1>
    <div *ngFor="let order of orders">
      <div
        class="button-row"
        style="margin-top: 10px"
        (click)="openOrder(order)"
      >
        {{ order.dateTime | date : "yyyy/MM/dd - H:mm" }} {{ order.status }}
      </div>
    </div>
  </div>

  <div class="flex-columns">
    <h1 class="flex-title">Info</h1>
    <div *ngIf="order">
      <h3>{{ order.status }}</h3>
      <p>Comments: {{ order.comments }}</p>
      <h3>{{ order.dateTime | date : "yyyy/MM/dd - H:mm" }}</h3>
      <table>
        <thead>
          <tr>
            <th class="tg-0lax-1">name</th>
            <th class="tg-0lax">default</th>
            <th class="tg-0lax">stock</th>
            <th class="tg-0lax">modified</th>
            <th class="tg-0lax">to order</th>
            <th class="tg-0lax">arrived</th>
          </tr>
        </thead>
      </table>

      <table style="max-height: 800px; overflow: auto">
        <tr *ngFor="let item of order.items">
          <th class="tg-0lax-1">{{ item.name }}</th>
          <th class="tg-0lax">{{ item.defaultQuantity }}</th>
          <th class="tg-0lax">{{ item.stockQuantity }}</th>
          <th class="tg-0lax">
            <input
              *ngIf="order.status === 'Requested'"
              style="max-width: 70px"
              type="number"
              (change)="
                item.ordered =
                  item.modifier >= 0
                    ? item.modifier
                    : item.defaultQuantity - item.stockQuantity
              "
              [(ngModel)]="item.modifier"
            />
            <div *ngIf="order.status !== 'Requested'">{{ item.modifier }}</div>
          </th>
          <th class="tg-0lax">{{ item.ordered }}</th>
          <th class="tg-0lax">{{ item.arrived }}</th>
        </tr>
      </table>
    </div>
  </div>

  <div class="flex-columns">
    <h1 class="flex-title">Actions</h1>
    <div class="button-row" *ngIf="order">
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Stock Counted'"
        (click)="orderOrder()"
      >
        Set Ordered
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Requested'"
        (click)="saveOrder()"
      >
        Save Modifications
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Requested'"
        (click)="order.status = 'Ordered'; saveOrder()"
      >
        Mark for Loading Van
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Counted In' || order.status === 'Ordered'"
        (click)="saveAndMarkCompleted()"
      >
        Save & Mark Completed
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Archived'"
        (click)="makeInvoice(order)"
      >
        Make Invoice
      </button>
      <button mat-raised-button (click)="deleteOrder(order)">
        Delete Order
      </button>
    </div>
  </div>
</div>
