<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">
    {{ emailType.name || "Email Type" }}
  </h3>
</div>

<!--Editing-->
<div class="staff-info-content">
  <div class="edit-row">
    <div class="edit-desc">Name:</div>
    <input class="edit" disabled [ngModel]="emailType.name" name="uid" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Description</div>
    <input
      class="edit"
      (ngModelChange)="edited = true"
      [(ngModel)]="emailType.description"
      name="uid"
    />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Active:</div>
    <input
      class="edit"
      type="checkbox"
      [(ngModel)]="emailType.active"
      name="uid"
      (ngModelChange)="edited = true"
    />
  </div>
  <div class="my-table-header" style="padding: 0px 14px">
    <div class="my-table-item-large">
      <div>Name</div>
    </div>

    <div class="my-table-item-imp">
      <div>Email</div>
    </div>

    <div class="my-table-item-imp">
      <div>Access</div>
    </div>

    <div class="my-table-item-imp">
      <div>Active</div>
    </div>
  </div>

  <div class="my-table">
    <div *ngFor="let s of users" class="my-table-item">
      <div class="my-table-item-large">
        {{ s.displayName }}
      </div>

      <div class="my-table-item-imp">
        {{ s.email }}
      </div>
      <div class="my-table-item-imp">
        {{ s.access | accessLevels }}
      </div>

      <div class="my-table-item-imp">
        <input
          type="checkbox"
          [(ngModel)]="s.active"
          (ngModelChange)="edited = true"
          name="uid"
        />
      </div>
    </div>
  </div>
</div>

<div
  class="actions staff-info-header"
  style="align-self: flex-end; margin-top: auto"
>
  <button
    color="accent"
    class="reactive-button-title green"
    [disabled]="!edited"
    (click)="save()"
  >
    Save
  </button>

  <button color="warn" class="reactive-button-title red" (click)="close()">
    {{ edited ? "Cancel" : "Close" }}
  </button>
</div>
