import { DeclarationReceipt } from "./declaration-receipt";

export class Declaration {
  bar: string;
  id: string;
  userId: string;
  userName: string;
  dateCreated: number;
  declarationDate: number;
  declarationName: string;
  declarationDescription: string;
  receipts: DeclarationReceipt[];
  status: string;
  amount: number = 0;

  constructor() {
    this.receipts = [];
    this.status = "created";
    this.dateCreated = new Date().getTime();
    this.declarationDate = new Date().getTime();
  }

  sum() {
    console.log("summing");
    this.amount = this.receipts.reduce((a, b) => {
      b.sum();
      return a + b.amount;
    }, 0);
    return this.amount;
  }
}
