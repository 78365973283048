<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">
    {{ day.name || "Unknown Day" }}
  </h3>
</div>

<div class="actions staff-info-header">
  <button
    (click)="page = 0"
    [class]="
      page == 0 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Settings
  </button>
  <button
    (click)="page = 1"
    [class]="
      page == 1 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    History
  </button>

  <button
    (click)="page = 2"
    [class]="
      page == 2 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Graph
  </button>

  <button
    (click)="page = 3; clickHourly()"
    [class]="
      page == 3 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Hourly
  </button>
</div>

<!--Editing-->
<div class="staff-info-content" style="flex-grow: 1">
  <ng-container *ngIf="page == 0">
    <div class="edit-row">
      <div class="edit-desc">Open Time</div>
      <input
        class="edit"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.openTime"
        name="uid"
      />
    </div>

    <div class="edit-row">
      <div class="edit-desc">Close Time</div>
      <input
        class="edit"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.closeTime"
        name="uid"
      />
    </div>

    <div class="edit-row">
      <div class="edit-desc">Number Of Staff</div>
      <input
        type="number"
        step="1"
        min="1"
        max="100"
        class="edit"
        (ngModelChange)="edited = true; numberOfStaffChanged($event)"
        [(ngModel)]="day.numberOfStaff"
        name="uid"
      />
    </div>

    <div>
      <div class="edit-desc">Start Times</div>
      <div *ngFor="let start of day.startTimes; let i = index">
        <input
          type="string"
          class="edit"
          (ngModelChange)="edited = true; startTimeChanged($event, i)"
          [ngModel]="start"
          name="uid"
        />
      </div>
    </div>

    <div class="edit-row">
      <div class="edit-desc">Requires Social Hygiene</div>
      <input
        class="edit"
        type="checkbox"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.requiresSocialHygiene"
        name="uid"
      />
    </div>

    <div class="edit-row">
      <div class="edit-desc">Bonus Requirement (euros)</div>
      <input
        class="edit"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.bonusRequirement"
        name="uid"
      />
    </div>
    <div class="edit-row">
      <div class="edit-desc">Bonus Amount (hours)</div>
      <input
        class="edit"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.bonusAmount"
        name="uid"
      />
    </div>

    <div class="edit-row">
      <div class="edit-desc">Two Staff Requirement (euros)</div>
      <input
        class="edit"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.twostaffreq"
        name="uid"
      />
    </div>

    <div class="edit-row">
      <div class="edit-desc">Four Staff Requirement (euros)</div>
      <input
        class="edit"
        (ngModelChange)="edited = true"
        [(ngModel)]="day.fourstaffreq"
        name="uid"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="page == 1">
    <app-input
      name="Number Of Weeks"
      type="number"
      [model]="numberOfWeeks"
      (changed)="numberOfWeeks = $event; getData()"
    ></app-input>
    <table>
      <thead style="position: sticky; top: 0">
        <tr style="position: sticky">
          <th>Date</th>
          <th>P/C/I</th>
          <th>Omzet</th>
          <th>Hours</th>
          <th>€/Hour</th>
        </tr>
      </thead>

      <tr *ngFor="let history of history">
        <td>{{ history.date | date : "LLL d `YY" }}</td>
        <td>
          {{ history.pin | currency : "EUR" }} /
          {{ history.cash | currency : "EUR" }} /
          {{ history.internet | currency : "EUR" }}
        </td>
        <td>{{ history.omzet | currency : "EUR" }}</td>
        <td>{{ history.hours }}h</td>
        <td>{{ history.euroPerHour | currency : "EUR" }}/h</td>
      </tr>
      <tfoot style="position: sticky; bottom: 0">
        <tr style="font-weight: bold">
          <td>Total</td>
          <td>
            {{ totals.pin | currency : "EUR" }} /
            {{ totals.cash | currency : "EUR" }} /
            {{ totals.internet | currency : "EUR" }}
          </td>
          <td>{{ totals.omzet | currency : "EUR" }}</td>
          <td>{{ totals.hours }}h</td>
          <td></td>
        </tr>

        <tr style="position: sticky; font-weight: bold">
          <td>Average</td>
          <td>
            {{ averages.pin | currency : "EUR" }} /
            {{ averages.cash | currency : "EUR" }} /
            {{ averages.internet | currency : "EUR" }}
          </td>
          <td>{{ averages.omzet | currency : "EUR" }}</td>
          <td>{{ averages.hours | number : "1.2-2" }}h</td>
          <td>{{ averages.euroPerHour | currency : "EUR" }}/h</td>
        </tr>
      </tfoot>
    </table>
  </ng-container>

  <ng-container *ngIf="page == 2">
    <app-input
      name="Number Of Weeks"
      type="number"
      [model]="numberOfWeeks"
      (changed)="numberOfWeekChanges($event)"
    ></app-input>
    <canvas
      baseChart
      type="line"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [plugins]="lineChartPlugins"
    >
    </canvas>
  </ng-container>

  <ng-container *ngIf="page == 3">
    <plotly-plot
      id="plotly-plot"
      [data]="graph.data"
      [layout]="graph.layout"
    ></plotly-plot>
  </ng-container>
</div>

<div
  class="actions staff-info-header"
  style="align-self: flex-end; margin-top: auto"
>
  <button
    color="accent"
    class="reactive-button-title green"
    [disabled]="!edited"
    (click)="save()"
  >
    Save
  </button>

  <button color="warn" class="reactive-button-title red" (click)="close()">
    {{ edited ? "Cancel" : "Close" }}
  </button>
</div>
