<ng-template #content let-modal style="color: black">
  <div class="modal-header" style="color: black">
    <h4 class="modal-title" id="modal-basic-title">Edit/Add</h4>
  </div>
  <div class="modal-body" style="color: black" *ngIf="selectedDrink">
    <div class="mb-3">
      <div class="edit-row">
        <div class="edit-desc">Name:</div>
        <input class="edit" [(ngModel)]="selectedDrink.name" name="name" />
      </div>
      <div class="edit-row">
        <div class="edit-desc">Default Quantity:</div>
        <input
          class="edit"
          type="number"
          [(ngModel)]="selectedDrink.defaultQuantity"
          name="name"
        />
      </div>

      <div class="edit-row">
        <div class="edit-desc">VAT:</div>
        <input
          class="edit"
          type="number"
          [(ngModel)]="selectedDrink.vat"
          name="name"
        />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Price Per Unit :</div>
        <input
          class="edit"
          type="number"
          [(ngModel)]="selectedDrink.price"
          name="name"
        />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Description:</div>
        <input
          class="edit"
          type="text"
          [(ngModel)]="selectedDrink.description"
          name="name"
        />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Count Needed:</div>
        <input
          class="edit"
          type="checkbox"
          [(ngModel)]="selectedDrink.isCountNeeded"
          name="name"
        />
      </div>

      <div class="edit-row">
        <div class="edit-desc">Invoice Category:</div>
        <select [(ngModel)]="selectedDrink.invoiceCategory">
          <option *ngFor="let c of invoiceCategories" [ngValue]="c">
            {{ c }}
          </option>
        </select>
      </div>
    </div>

    <div class="edit-row" *ngFor="let b of bars">
      <div class="edit-desc">{{ b | capitalize }}</div>
      <input
        type="checkbox"
        [ngModel]="!!selectedDrink.bars[b]"
        (ngModelChange)="barChanged($event, b, selectedDrink)"
      />
    </div>
  </div>
  <div class="modal-footer" style="color: black">
    <button mat-raised-button color="primary" (click)="modal.close('save')">
      Save
    </button>
    <button mat-raised-button color="warn" (click)="modal.close('cancel')">
      Cancel
    </button>
    <button mat-raised-button color="warn" (click)="modal.close('delete')">
      Delete
    </button>
  </div>
</ng-template>

<div class="button-row" style="margin-bottom: 15px">
  <button [disabled]="isBar" mat-raised-button (click)="newDrink(content)">
    New Item
  </button>
  <button
    [disabled]="isBar"
    mat-raised-button
    (click)="sync()"
    ngbTooltip="Do when sorted all stock items this will sync all bars else it only affects DB"
  >
    Sync With All Bars
  </button>

  <button
    [disabled]="!isBar"
    mat-raised-button
    (click)="editOrder()"
    ngbTooltip="Do when sorted all stock items this will sync all bars else it only affects DB"
  >
    Edit Quantities
  </button>

  <button
    [disabled]="!isBar || !edited"
    mat-raised-button
    (click)="saveEdits()"
    ngbTooltip="Save the edited quantities"
  >
    Save Edits
  </button>
</div>

<div class="stock-table-header">
  <div class="stock-item-sml">
    <div>No:</div>
  </div>
  <div class="stock-item-large">
    <div>Name</div>
  </div>
  <div class="stock-item-large">
    <div>Category</div>
  </div>
  <div class="stock-item-large">
    <div>Server ID</div>
  </div>

  <div class="stock-item-sml" *ngIf="!isBar">
    <div>PPU</div>
  </div>

  <div class="stock-item-sml" *ngIf="!isBar">
    <div>VAT</div>
  </div>

  <div class="stock-item-sml" *ngIf="isBar">
    <div>Default Quantity</div>
  </div>

  <div class="stock-item-large" *ngIf="!isBar">
    <div>Bars</div>
  </div>
  <div class="stock-item-sml" *ngIf="isBar">
    <i class="fas fa-chevron-up"></i>
  </div>
  <div class="stock-item-sml" *ngIf="isBar">
    <i class="fas fa-chevron-down"></i>
  </div>
</div>

<div class="stock-table">
  <button
    *ngFor="let item of drinks; let index = index"
    class="nd-button stock-item"
    (click)="drinkSelected(item, content)"
  >
    <div class="stock-item-sml" *ngIf="isBar">
      {{ item.bars[bar].position }}
    </div>

    <div class="stock-item-sml" *ngIf="!isBar">
      {{ item.position }}
    </div>
    <div class="stock-item-large">
      {{ item.name }}
    </div>
    <div class="stock-item-large">
      {{ item.invoiceCategory }}
    </div>
    <div class="stock-item-large">
      {{ item.key }}
    </div>

    <div *ngIf="!isBar" class="stock-item-sml">
      {{ item.price }}
    </div>

    <div *ngIf="!isBar" class="stock-item-sml">
      {{ item.vat }}
    </div>

    <div *ngIf="isBar && !editing" class="stock-item-sml">
      {{ item.bars[bar].defaultQuantity }}
    </div>

    <input
      *ngIf="isBar && editing"
      [disabled]="item.isCountNeeded"
      class="stock-item-sml"
      type="number"
      (click)="$event.stopPropagation()"
      [(ngModel)]="item.bars[bar].defaultQuantity"
      name="name"
    />

    <div
      class="stock-item-large"
      *ngIf="!isBar"
      style="
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
      "
    >
      <div
        class="barshorthand"
        *ngFor="let b of item.bars | keyvalue"
        [style.backgroundColor]="b.key | stringToHex"
      >
        {{ b.key | initials }}
      </div>
    </div>
    <button
      *ngIf="isBar && !editing"
      class="sort-arrow"
      (click)="$event.stopPropagation(); sortUp(item, index)"
      [disabled]="index <= 0"
    >
      <i class="fas fa-chevron-up"></i>
    </button>

    <button
      *ngIf="isBar && !editing"
      class="sort-arrow"
      [disabled]="index >= drinks.length - 1"
      (click)="$event.stopPropagation(); sortDown(item, index)"
    >
      <i class="fas fa-chevron-down"></i>
    </button>
  </button>
</div>
