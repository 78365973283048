import { Component, OnInit } from "@angular/core";
import { Auth } from "src/app/auth/auth";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";

@Component({
  selector: "app-bestellingen",
  templateUrl: "./bestellingen.component.html",
  styleUrls: ["./bestellingen.component.css"],
})
export class BestellingenComponent implements OnInit {
  constructor(public auth: Auth, public bi: BarInfo) {}

  ngOnInit(): void {}
}
