import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailType } from "src/app/Classes/email-type";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";

@Component({
  selector: "app-email-modal",
  templateUrl: "./email-modal.component.html",
  styleUrls: ["./email-modal.component.scss"],
})
export class EmailModalComponent {
  edited: boolean = false;

  @Input("users") users: any[] = [];
  @Input("emailType") emailType: EmailType;
  constructor(
    public activeModal: NgbActiveModal,
    public loading: LoadingService,
    public alert: AlertsService
  ) {
    console.log(this.users);
  }

  ngOnInit() {
    console.log(this.users);
  }
  save() {
    this.emailType.users = [];
    this.users.forEach((user) => {
      user.active = user.active || false;
      if (!user.active) return;
      this.emailType.users.push(user);
    });
    this.activeModal.close(this.emailType);
  }

  close() {
    this.activeModal.dismiss();
  }
}
