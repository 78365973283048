import { BrowserModule } from "@angular/platform-browser";
import { NgModule, PLATFORM_ID, NgZone, LOCALE_ID } from "@angular/core";
import { Observable } from "rxjs";
import { AppRoutingModule } from "./Services/app-routing.module";
import { AppComponent, SelectBarDialog } from "./app.component";
import { MaterialModule } from "./material-components";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { LoginComponent } from "./Components/Home/login/login.component";

import { environment } from "../environments/environment";

import { AuthGuard } from "./auth/authguard";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { UserAccessLevels } from "./Classes/user-access-levels";
import { ConfirmationDialogComponent } from "./Packages/confirmation-dialog/confirmation-dialog.component";
import { NgbAccordionModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogService } from "./Packages/confirmation-dialog/confirmation-dialog.service";

import { AccountComponent } from "./Components/Account/account/account.component";

import { BnNgIdleService } from "bn-ng-idle";
import { MyUnorg } from "./Helpers/functions/MyUnorg";

import { NgChartsModule } from "ng2-charts";

import { NgxChartsModule } from "@swimlane/ngx-charts";

import localeDe from "@angular/common/locales/nl";
registerLocaleData(localeDe);

import { BarInfo } from "./Helpers/functions/BarInfo";

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

import { CountingComponent } from "./Components/Controle/counting/counting.component";

import { NDOrderComponent } from "./Components/Bestellingen/ndorder/ndorder.component";

import { TillComponent } from "./Components/Controle/till/till.component";
import { MessagesComponent } from "./Components/Instellingen/messages/messages.component";
import { DatePipe } from "./Components/Instellingen/messages/messages.component";
import { TasksComponent } from "./Components/Onderhoud/tasks/tasks.component";
import { BarComponent } from "./Components/Instellingen/bar/bar.component";
import { DrinkOrdersComponent } from "./Components/Bestellingen/drink-orders/drink-orders.component";

import { CustomerAppStorageUploader } from "./Helpers/functions/CustomerAppStorageUploader";

import { AvatarServiceService } from "./Packages/Avatar/avatar-service.service";
import { AvatarViewerComponent } from "./Packages/Avatar/avatar-viewer/avatar-viewer.component";
import { AvatarMakerComponent } from "./Packages/Avatar/avatar-maker/avatar-maker.component";
import { HelpComponent } from "./Components/Help/help/help.component";
import { StockComponent } from "./Components/Instellingen/stock/stock.component";
import { ExpandableBoxComponent } from "./Packages/expandable-box/expandable-box.component";

import { ApplyDiscountsService } from "./Helpers/functions/Untill/Swagger/api/applyDiscounts.service";
import { BackofficeModifyService } from "./Helpers/functions/Untill/Swagger/api/backofficeModify.service";
import { BackofficeViewService } from "./Helpers/functions/Untill/Swagger/api/backofficeView.service";
import { ClientManagementService } from "./Helpers/functions/Untill/Swagger/api/clientManagement.service";
import { MiscService } from "./Helpers/functions/Untill/Swagger/api/misc.service";
import { OrderingService } from "./Helpers/functions/Untill/Swagger/api/ordering.service";
import { PaymentService } from "./Helpers/functions/Untill/Swagger/api/payment.service";
import { ReportingService } from "./Helpers/functions/Untill/Swagger/api/reporting.service";
import { ReservationsService } from "./Helpers/functions/Untill/Swagger/api/reservations.service";
import { Configuration } from "./Helpers/functions/Untill/Swagger/configuration";

import { SwaggerInterceptor } from "./Helpers/functions/Untill/Swagger/swaggerInterceptor";

import { InputComponent } from "./Packages/input/input.component";
import { StaffDrinksComponent } from "./Components/Personeel/staff-drinks/staff-drinks.component";
import { DecimalPipe, registerLocaleData } from "@angular/common";
import { StaffOrdersComponent } from "./Components/Personeel/staff-orders/staff-orders.component";

import { StaffNamePipe } from "./Pipes/StaffNamePipe";
import { VipRoomsComponent } from "./Components/Instellingen/vip-rooms/vip-rooms.component";
import { TestersComponent } from "./Components/Instellingen/testers/testers.component";

import { CalendarCreator } from "./Classes/mycal/calendarCreator.service";
import { LostandfoundComponent } from "./Components/Instellingen/lostandfound/lostandfound.component";

import { PayslipUploaderComponent } from "./Components/Personeel/payslip-uploader/payslip-uploader.component";
import { NamePipe } from "./Pipes/name.pipe";
import { ControleComponent } from "./Components/Controle/controle/controle.component";
import { InkomstenComponent } from "./Components/Inkomsten/inkomsten/inkomsten.component";
import { PeroneelComponent } from "./Components/Personeel/peroneel/peroneel.component";
import { BestellingenComponent } from "./Components/Bestellingen/bestellingen/bestellingen.component";
import { OnderhoudComponent } from "./Components/Onderhoud/onderhoud/onderhoud.component";
import { InstelligenComponent } from "./Components/Instellingen/instelligen/instelligen.component";
import { AlertsComponent } from "./Packages/alerts/alerts.component";
import { ShiftsComponent } from "./Components/Instellingen/shifts/shifts.component";
import { WhoIsWorkingComponent } from "./Packages/who-is-working/who-is-working.component";
import { StockCountComponent } from "./Packages/stock-count/stock-count.component";
import { TellingComponent } from "./Components/Controle/telling/telling.component";
import { DatethPipe } from "./Pipes/dateth.pipe";
import { DashboardComponent } from "./Components/Controle/dashboard/dashboard.component";
import { ColorRangePipe } from "./Pipes/color-range.pipe";
import { StockNamePipe } from "./Pipes/stock-name.pipe";
import { VerbruikPopoverComponent } from "./Components/Controle/verbruik-popover/verbruik-popover.component";
import { ExpensesComponent } from "./Components/Instellingen/expenses/expenses.component";
import { ExpensesPopOverComponent } from "./Components/Inkomsten/expenses-pop-over/expenses-pop-over.component";
import { PricePipe } from "./Pipes/price.pipe";
import { MyDatePipe } from "./Pipes/my-date.pipe";
import { OverviewListComponent } from "./Packages/overview-list/overview-list.component";
import { PinCashInternetChartComponent } from "./Charts/pin-cash-internet-chart/pin-cash-internet-chart.component";
import { PDFMakerComponent } from "./Packages/pdfmaker/pdfmaker.component";
import { AddressComponent } from "./Packages/address/address.component";
import { NgxPrintDirective, NgxPrintModule } from "ngx-print";
import { TestingComponent } from "./Components/Testing/testing/testing.component";
import { BonusComponent } from "./Components/Testing/bonus/bonus.component";
import { InkoopComponent } from "./Components/Testing/inkoop/inkoop.component";
import { NgxJsonViewerModule } from "ngx-json-viewer-scrolling";
import { CustomerAppComponent } from "./Components/CustomerApp/customer-app/customer-app.component";
import { CatalogueComponent } from "./Components/CustomerApp/catalogue/catalogue.component";
import { NgbdSortableHeader } from "./Helpers/directive/sort-event.directive";
import { CustomersComponent } from "./Components/CustomerApp/customers/customers.component";
import { EditArtistComponent } from "./Packages/edit-artist/edit-artist.component";
import { EditSongComponent } from "./Packages/edit-song/edit-song.component";
import { SanitizerUrlPipe } from "./Pipes/sanitizer-url.pipe";
import { LiveComponent } from "./Components/CustomerApp/live/live.component";
import { SongPipe } from "./Pipes/song.pipe";
import { CustomerPipe } from "./Pipes/customer.pipe";
import { AccessLevelsComponent } from "./Components/Instellingen/access-levels/access-levels.component";
import { BookingAPIComponent } from "./Components/Testing/booking-api/booking-api.component";
import { SongRequestsComponent } from "./Components/CustomerApp/song-requests/song-requests.component";
import { MapComponent } from "./Components/Testing/map/map.component";
import { SongpricingComponent } from "./Components/CustomerApp/songpricing/songpricing.component";
import { WeekdayPipe } from "./Pipes/weekday.pipe";
import { DetailedTurnoverComponent } from "./Components/Testing/detailed-turnover/detailed-turnover.component";
import { MoveSongComponent } from "./Packages/move-song/move-song.component";
import { EmulatorComponent } from "./Packages/CoinCost/emulator/emulator.component";

import {
  AngularFireFunctionsModule,
  REGION,
} from "@angular/fire/compat/functions";
import { AngularFireModule } from "@angular/fire/compat";
import { env } from "process";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import {
  AngularProject1,
  AngularProject2,
  AngularAuthProject2,
  CustomerAppAuth,
  AngularStorage2,
  AngularStorage,
  StaffAppFunctions,
  CustomerAppFunctions,
} from "./Services/firebase.factory";
import { UsageComponent } from "./Components/CustomerApp/usage/usage.component";
import { ReviewsComponent } from "./Components/Instellingen/reviews/reviews.component";
import { ReviewAnswerComponent } from "./Packages/review-answer/review-answer.component";
import { CalendarManager } from "./Classes/my-calendar/calendar-manager";
import { MyCalendarComponent } from "./Classes/my-calendar/my-calendar.component";
import { HomeComponent } from "./Components/Home/home/home.component";
import { LoadingComponent } from "./Packages/loading/loading.component";
import { Schedule2Component } from "./Components/Personeel/schedule2/schedule2.component";
import { StaffAppDatabase } from "./Services/staffappdatabase";
import { Firebase } from "./Services/firebase";
import { LoadingService } from "./Services/loading.service";
import { NDOrderItemsComponent } from "./Components/Bestellingen/ndorder-items/ndorder-items.component";
import { NDOrdersComponent } from "./Components/Bestellingen/ndorders/ndorders.component";
import {
  StaffInfoComponent,
  DeleteStaffDialog,
} from "./Components/Personeel/staff-info/staff-info.component";
import { StaffhoursComponent } from "./Components/Personeel/staffhours/staffhours.component";
import { TextComponent } from "./Packages/text/text.component";
import { TypeofPipe } from "./Pipes/typeof.pipe";
import { IsNumberPipe } from "./Pipes/is-number.pipe";
import { MultiselectComponent } from "./Packages/multiselect/multiselect.component";

import { FilterPipe } from "./Pipes/filter.pipe";
import { WipeCreditComponent } from "./Components/CustomerApp/wipe-credit/wipe-credit.component";
import { NgxBootstrapMultiselectModule } from "ngx-bootstrap-multiselect";
import { StatisticsComponent } from "./Components/CustomerApp/statistics/statistics.component";
import { StatisticsDirective } from "./Components/CustomerApp/statistics.directive";
import { DocumentManagerComponent } from "./Components/Personeel/document-manager/document-manager.component";
import { BugReportComponent } from "./Packages/bug-report/bug-report.component";
import { YearMonthCountPipe } from "./Pipes/year-month-count.pipe";
import { MonthPipe } from "./Pipes/month.pipe";
import { SongKeySplitterPipe } from "./Pipes/song-key-splitter.pipe";
import { UpDownComponent } from "./Packages/up-down/up-down.component";
import { TipsComponent } from "./Components/Controle/tips/tips.component";
import { ToColorPipe } from "./Pipes/to-color.pipe";
import { DevicesComponent } from "./Components/Instellingen/devices/devices.component";
import { SpaceCapitalsPipe } from "./Pipes/space-capitals.pipe";
import { SizeConversionPipe } from "./Pipes/size-conversion.pipe";
import { DocumentsComponent } from "./Components/Account/documents/documents.component";
import { HoursComponent } from "./Components/Account/hours/hours.component";
import { SetScheduleComponent } from "./Components/Account/set-schedule/set-schedule.component";
import { CountdownPipe } from "./Pipes/countdown.pipe";
import { CheckArrayPipe } from "./Pipes/check-array.pipe";
import { InvoiceMakerComponent } from "./Components/Tools/invoice-maker/invoice-maker.component";
import { ToolsComponent } from "./Components/Tools/tools/tools.component";
import { VoucherComponent } from "./Components/CustomerApp/voucher/voucher.component";
import { QRCodeModule } from "angularx-qrcode";
import { WorkingComponent } from "./Components/Testing/working/working.component";
import { WorkedRecordsComponent } from "./Packages/worked-records/worked-records.component";
import { WeeklyGiftComponent } from "./Components/Personeel/weekly-gift/weekly-gift.component";
import { SongCreditSimulationComponent } from "./Components/Testing/song-credit-simulation/song-credit-simulation.component";
import { GenericMessageComponent } from "./Packages/generic-message/generic-message.component";
import { CreateMessageComponent } from "./Packages/create-message/create-message.component";
import { TutorialsComponent } from "./Components/tutorials/tutorials.component";
import { CompetitionComponent } from "./Components/Instellingen/competition/competition.component";
import { ChangeRecordsComponent } from "./Components/Tools/change-records/change-records.component";
import { JSONCompareComponent } from "./Packages/jsoncompare/jsoncompare.component";

import { CanDutchComponent } from "./Components/CustomerApp/can-dutch/can-dutch.component";

import { PdfViewerModule as PDFViewModule2 } from "ng2-pdf-viewer";
import { TablesComponent } from "./Components/Controle/tables/tables.component";
import { WalletpassthroughComponent } from "./walletpassthrough/walletpassthrough.component";
import { InkoopKostenComponent } from "./Components/Instellingen/inkoop-kosten/inkoop-kosten.component";
import { PDFViewerModule } from "duncans-pdfviewer";
import { InitialsPipe } from "./Pipes/initials.pipe";
import { SortByComponent } from "./Packages/sort-by/sort-by.component";
import { StringToHexPipe } from "./Pipes/string-to-hex.pipe";
import { HQStockComponent } from "./Packages/hqstock/hqstock.component";
import { SongPricingModelComponent } from "./Packages/song-pricing-model/song-pricing-model.component";
import { ShirtsComponent } from "./Packages/shirts/shirts.component";
import { AddShirtComponent } from "./Packages/add-shirt/add-shirt.component";
import { StaffInfoPopupComponent } from "./Components/Personeel/staff-info-popup/staff-info-popup.component";
import { EditStockComponent } from "./Packages/edit-stock/edit-stock.component";
import { EditStockOrderComponent } from "./Packages/edit-stock-order/edit-stock-order.component";
import { SelectNewStockComponent } from "./Packages/select-new-stock/select-new-stock.component";
import { CapitalizePipe } from "./Pipes/capitalize.pipe";
import { TipModalComponent } from "./Controle/tip-modal/tip-modal.component";
import { CalculateTipComponent } from "./Pacakages/calculate-tip/calculate-tip.component";
import { CCTVComponent } from "./Components/Testing/cctv/cctv.component";

import { ShiftCheckComponent } from "./Components/Controle/shift-check/shift-check.component";
import { LafmodalComponent } from "./Components/Instellingen/lostandfound/lafmodal/lafmodal.component";
import { NDOStockHQComponent } from "./Components/Instellingen/ndostock-hq/ndostock-hq.component";
import { NDOStockEditComponent } from "./Components/Instellingen/ndostock-edit/ndostock-edit.component";
import { CountingHQComponent } from "./Components/Control/counting-hq/counting-hq.component";
import { NDCountingHQComponent } from "./Components/Control/ndcounting-hq/ndcounting-hq.component";
import { TipsSettingsComponent } from "./Components/Testing/tips/tips.component";
import { TipTypePipe } from "./Pipes/tip-type.pipe";
import { TipCalculatorPipe } from "./Pipes/tip-calculator.pipe";
import { DynamicVoucherComponent } from "./Components/CustomerApp/dynamic-voucher/dynamic-voucher.component";
import { TheEndTVComponent } from "./components/Testing/the-end-tv/the-end-tv.component";
import { TheEndTVPopupComponent } from "./Components/Testing/the-end-tvpopup/the-end-tvpopup.component";
import { InvoiceSplitterComponent } from "./Components/Instellingen/invoice-splitter/invoice-splitter.component";
import { InvoiceSplitterPreviewComponent } from "./Components/Modals/invoice-splitter-preview/invoice-splitter-preview.component";
import { MissingTellingDatesComponent } from "./Components/Controle/missing-telling-dates/missing-telling-dates.component";
import { EmailComponent } from "./Components/Testing/email/email.component";
import { PaymentsComponent } from "./Components/Controle/payments/payments.component";
import { PaginatePipe } from "./Pipes/paginate.pipe";
import { PaymentModalComponent } from "./Components/Controle/payment-modal/payment-modal.component";
import { ReceiptModalComponent } from "./Components/Controle/receipt-modal/receipt-modal.component";
import { EJournalModalComponent } from "./Components/Controle/ejournal-modal/ejournal-modal.component";
import { CreateBarComponent } from "./Components/Tools/create-bar/create-bar.component";
import { FlagsComponent } from "./Components/CustomerApp/flags/flags.component";
import { SidebarComponent } from "./Components/Testing/sidebar/sidebar.component";
import { OutingBudgetComponent } from "./Components/Personeel/outing-budget/outing-budget.component";
import { OutingBudgetModalComponent } from "./Components/Personeel/outing-budget-modal/outing-budget-modal.component";
import { OutingRecordModalComponent } from "./Components/Personeel/outing-record-modal/outing-record-modal.component";
import { StringEntryModalComponent } from "./Modals/string-entry-modal/string-entry-modal.component";
import { GiftCardsComponent } from "./Components/Testing/gift-cards/gift-cards.component";
import { NDOrderItemsBarsComponent } from './Components/Bestellingen/ndorder-items-bars/ndorder-items-bars.component';
declare var require: any;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    StaffNamePipe,
    ConfirmationDialogComponent,

    AccountComponent,

    StaffhoursComponent,
    NDOrderComponent,
    NDOrderItemsComponent,
    NDOrdersComponent,
    StaffInfoComponent,
    SelectBarDialog,
    CountingComponent,
    MyCalendarComponent,
    TillComponent,
    MessagesComponent,
    DatePipe,
    TasksComponent,
    BarComponent,
    DrinkOrdersComponent,
    AvatarViewerComponent,
    AvatarMakerComponent,
    HelpComponent,
    StockComponent,
    ExpandableBoxComponent,

    InputComponent,
    StaffDrinksComponent,
    StaffOrdersComponent,

    VipRoomsComponent,
    TestersComponent,
    Schedule2Component,
    LostandfoundComponent,
    LoadingComponent,
    PayslipUploaderComponent,
    NamePipe,
    ControleComponent,
    InkomstenComponent,
    PeroneelComponent,
    BestellingenComponent,
    OnderhoudComponent,
    InstelligenComponent,
    AlertsComponent,
    ShiftsComponent,
    WhoIsWorkingComponent,
    StockCountComponent,
    TellingComponent,
    DatethPipe,
    DashboardComponent,
    ColorRangePipe,
    StockNamePipe,
    VerbruikPopoverComponent,
    ExpensesComponent,
    ExpensesPopOverComponent,
    PricePipe,
    MyDatePipe,
    OverviewListComponent,
    PinCashInternetChartComponent,
    PDFMakerComponent,
    AddressComponent,
    TestingComponent,
    BonusComponent,
    InkoopComponent,
    CustomerAppComponent,
    CatalogueComponent,
    NgbdSortableHeader,
    CustomersComponent,
    EditArtistComponent,
    EditSongComponent,
    SanitizerUrlPipe,
    LiveComponent,
    SongPipe,
    CustomerPipe,
    AccessLevelsComponent,
    BookingAPIComponent,
    SongRequestsComponent,
    MapComponent,
    SongpricingComponent,
    WeekdayPipe,
    DetailedTurnoverComponent,
    MoveSongComponent,
    EmulatorComponent,
    UsageComponent,
    ReviewsComponent,
    ReviewAnswerComponent,
    TextComponent,
    TypeofPipe,
    IsNumberPipe,
    NamePipe,
    MultiselectComponent,

    FilterPipe,
    WipeCreditComponent,
    StatisticsComponent,
    DocumentManagerComponent,
    BugReportComponent,
    YearMonthCountPipe,
    MonthPipe,
    SongKeySplitterPipe,
    UpDownComponent,
    TipsComponent,
    ToColorPipe,
    DevicesComponent,
    SpaceCapitalsPipe,
    SizeConversionPipe,
    DocumentsComponent,
    HoursComponent,
    SetScheduleComponent,
    CountdownPipe,
    CheckArrayPipe,
    InvoiceMakerComponent,
    ToolsComponent,
    VoucherComponent,
    WorkingComponent,
    WorkedRecordsComponent,
    WeeklyGiftComponent,
    SongCreditSimulationComponent,
    GenericMessageComponent,
    CreateMessageComponent,
    TutorialsComponent,
    CompetitionComponent,
    ChangeRecordsComponent,
    JSONCompareComponent,
    CCTVComponent,
    CanDutchComponent,

    TablesComponent,
    WalletpassthroughComponent,
    InkoopKostenComponent,
    InitialsPipe,
    SortByComponent,
    StringToHexPipe,
    HQStockComponent,
    SongPricingModelComponent,
    ShirtsComponent,
    AddShirtComponent,
    StaffInfoPopupComponent,
    EditStockComponent,
    EditStockOrderComponent,
    SelectNewStockComponent,
    CapitalizePipe,
    TipModalComponent,
    CalculateTipComponent,
    ShiftCheckComponent,
    LafmodalComponent,
    NDOStockHQComponent,
    NDOStockEditComponent,
    CountingHQComponent,
    NDCountingHQComponent,
    TheEndTVComponent,
    TheEndTVPopupComponent,

    TipsSettingsComponent,
    TipTypePipe,
    TipCalculatorPipe,
    DynamicVoucherComponent,
    InvoiceSplitterComponent,
    InvoiceSplitterPreviewComponent,
    MissingTellingDatesComponent,
    EmailComponent,
    PaymentsComponent,
    PaginatePipe,
    PaymentModalComponent,
    ReceiptModalComponent,
    EJournalModalComponent,
    CreateBarComponent,
    FlagsComponent,
    SidebarComponent,
    OutingBudgetComponent,
    OutingBudgetModalComponent,
    OutingRecordModalComponent,
    StringEntryModalComponent,
    GiftCardsComponent,
    NDOrderItemsBarsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPrintModule,

    AngularFireModule.initializeApp(environment.staffFirebase, "staff"),

    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbAccordionModule,
    NgxChartsModule,
    NgChartsModule,
    ReactiveFormsModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxJsonViewerModule,
    NgChartsModule,
    NgxBootstrapMultiselectModule,
    NgxJsonViewerModule,
    QRCodeModule,
    PDFViewerModule,
    PDFViewModule2,
  ],
  providers: [
    AuthGuard,
    Firebase,
    StaffAppDatabase,
    AvatarServiceService,

    CustomerAppStorageUploader,
    UserAccessLevels,
    CalendarManager,
    BarInfo,
    ConfirmationDialogService,
    SelectBarDialog,
    DeleteStaffDialog,
    CalendarCreator,
    LoadingService,
    DecimalPipe,
    SongPipe,
    CustomerPipe,
    NamePipe,
    FilterPipe,
    BnNgIdleService,
    MyUnorg,
    { provide: LOCALE_ID, useValue: "en-NL" },
    {
      provide: "env",
      useValue: environment,
    },

    {
      provide: "firebaseProject1",
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularProject1,
    },
    {
      provide: "firebaseProject2",
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularProject2,
    },
    {
      provide: "firebaseAuthProject2",
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularAuthProject2,
    },
    {
      provide: "firebaseAuthProject1",
      deps: [PLATFORM_ID, NgZone],
      useFactory: CustomerAppAuth,
    },
    {
      provide: "firebaseStorage2",
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularStorage2,
    },
    {
      provide: "firebaseStorage",
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularStorage,
    },

    {
      provide: "firebaseFunctions2",
      deps: [PLATFORM_ID, NgZone],
      useFactory: StaffAppFunctions,
    },
    {
      provide: "firebaseFunctions1",
      deps: [PLATFORM_ID, NgZone],
      useFactory: CustomerAppFunctions,
    },

    { provide: REGION, useValue: "europe-west3" },
    SwaggerInterceptor,
    ApplyDiscountsService,
    BackofficeModifyService,
    BackofficeViewService,
    ClientManagementService,
    MiscService,
    OrderingService,
    PaymentService,
    ReportingService,
    ReservationsService,
    NgxPrintDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
