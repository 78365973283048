<button
  class="action-button selected"
  *ngIf="
    auth.accessUser && auth.accessUser.access >= 3 && bi.bar != 'Daga Beheer'
  "
  routerLink="/bestellingen/orders"
  routerLinkActive="true"
>
  Drink Orders
</button>

<button
  class="action-button selected"
  *ngIf="
    auth.accessUser && auth.accessUser.access >= 3 && bi.bar != 'Daga Beheer'
  "
  routerLink="/bestellingen/NDOrders"
  routerLinkActive="true"
>
  None Drink Orders
</button>

<button
  class="action-button selected"
  *ngIf="auth.accessUser && auth.accessUser.access >= 3"
  routerLink="/bestellingen/NDOrderItems"
  routerLinkActive="true"
>
  NDO Items
</button>
