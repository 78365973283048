<div class="my-table-header" style="padding: 0px 14px">
  <div class="my-table-item-imp" (click)="setSortBy('name')">
    <div>Name</div>
    <i
      [style.display]="sortBy.key == 'name' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="my-table-item-imp">
    <div>Active</div>
  </div>

  <div class="my-table-item-large">
    <div>Description</div>
  </div>
</div>

<div class="my-table">
  <div *ngFor="let s of emailTypes" class="my-table-item" (click)="openItem(s)">
    <div class="my-table-item-imp">
      {{ s.name }}
    </div>

    <div class="my-table-item-imp">
      {{ s.active + "" | capitalize }}
    </div>
    <div class="my-table-item-large">
      {{ s.description }}
    </div>
  </div>
</div>
