import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { randomBytes, createHash, createCipheriv } from "crypto-browserify";
import { hi } from "date-fns/locale";

import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { JSON2CSV } from "src/app/Packages/json2-csv";
import { MultiSelectOption } from "src/app/Packages/multiselect/multiselect.component";
import { Firebase } from "src/app/Services/firebase";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-voucher",
  templateUrl: "./voucher.component.html",
  styleUrls: ["./voucher.component.css"],
})
export class VoucherComponent implements OnInit {
  vouchers: Voucher[] = [];
  voucher: Voucher;
  tillInfo: any = {};
  history: any[] = [];
  qrWidth = "312";
  qrString = "";
  showQr = false;
  voucherListOptions: MultiSelectOption[] = [];
  showHistory = false;
  userHistory: any;

  showArchived: boolean = false;
  constructor(
    private loading: LoadingService,
    private alert: AlertsService,
    private db: StaffAppDatabase,
    private auth: Auth,
    private customerDB: Firebase,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  reset() {
    this.showHistory = false;
    this.voucher = null;
    this.history = [];
    this.qrString = "";
    this.userHistory = null;
  }
  totals = { given: 0, redeemed: 0, balance: 0, length: 0 };
  statistics: {
    UID: string;

    august: number;
    september: number;
    october: number;
    november: number;
    december: number;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
  }[] = [];
  statisticsFirstVisited: {
    UID: string;

    august: number;
    september: number;
    october: number;
    november: number;
    december: number;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
  }[] = [];

  spentStatistics: {
    UID: string;

    august: number;
    september: number;
    october: number;
    november: number;
    december: number;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
  }[] = [];
  async getHistory() {
    this.totals = { given: 0, redeemed: 0, balance: 0, length: 0 };
    this.showHistory = true;
    this.loading.nextEmit("on", null, "getting_voucher_history");
    const history = await this.customerDB.getVoucherHistory(this.voucher.key);
    console.log(history);
    this.history = history;

    await this.allPromises(history);

    console.log(this.userHistories, this.totals);
    //this.saveStatistics(this.userHistories);

    // this.firstVisitStatistics(this.userHistories);
    this.spentVisitStatistics(this.userHistories);

    var csv = JSON2CSV.ConvertToCSV(this.spentStatistics, [
      "UID",
      "august",
      "september",
      "october",
      "november",
      "december",
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
    ]);

    console.log("Calculateed CSV", csv);

    this.downloadFile(csv);

    this.loading.nextEmit(null, null, "getting_voucher_history");
  }

  firstVisitStatistics(histories) {
    Object.entries(histories).forEach(([key, value]: [string, any]) => {
      var statistic = {
        UID: key,

        august: this.calculateMonthFirst(value, "august", key),
        september: this.calculateMonthFirst(value, "september", key),
        october: this.calculateMonthFirst(value, "october", key),
        november: this.calculateMonthFirst(value, "november", key),
        december: this.calculateMonthFirst(value, "december", key),
        january: this.calculateMonthFirst(value, "january", key),
        february: this.calculateMonthFirst(value, "february", key),
        march: this.calculateMonthFirst(value, "march", key),
        april: this.calculateMonthFirst(value, "april", key),
        may: this.calculateMonthFirst(value, "may", key),
        june: this.calculateMonthFirst(value, "june", key),
        july: this.calculateMonthFirst(value, "july", key),
        voucherRef: this.voucher.key,
      };
      this.statisticsFirstVisited.push(statistic);
    });
    console.log(this.statistics);
  }

  spentVisitStatistics(histories) {
    Object.entries(histories).forEach(([key, value]: [string, any]) => {
      var statistic = {
        UID: key,

        august: this.calculateMonthSpent(value, "august", key),
        september: this.calculateMonthSpent(value, "september", key),
        october: this.calculateMonthSpent(value, "october", key),
        november: this.calculateMonthSpent(value, "november", key),
        december: this.calculateMonthSpent(value, "december", key),
        january: this.calculateMonthSpent(value, "january", key),
        february: this.calculateMonthSpent(value, "february", key),
        march: this.calculateMonthSpent(value, "march", key),
        april: this.calculateMonthSpent(value, "april", key),
        may: this.calculateMonthSpent(value, "may", key),
        june: this.calculateMonthSpent(value, "june", key),
        july: this.calculateMonthSpent(value, "july", key),
        voucherRef: this.voucher.key,
      };
      this.spentStatistics.push(statistic);
    });
    console.log(this.statistics);
  }

  saveStatistics(histories) {
    Object.entries(histories).forEach(([key, value]: [string, any]) => {
      var statistic = {
        UID: key,

        august: this.calculateMonth(value, "august"),
        september: this.calculateMonth(value, "september"),
        october: this.calculateMonth(value, "october"),
        november: this.calculateMonth(value, "november"),
        december: this.calculateMonth(value, "december"),
        january: this.calculateMonth(value, "january"),
        february: this.calculateMonth(value, "february"),
        march: this.calculateMonth(value, "march"),
        april: this.calculateMonth(value, "april"),
        may: this.calculateMonth(value, "may"),
        june: this.calculateMonth(value, "june"),
        july: this.calculateMonth(value, "july"),
        voucherRef: this.voucher.key,
      };
      this.statistics.push(statistic);
    });
    console.log(this.statistics);
  }

  competitionDate = new Date(2023, 7, 22, 8, 0, 0);

  downloadFile(csvData, filename = "data") {
    let arrHeader = ["name", "age", "country", "phone"];

    console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "sample.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  calculateMonth(value, month: string) {
    var history = value.history;
    var total = 0;
    var spent = 0;
    var added = false;

    value.receipts.forEach((value: any) => {
      var date = new Date(Number(value.key));
      var collectionDate = this.getDate(month);

      if (date.getMonth() != collectionDate.getMonth()) {
        //not the same month so skip
        return;
      }

      if (date < this.competitionDate) {
        return;
      }

      //is it within 12 hours after competition time? then skip

      if (
        Math.abs(date.getTime() - this.competitionDate.getTime()) <
        24 * 60 * 60 * 1000
      ) {
        return;
      }

      spent += value.total;
      total += 1;
    });

    return total;
  }

  calculateMonthSpent(value, month: string, uid: string) {
    var history = value.history;
    var total = 0;
    var spent = 0;
    var added = false;

    value.receipts.forEach((value: any) => {
      var date = new Date(Number(value.key));
      var collectionDate = this.getDate(month);

      if (date.getMonth() != collectionDate.getMonth()) {
        //not the same month so skip
        return;
      }

      if (date < this.competitionDate) {
        return;
      }

      //is it within 12 hours after competition time? then skip

      if (
        Math.abs(date.getTime() - this.competitionDate.getTime()) <
        24 * 60 * 60 * 1000
      ) {
        return;
      }

      spent += value.amount;
      total += 1;
    });

    return spent;
  }

  markedVisited = new Set();
  calculateMonthFirst(value, month: string, uid: string) {
    var history = value.history;
    var total = 0;
    var spent = 0;
    var added = false;

    value.receipts.forEach((value: any) => {
      if (this.markedVisited.has(uid)) {
        return;
      }
      var date = new Date(Number(value.key));
      var collectionDate = this.getDate(month);

      if (date.getMonth() != collectionDate.getMonth()) {
        //not the same month so skip
        return;
      }

      if (date < this.competitionDate) {
        return;
      }

      //is it within 12 hours after competition time? then skip

      if (
        Math.abs(date.getTime() - this.competitionDate.getTime()) <
        24 * 60 * 60 * 1000
      ) {
        return;
      }

      spent += value.amount;
      total += 1;
      this.markedVisited.add(uid);
    });

    return total;
  }

  getDate(month: string): Date {
    const date = new Date();
    date.setMonth(this.getMonth(month));
    if (date.getMonth() >= 7) {
      date.setFullYear(date.getFullYear() - 1);
    }

    return date;
  }
  MONTHS = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11,
  };
  getMonth(month: string): number {
    return this.MONTHS[month.toLowerCase()];
  }

  async allPromises(history) {
    var promises = [];
    for (var i = 0; i < history.length; i++) {
      const key = history[i].key;
      promises.push(
        new Promise(async (res, rej) => {
          await this.getUserInfo(key);
          this.totals.length++;
          this.totals.given += Number(
            Object.values(this.userHistories[key].history).reduce(
              (a, b: any) => a + (b.type == "credit" ? b.total : 0),
              0
            )
          );

          this.totals.balance += Number(this.userHistories[key].balance);

          this.totals.redeemed += Number(
            Object.values(this.userHistories[key].history).reduce(
              (a, b: any) => a + (b.type == "credit" ? 0 : b.total),
              0
            )
          );

          return res(true);
        })
      );
    }

    return await Promise.all(promises);
  }

  userHistories: any = {};

  async showUserInfo(content, uid) {
    this.userHistory = this.userHistories[uid];

    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {}
    );
  }

  async getUserInfo(uid) {
    const user = await this.customerDB.getUserAsync(uid);
    const receipts = await this.customerDB.getUserReceipts(uid);
    const drinks: any = await this.db.getCustomerDrinks(uid);

    this.userHistory = {
      user,
      receipts,
      balance: drinks[0],
      history: drinks[1],
      spent:
        Object.values(drinks[1]).reduce(
          (a, b: any) => a + (b.type == "credit" ? 0 : b.total),
          0
        ) || 0,
    };
    this.userHistories[uid] = this.userHistory;
  }

  async getData() {
    this.loading.nextEmit("on", null, "getting_vouchers");
    await this.getVouchers();
    await this.getTillInfo();

    console.log(this.vouchers);
    console.log(this.tillInfo);

    this.loading.nextEmit(null, null, "getting_vouchers");
  }

  async getVouchers() {
    this.vouchers = await this.db
      .getVouchers()
      .then((a) =>
        a
          .map((b: any) => Object.assign(new Voucher(), b))
          .filter((b) => this.showArchived || b.archived != true)
      )
      .catch((e) => {
        console.error(e);
        return [];
      });
  }

  equivalentChanged(v: Voucher, event: any) {
    console.log(v, event);
    v.equivalent = event.filter((a) => a.selected).map((a) => a.id);
  }
  async getTillInfo() {
    this.tillInfo = await this.db.getTillInfo();
  }

  voucherClicked(v) {
    this.voucher = v;
    this.showHistory = false;
    this.history = [];
    this.voucherListOptions = [];
    this.voucherListOptions = this.vouchers
      .filter((a) => a.key != v.key)
      .map((a) => {
        const option = new MultiSelectOption();
        option.id = a.key;
        option.name = a.name;
        option.selected = this.voucher.equivalent.includes(a.key);
        return option;
      });
  }
  newVoucher() {
    this.voucher = new Voucher();
    this.voucher.db = this.tillInfo.database;
    this.voucher.location = this.tillInfo.postCode;
    this.voucherClicked(this.voucher);
  }

  dateChanged(event) {
    const date = new Date(event);
    date.setHours(20, 0, 0, 0);
    this.voucher.date = date.getTime();
    console.log(new Date(this.voucher.date));
  }

  async saveVoucher() {
    this.loading.nextEmit("on", null, "saving_voucher");

    if (!this.voucher.name || !this.voucher.description || !this.voucher.date) {
      this.alert.nextEmit(AlertsService.error("Please fill in all fields"));
      this.loading.nextEmit(null, null, "saving_voucher");
      return;
    }

    if (this.voucher.maxUses < 1) {
      this.alert.nextEmit(AlertsService.error("Max uses must be at least 1"));
      this.loading.nextEmit(null, null, "saving_voucher");
      return;
    }

    if (this.voucher.drinkCredits < 1 && this.voucher.songCredits < 1) {
      this.alert.nextEmit(
        AlertsService.error("Voucher must give at least 1 credit")
      );
      this.loading.nextEmit(null, null, "saving_voucher");
      return;
    }
    if (!this.voucher.key) {
      this.voucher.creator = this.auth.user.uid;
      this.voucher.edits.push({ user: this.auth.user.uid, date: Date.now() });
    }
    const res = await this.db
      .saveVoucher(this.voucher)
      .then((a) => null)
      .catch((e) => e);

    if (res) {
      this.alert.nextEmit(AlertsService.error("Error saving voucher", res));
    }

    this.loading.nextEmit(null, null, "saving_voucher");
    this.getData();
  }

  async generateQR() {
    this.showHistory = false;
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "HH:mm";
    //get date and time seperately fwith formattimg
    const date = new Date(this.voucher.date);
    const dateStr = date.toISOString().split("T")[0];
    const timeStr = date.toISOString().split("T")[1].split(".")[0];

    console.log(dateStr, timeStr);
    const hash = await this.db.getEncryptedVoucher(this.voucher);
    console.log(hash);
    if (hash && hash.status == "success" && hash.data) {
      var qrCode =
        "https://theendkaraoke.nl/app/?date=" +
        dateStr +
        "&time=" +
        timeStr +
        "&db=" +
        this.voucher.db +
        "&id=voucher___" +
        hash.data +
        "&location=" +
        this.voucher.location;
      console.log(qrCode);
      this.voucher.qrCode = qrCode;
    } else {
      this.alert.nextEmit(
        AlertsService.error(
          "Error generating QR code",
          hash && hash.error ? hash.error : "no error given"
        )
      );
    }
  }
}

export class Voucher {
  name: string = "";
  description: string = "";
  date: number = Date.now();
  key: string = "";
  db: string = "";
  location: string = "";
  newOnly: boolean = false;
  songCredits: number = 0;
  drinkCredits: number = 0;

  onePerPerson: boolean = true;
  maxUses: number = 1;
  equivalent: string[] = [];

  creator: string;
  edits: any[] = [];
  usages = 0;
  qrCode: string = "";
}
