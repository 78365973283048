<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="color: black" id="modal-basic-title">
      Delete Shift
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      X
    </button>
  </div>
  <div class="modal-body" style="color: black">
    <p>Are you sure you want to delete this shift?</p>
    <div class="flex-even">
      <div style="flex-shrink: 1 !important">Name:</div>
      <div>{{ shift.name }}</div>
    </div>
    <div class="flex-even">
      <div style="flex-shrink: 1 !important">Date</div>
      <div>{{ sDate | date : "yyyy LLL dd" }}</div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Cross click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Delete Shift
    </button>
  </div>
</ng-template>

<ng-template #infractionsModal let-modal style="color: black; padding: 10px">
  <div class="staff-info-header" style="color: black">
    Issues with {{ review.start | date : "yyyy LLLL EEE dd" }} →
    {{ review.end | date : "EEE dd" }}
  </div>
  <div class="modal-body" style="color: black">
    <div *ngFor="let day of infractions">
      <h3>{{ day.date | date : "yyyy LLLL EEE dd" }}</h3>
      <div *ngFor="let inf of day.infractions">
        {{ inf }}
      </div>

      <app-input
        *ngIf="day.infractions.length"
        style="justify-self: flex-end"
        name="Reasoning"
        placeholder="Please explain all the issues (this will only be saved if you 'Save, Send notifiction and Quit')"
        [model]="day.reasoning"
        (changed)="day.reasoning = $event"
      ></app-input>
    </div>
  </div>
  <div class="actions staff-info-header">
    <button
      color="accent"
      class="reactive-button-title green"
      [disabled]="!sortedInfractions()"
      (click)="modal.close('Save click')"
    >
      Save
    </button>

    <button
      color="warn"
      class="reactive-button-title orange"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "Cancel" }}
    </button>
  </div>
</ng-template>

<div class="page-overview">
  <div class="page-box-out smaller">
    <div class="page-box-sub" style="flex-shrink: 0">
      <h3>{{ bar }}</h3>
      <div class="flex-even">
        <label for="start">Start date:</label>
        <input
          [(ngModel)]="startDate"
          type="date"
          id="start"
          name="hoursStart"
          min="2018-01-01"
        />
      </div>

      <div class="flex-even">
        <label for="end">End date:</label>
        <input
          [(ngModel)]="endDate"
          type="date"
          id="end"
          name="hoursEnd"
          min="2018-01-01"
        />
      </div>
      <div class="flex-even">
        <button (click)="reviewMonth = false; getHours()">Go</button>
        <button *ngIf="table && table.length" (click)="makeExcel()">
          To Excel
        </button>
      </div>

      <div class="flex-even">
        <button
          *ngIf="bar != null && bar != ''"
          (click)="
            reviewMonth = false; addingShift = true; reviewingWeek = false
          "
        >
          Add Shift
        </button>
        <button
          *ngIf="bar != null && bar != ''"
          (click)="reviewMonth = false; addingShift = false; seeReviews()"
        >
          Review Week
        </button>
        <button
          *ngIf="bar != null && bar != ''"
          (click)="reviewMonth = true; seeMonthReviews()"
        >
          Review Month
        </button>
      </div>
    </div>
    <div class="page-box-sub" *ngIf="addingShift" style="overflow: visible">
      <h3>Add Shift</h3>

      <div ngbDropdown class="d-inline-block" style="width: 100%">
        <app-input
          style="width: 100px; margin-bottom: 5px"
          type="checkbox"
          [model]="!!filterOn"
          (changed)="filterOn = $event ? 'location' : ''; doFilterStaff()"
          name="Filter Bars"
        ></app-input>
        <button
          type="button"
          style="width: 100%"
          class="btn btn-outline-primary"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          {{ (selectedStaff | name) || "Select Staff" }}
        </button>
        <div
          ngbDropdownMenu
          aria-labelledby="dropdownBasic1"
          style="
            overflow-y: scroll;
            max-height: 250px;
            position: fixed;
            width: 100%;
          "
        >
          <button
            ngbDropdownItem
            *ngFor="let s of filteredStaff"
            (click)="this.selectedStaff = s"
            class="twopartoption"
          >
            <div>
              {{ s | name }}
            </div>
            <div>
              {{ s.bar }}
            </div>
          </button>
        </div>
      </div>

      <label>Date</label>
      <input style="width: 100%" [(ngModel)]="sDate" type="date" />
      <label>Start Time</label>
      <input style="width: 100%" [(ngModel)]="sStart" type="time" />
      <label>End Time</label>
      <input style="width: 100%" [(ngModel)]="sEnd" type="time" />
      <div class="flex-even" style="margin-top: 10px; margin-bottom: 10px">
        <button style="width: 45%" (click)="cancelShift()">Cancel</button>
        <button
          style="width: 45%; background-color: green; color: white"
          (click)="confirmShift()"
        >
          Confirm
        </button>
      </div>
      <div class="flex-even">
        <button style="width: 45%" (click)="resetShift()">Reset</button>
        <button
          style="width: 45%; background-color: red; color: white"
          (click)="deleteShift(content)"
        >
          Delete
        </button>
      </div>
    </div>

    <div
      class="page-box-sub"
      *ngIf="reviewingWeek"
      style="display: flex; flex-direction: column"
    >
      <h3>Week Reviews</h3>

      <label class="review-title">Next Review:</label>

      <div
        *ngIf="review"
        class="review-box extended"
        [style.background-color]="
          review.reviewed != null
            ? review.reviewed
              ? 'green'
              : 'red'
            : 'orange'
        "
      >
        <div class="review-box-title">
          {{ review.start | date : "yyyy LLLL EEE dd" }} →
          {{ review.end | date : "EEE dd" }}
        </div>
        <div class="review-box-info">
          <div>
            <label>Reviewed By:</label>
            <p>{{ review.reviewedBy }}</p>
          </div>
          <div>
            <label>Reviewed on:</label>
            <p>{{ review.timeOfReview | date : "yyyy LLL EEE dd - HH:mm" }}</p>
          </div>
        </div>
      </div>

      <div
        *ngIf="review"
        class="review-box-actions"
        [style.background-color]="
          review.reviewed != null
            ? review.reviewed
              ? 'green'
              : 'red'
            : 'orange'
        "
      >
        <button (click)="approve(false)">Unapprove</button>
        <button (click)="approve(true, infractionsModal)">Approve</button>
      </div>

      <label class="review-title previous">Previous Reviews:</label>

      <div
        *ngFor="let r of reviews"
        class="review-box"
        [style.background-color]="
          r.reviewed != null ? (r.reviewed ? 'green' : 'red') : 'orange'
        "
      >
        <div class="review-box-title">
          {{ r.start | date : "yyyy LLLL EEE dd" }} →
          {{ r.end | date : "EEE dd" }}
        </div>
        <div class="review-box-info">
          <div>
            <label>Reviewed By:</label>
            <p>{{ r.reviewedBy }}</p>
          </div>
          <div>
            <label>Reviewed on:</label>
            <p>{{ r.timeOfReview | date : "yyyy LLL EEE dd - HH:mm" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="page-box"
    style="flex-grow: 1"
    *ngIf="table != null && table.length"
  >
    <div class="table-cont" style="max-height: 650px">
      <table class="table" id="table">
        <ng-container>
          <tr>
            <th
              *ngFor="let cell of table[0]; let row = index"
              [class]="'cell border-bottom fix-to-top'"
              [class.bonus]="table[0][row] == 'Bonus'"
            >
              {{ cell != 0 ? cell : "" }}
            </th>
          </tr>

          <tr
            *ngFor="
              let date of table.slice(1, -(reviewMonth ? 3 : 1));
              let column = index
            "
          >
            <td
              *ngFor="let cell of date; let row = index"
              [class]="'light-border-right'"
              [class.bonus]="table[0][row] == 'Bonus'"
            >
              {{ cell != 0 ? cell : "" }}
            </td>
          </tr>

          <ng-container *ngIf="reviewMonth">
            <tr>
              <th
                class="th-foot cell border-top"
                *ngFor="let cell of table[table.length - 3]; let row = index"
                [class]="'cell'"
              >
                {{ cell != 0 ? cell : "" }}
              </th>
            </tr>
            <tr>
              <th
                class="th-foot-2 cell border-top"
                *ngFor="let cell of table[table.length - 2]; let row = index"
              >
                {{ cell != 0 ? cell : "" }}
              </th>
            </tr>
            <tr>
              <th
                class="th-foot-2 cell border-top"
                *ngFor="let cell of table[table.length - 1]; let row = index"
              >
                {{ cell != 0 ? cell : "" }}
              </th>
            </tr>
          </ng-container>

          <ng-container *ngIf="!reviewMonth">
            <tr>
              <th
                class="th-foot cell border-top"
                *ngFor="let cell of table[table.length - 1]; let row = index"
              >
                {{ cell != 0 ? cell : "" }}
              </th>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
  </div>
</div>
