import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { BackofficeViewService } from "src/app/Helpers/functions/Untill/Swagger/api/backofficeView.service";
import { Configuration } from "src/app/Helpers/functions/Untill/Swagger/configuration";
import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { isArray } from "lodash";
import { Auth } from "src/app/auth/auth";
import { EditStockComponent } from "src/app/Packages/edit-stock/edit-stock.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectNewStockComponent } from "src/app/Packages/select-new-stock/select-new-stock.component";
import { EditStockOrderComponent } from "src/app/Packages/edit-stock-order/edit-stock-order.component";

@Component({
  selector: "app-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.css"],
})
export class StockComponent implements OnInit {
  sortBy = {
    key: "name",
    reverse: false,
  };

  setSortBy(key, changeDirection = true) {
    if (this.sortBy.key === key && !changeDirection) {
    } else if (this.sortBy.key === key) {
      this.sortBy.reverse = !this.sortBy.reverse;
    } else {
      this.sortBy.key = key;
      this.sortBy.reverse = false;
    }

    this.allStock = this.allStock.sort((a, b) => {
      if (a[this.sortBy.key] === undefined || a[this.sortBy.key] === null) {
        return 1;
      }
      if (b[this.sortBy.key] === undefined || b[this.sortBy.key] === null) {
        return -1;
      }

      if (this.sortBy.key === "offset") {
        return (
          (this.sortBy.reverse ? -1 : 1) *
          (Math.abs(a[this.sortBy.key]) - Math.abs(b[this.sortBy.key]))
        );
      }
      if (a[this.sortBy.key] < b[this.sortBy.key]) {
        return this.sortBy.reverse ? 1 : -1;
      } else if (a[this.sortBy.key] > b[this.sortBy.key]) {
        return this.sortBy.reverse ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  barName = "";

  allStock = [];

  tillCategoryChanged($event) {
    console.log($event);
  }
  subs = [];

  tillDrinks = [];
  filteredTillDrinks = [];

  tillInfo;

  drink: any;

  search = "";

  resorted = false;

  variable: any = null;

  constructor(
    private db: StaffAppDatabase,
    private barInfo: BarInfo,
    private http: HttpClient,
    private alert: AlertsService,
    private loading: LoadingService,
    public auth: Auth,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.barInfo.barObserver.subscribe((values) => {
        if (values != null) {
          this.barName = values;
          //console.log('Stock, barname: ' + this.barName);
          this.getStock();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((a) => a.unsubscribe());
  }

  async getStock() {
    this.loading.nextEmit("on");

    this.allStock = await this.db
      .getAllStockSnapshotAsync(this.barName)
      .then((values) => {
        console.log(values);

        var l = [];
        for (var catSnap of values) {
          var key = catSnap.key;

          const val = Object.values(catSnap.payload.val()).map((a: any) => {
            if (!isArray(a.tillCategory)) {
              a.tillCategory = [];
            }
            if (a.saleAreaId) {
              a.saleAreaId = null;
            }
            if (isNaN(a.id)) {
              a.id = 100;
            }
            if (a.saleAreaIds) {
              a.saleAreaIds = null;
            }

            /*

    <!-- (PriceBeforeVAT / PPS -->
    <!--

      Example Cola
      (3.20 / 109) * 100 = 2.9357798165137614678899082568807
      2.936 / 0.58 = 5.06
    -->
*/

            try {
              if (
                a.price === undefined ||
                a.price === null ||
                a.vat === undefined ||
                a.vat === null ||
                a.singlePrice === undefined ||
                a.singlePrice === null ||
                a.price == 0 ||
                a.singlePrice == 0 ||
                a.vat == 0
              ) {
                a.sellingRatio = NaN;
              } else {
                var ratio = ((a.price / (100 + a.vat)) * 100) / a.singlePrice;
                a.sellingRatio = Math.round(ratio * 100) / 100;
              }
            } catch (e) {
              console.log(e, a);
            }
            a.category = key;
            return a;
          });

          l.push(...val);
        }

        return l;
      });
    console.log(this.allStock);
    this.setSortBy(this.sortBy.key, false);
    this.loading.hide();
  }

  resetOffset() {
    if (confirm("Are you sure you want to reset offsets?")) {
      this.db
        .resetOffsets(
          this.barName,
          this.allStock.filter((a) => a.category == "crates" || a.offset != 0)
        )
        .then((v) => {
          //console.log(v);
          this.alert.nextEmit(AlertsService.good("Offsets reset"));
          this.getStock();
        })
        .catch((e) => {
          this.alert.nextEmit(
            AlertsService.error("Offsets Not Reset", JSON.stringify(e, null, 2))
          );
        });
    }
  }

  openItem(s: any, tab = 0) {
    if (this.auth.accessUser.access <= 3) {
      this.alert.nextEmit(
        AlertsService.error("You do not have access to edit stock")
      );
      return;
    }
    console.log("opening ", s);
    //open modal
    var m = this.modal.open(EditStockComponent, {
      size: "lg",
      backdrop: "static",
    });
    m.componentInstance.drink = s;
    m.componentInstance.barName = this.barName;
    m.componentInstance.tab = tab;
    m.result.then(
      (result) => {
        console.log(result);
        if (result && result.command == "next") {
          var index = this.allStock.findIndex((a) => a.serverId == s.serverId);
          if (index < this.allStock.length - 1) {
            this.openItem(this.allStock[index + 1], result.tab || 0);
          }
        }
        this.getStock();
      },
      (reason) => {
        console.log(reason);
        this.getStock();
      }
    );
  }

  openTillStock() {
    if (this.auth.accessUser.access <= 4) {
      this.alert.nextEmit(
        AlertsService.error("You do not have access to edit stock")
      );
      return;
    }
    var m = this.modal.open(SelectNewStockComponent, {
      size: "lg",
      backdrop: "static",
    });
    m.componentInstance.barName = this.barName;
    m.componentInstance.stock = this.allStock;
    m.result.then((result) => {
      if (result && result.command) {
        if (result.command == "addStock") {
          var newStock = {
            name: result.stock.name,
            price: result.stock.prices[0].amount,
            vat: result.stock.prices[0].vat,

            serverId: result.stock.id,
            offset: 0,
            id: 100,
            tillCategory: [],
          };
          this.openItem(newStock);
        }
      }
    });
  }

  reorderStock() {
    var m = this.modal.open(EditStockOrderComponent, {
      size: "lg",
      backdrop: "static",
    });
    m.componentInstance.bar = this.barName;
    m.componentInstance.stock = this.allStock;
    m.result.then(
      (result) => {
        this.getStock();
      },
      (result) => {
        this.getStock();
      }
    );
  }
}
