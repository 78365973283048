import { Pipe, PipeTransform } from "@angular/core";
import { RoleNames, Roles } from "../Enums/roles.enum";

@Pipe({
  name: "scheduleCompetency",
})
export class ScheduleCompetencyPipe implements PipeTransform {
  transform(
    value: string | number,
    getString: boolean = false,
    fromUID = false,
    list: any[] = []
  ): unknown {
    if (fromUID) {
      console.log("fromUID", value, list);
      const role = list.find((r) => r.uid === value);
      if (role) {
        value = Number(role.role);
      }
    }

    console.log("value", value);
    if (typeof value === "number") {
      value = new Roles().roles[value];
    }
    if (getString) {
      return value;
    }
    switch (value) {
      case RoleNames.NEW:
        return "fa-duotone fa-solid fa-chess-pawn";
      case RoleNames.COMPETENT:
        return "fa-duotone fa-solid fa-chess-knight";
      case RoleNames.SOCIAL_HYGIENE:
        return "fa-duotone fa-solid fa-chess-queen";
    }
    return null;
  }
}
