import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { NdOrderItem } from "./ndorder-item";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";
import { ta } from "date-fns/locale";

@Component({
  selector: "app-ndorder-items",
  templateUrl: "./ndorder-items.component.html",
  styleUrls: ["./ndorder-items.component.css"],
})
export class NDOrderItemsComponent implements OnInit {
  selectedDrink: any;
  invoiceCategories = [
    "frisdrank",
    "bieren",
    "bieren alcoholvrij",
    "gedestilleerd",
    "wijnen",
    "schoonmaak",
    "restaurant artikelen",
    "glaswerk",
    "vel",
  ];

  isBar: boolean = false;

  drinks: NdOrderItem[] = [];
  bar;
  bars;
  isNewDrink = false;
  edited = false;
  editing = false;
  constructor(
    private db: StaffAppDatabase,
    public barInfo: BarInfo,
    private loading: LoadingService,
    private alert: AlertsService,
    public modal: NgbModal,
    public auth: Auth
  ) {}

  ngOnInit(): void {
    if (this.barInfo.bar == "Daga Beheer") {
      this.isBar = false;
    } else {
      this.isBar = true;
    }

    this.bars = this.barInfo.bars.filter((value) => {
      return (
        value != "All" &&
        value != "Daga Beheer" &&
        !this.barInfo.onlyDuncan.get(value)
      );
    });
    this.loading.nextEmit("on");
    this.barInfo.barObserver.subscribe((value) => {
      this.bar = value;
      this.db.getNDItems("Daga Beheer"); //always get the drinks from the head office
    });

    this.db.ndObserver.subscribe(async (values) => {
      console.log(values);
      this.loading.nextEmit("on");
      if (values) {
        var positionChanged = false;
        this.drinks = values
          .filter((a) => {
            if (!this.isBar) {
              return true;
            } else {
              return a.bars[this.bar];
            }
          })
          .sort((a, b) => {
            if (this.isBar) {
              return a.bars[this.bar].position - b.bars[this.bar].position;
            } else {
              return a.position - b.position;
            }
          })
          .map((a, index) => {
            a.bars = a.bars || {};
            return a;
          });
      }
      this.loading.nextEmit("");
    });
  }

  getDrinks() {
    this.loading.nextEmit("on");
    this.db.getNDItems("Daga Beheer");
  }

  drinkSelected(i, modal, isNewDrink = false) {
    if (this.isBar || this.auth.user.access < 4) {
      return;
    }
    this.isNewDrink = isNewDrink;
    this.selectedDrink = i;
    this.modal
      .open(modal, {
        backdrop: "static",
      })
      .result.then((result) => {
        console.log(result);
        if (result === "save") {
          this.saveDrink(i);
        }
        if (result === "delete") {
          this.deleteDrink();
        }
      });
  }

  saveDrink(drink) {
    if (!drink) {
      return this.alert.nextEmit(
        AlertsService.error(
          "Failed to Save None Drink",
          "Please make sure all variables are filled in (with  0 if unknown value) "
        )
      );
    }
    drink.price = Number(drink.price);
    drink.vat = Number(drink.vat);
    //drink.vat should be 0 or 9 or 21
    if (drink.vat != 0 && drink.vat != 9 && drink.vat != 21) {
      return this.alert.nextEmit(
        AlertsService.error(
          "Failed to Save None Drink",
          "Please make sure the VAT is 0, 9 or 21 "
        )
      );
    }
    try {
      if (this.isNewDrink) {
        drink.position =
          this.drinks.reduce((a, b) => (a.position > b.position ? a : b))
            .position + 1;
        this.db.addNDItem("Daga Beheer", drink);
      } else {
        this.db.updateNDItem(this.bar, drink);
      }
      this.alert.nextEmit(
        AlertsService.good("Saved None Drink", "Saved None Drink")
      );
      this.cancel();
    } catch (e) {
      console.error(e);
      this.alert.nextEmit(
        AlertsService.error(
          "Failed to Save None Drink",
          "Please make sure all variables are filled in (with  0 if unknown value) " +
            e.message
        )
      );
    }
  }

  async sync() {
    this.loading.nextEmit("on");
    //sync the stock from Daga Beheer to the rest of the bars
    for (var b of this.bars) {
      console.log(b);
      //get the stock of the bar
      var stock: NdOrderItem[] = await this.db
        .getNDItemsAsync(b)
        .then((a) => a.map((a) => a as NdOrderItem));

      // the stock from head office is in the list drinks
      // the stock from the bar is in the list stock

      //any that arent in the bar stock should be added (with a position on the end of the list)
      //any that arent in the head office stock should be deleted
      //any that are in both should be updated (without changing the bar position)

      //not all bars have the same stock
      var takenPositions = new Set();
      for (var drink of stock) {
        if (drink.bars && drink.bars[b])
          takenPositions.add(drink.bars[b].position);
      }
      console.log(takenPositions);
      for (var drink2 of this.drinks) {
        var drink = Object.assign({}, drink2);
        console.log("sorting: " + drink.name);
        if (drink.bars[b] && !stock.find((d) => d.key == drink.key)) {
          console.log("adding: " + drink.name);
          //add the drink to the bar
          var nextAvailablePosition = 0;
          while (takenPositions.has(nextAvailablePosition)) {
            nextAvailablePosition++;
          }

          takenPositions.add(nextAvailablePosition);
          drink.bars[b].position = nextAvailablePosition;
          drink.bars[b].defaultQuantity = drink.defaultQuantity;

          stock.push(drink);
        } else if (!drink.bars[b] && stock.find((d) => d.key == drink.key)) {
          //remove the drink from the bar
          this.db.deleteNDOrderItem(b, drink);
          try {
            takenPositions.delete(drink.bars[b].position);
          } catch (e) {
            // do nothing
          }

          stock = stock.filter((d) => d.key != drink.key);
        } else if (drink.bars[b] && stock.find((d) => d.key == drink.key)) {
          //update the drink in the bar respect the position
          var stockDrink = stock.find((d) => d.key == drink.key);
          var oldPosition = stockDrink.bars[b].position;
          var oldQuantity = stockDrink.bars[b].defaultQuantity;

          stockDrink = drink;
          stockDrink.bars[b].position = oldPosition;
          stockDrink.bars[b].defaultQuantity = oldQuantity;
        }
        await this.db.updateNDItem(b, drink);
      }
      console.log(stock);
      //save the stock list to the bar
    }
    this.loading.nextEmit("");
  }

  newDrink(modal) {
    this.isNewDrink = true;
    this.selectedDrink = {
      name: "",
      defaultQuantity: 0,
      bars: {},
    };
    this.drinkSelected(this.selectedDrink, modal, true);
  }

  cancel() {
    this.selectedDrink = null;
    this.isNewDrink = false;
    this.db.getNDItems("Daga Beheer");
  }

  deleteDrink() {
    if (!window.confirm("Are you sure you want to delete this drink?")) {
      return;
    }
    this.isNewDrink = false;
    this.db.deleteNDOrderItem("Daga Beheer", this.selectedDrink);
    this.cancel();
  }

  sortUp(item: NdOrderItem, index) {
    console.log("up", item, index);

    var swapItem = this.drinks[index - 1];
    var temp = swapItem.bars[this.bar].position;
    swapItem.bars[this.bar].position = item.bars[this.bar].position;
    item.bars[this.bar].position = temp;
    this.saveBoth(swapItem, item);
  }

  sortDown(item: NdOrderItem, index) {
    console.log("down", item, index);

    var swapItem = this.drinks[index + 1];
    var temp = swapItem.bars[this.bar].position;
    swapItem.bars[this.bar].position = item.bars[this.bar].position;
    item.bars[this.bar].position = temp;

    this.saveBoth(swapItem, item);
  }

  async saveBoth(itemA, itemB) {
    this.loading.nextEmit("on");
    this.db.updateNDItemOrder(this.bar, itemA);
    this.db.updateNDItemOrder(this.bar, itemB);
    this.getDrinks();
  }

  editOrder() {
    //allow manager to edit the quanties of the drinks
    this.editing = true;
    this.edited = true;
  }

  saveEdits() {
    //saves the manager edits for that bar
    this.loading.nextEmit("on");
    for (var drink of this.drinks) {
      this.db.updateNDItemQuantity(this.bar, drink);
    }
    this.editing = false;
    this.edited = false;
    this.loading.nextEmit(null);
  }

  barChanged(event, bar, drink) {
    console.log(event, bar, drink);
    if (event) {
      //calculate next available position at the bar
      var position = 0;
      for (var i = 0; i < this.drinks.length; i++) {
        if (this.drinks[i].bars[bar]) {
          position++;
        }
      }
      drink.bars[bar] = {
        position: position,
        active: true,
        defaultQuantity: drink.defaultQuantity,
      };
    } else {
      delete drink.bars[bar];
    }
  }

  fixPositions() {
    for (var i = 0; i < this.drinks.length; i++) {
      if (!this.drinks[i].bars[this.bar]) {
        continue;
      }
      this.drinks[i].bars[this.bar].position = i;
      this.db.updateNDItemOrder(this.bar, this.drinks[i]);
    }
  }
}
