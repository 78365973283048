import { Component, OnInit, Inject } from "@angular/core";

import * as XLSX from "xlsx";
import { Router } from "@angular/router";
import { NgIf } from "@angular/common";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { take } from "rxjs/operators";
import { generate, Subscription } from "rxjs";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { ScheduleInfoDay } from "src/app/Classes/schedule-info-day";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { GenericMessageGroup } from "src/app/Classes/generic-message-group";
import { GenericMessage } from "src/app/Classes/generic-message";
import { timeStamp } from "console";

@Component({
  selector: "app-staffhours",
  templateUrl: "./staffhours.component.html",
  styleUrls: ["./staffhours.component.css"],
})
export class StaffhoursComponent implements OnInit {
  startDate = "";
  endDate = "";

  start: Date = null;
  end: Date = null;
  bar = "";
  bars = [];
  hours = [];
  staff = [];
  reviews = [];
  review: any;

  table: any[][] = null;
  days: Date[] = [];
  cleaning = false;

  subs: Subscription[] = [];

  addingShift = false;
  reviewingWeek = false;
  selectedStaff;
  dbstaff = [];
  sDate;
  sStart;
  sEnd;
  scheduleDayInfo: ScheduleInfoDay[] = [];
  shift = null;
  filteredStaff = [];
  filterOn: string = "location";
  reviewMonth = false;
  constructor(
    public database: StaffAppDatabase,
    private router: Router,
    private bi: BarInfo,
    private alert: AlertsService,
    private modal: NgbModal,
    private auth: Auth,
    private loading: LoadingService
  ) {
    this.table = [];

    if (this.router.url === "/personeel/cleaning") {
      this.cleaning = true;
    } else {
      this.cleaning = false;
    }
    this.subs.push(
      this.bi.barObserver.subscribe((val) => {
        this.bar = val;
      })
    );

    this.getData();

    this.subs.push(
      this.database.hoursObserver.subscribe((hours) => {
        this.table = [];
        if (hours != null) {
          this.fixTable(hours);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  async getData() {
    this.scheduleDayInfo = await this.database.getScheduleInfoDays();
    this.dbstaff = [];
    this.dbstaff = await this.database
      .getStaff()
      .pipe(take(1))
      .toPromise()
      .then((a) => {
        const list = a.filter(
          (s: any) =>
            s.firstName &&
            s.lastName &&
            s.lastName != "na" &&
            s.access >= 1 &&
            s.bar != "Pertempto" &&
            s.bar != "Old Staff"
        );
        return list.sort((b: any, c: any) => {
          if (b.bar == this.bar && c.bar != this.bar) {
            return -1;
          } else if (b.bar != this.bar && c.bar == this.bar) {
            return 1;
          }

          if (b.bar != c.bar) {
            return b.bar.localeCompare(c.bar);
          }
          return b.firstName
            .toLowerCase()
            .localeCompare(c.firstName.toLowerCase(), "en");
        });
      })
      .catch((e) => []);
    this.doFilterStaff();
  }

  doFilterStaff() {
    if (this.filterOn == "") {
      this.filteredStaff = this.dbstaff;
    } else if (this.filterOn == "location") {
      this.filteredStaff = this.dbstaff.filter((a) => {
        return a.bar == this.bar;
      });
    } else {
      this.filteredStaff = this.dbstaff.filter((a) => {
        return (
          a.firstName.toLowerCase().includes(this.filterOn.toLowerCase()) ||
          a.lastName.toLowerCase().includes(this.filterOn.toLowerCase())
        );
      });
    }
  }

  ngOnInit(): void {}

  fixTable(hours) {
    this.staff = [];
    this.hours = hours;
    hours.forEach((h) => {
      if (this.staff.indexOf(h.name) == -1) {
        this.staff.push(h.name);
      }
    });
    this.staff.sort();

    //make array of correct length
    if (this.reviewMonth) {
      this.table = new Array(this.days.length + 4);
    } else {
      this.table = new Array(this.days.length + 2);
    }

    for (let i = 0; i < this.table.length; i++) {
      this.table[i] = new Array(
        this.staff.length * (this.reviewMonth ? 2 : 1) +
          (this.reviewMonth ? 4 : 2)
      );
      for (
        let j = 0;
        j <
        this.staff.length * (this.reviewMonth ? 2 : 1) +
          (this.reviewMonth ? 4 : 2);
        j++
      ) {
        this.table[i][j] = 0;
      }
    }

    this.table[0][0] = this.bar;

    //fill in staff names
    if (this.reviewMonth) {
      for (let i = 0; i < this.staff.length * 2; i += 2) {
        this.table[0][i + 1] = this.staff[i / 2].substring(0, 15);
      }
      for (let i = 1; i < this.staff.length * 2; i += 2) {
        this.table[0][i + 1] = "Bonus";
      }
    } else {
      for (let i = 0; i < this.staff.length; i++) {
        this.table[0][i + 1] = this.staff[i].substring(0, 15);
      }
    }

    //fill in the date
    for (let i = 0; i < this.days.length; i++) {
      this.table[i + 1][0] = this.days[i].toLocaleDateString().split(" ");
    }

    //add each hourrecord to the table
    hours.forEach((h) => {
      if (
        this.days
          .map(Number)
          .indexOf(new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()) ==
        -1
      ) {
        //possible error
      } else if (this.staff.indexOf(h.name) == -1) {
        //possible error
      } else {
        if (this.cleaning && h.cleaning) {
          let num = Number(
            this.table[
              1 +
                this.days
                  .map(Number)
                  .indexOf(
                    new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                  )
            ][this.staff.indexOf(h.name) * (this.reviewMonth ? 2 : 1) + 1]
          );
          num += Number(h.total);

          this.table[
            1 +
              this.days
                .map(Number)
                .indexOf(
                  new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                )
          ][this.staff.indexOf(h.name) * (this.reviewMonth ? 2 : 1) + 1] = (
            Math.round(num * 100) / 100
          ).toFixed(2);
        } else if (!this.cleaning && !h.cleaning) {
          if (this.reviewMonth) {
            var scheduleSetups = this.scheduleDayInfo.filter(
              (a) => Number(a.id) == new Date(h.year, h.month, h.day).getDay()
            )[0];
            //was this person closing?
            if (
              h.endTime < 1200 &&
              h.endTime >= Number(scheduleSetups.closeTime.replace(":", ""))
            ) {
              //this person was closing//how many people worked this day?
              var worked = hours.filter(
                (a) =>
                  a.year === h.year &&
                  a.month === h.month &&
                  a.day === h.day &&
                  a.cleaning === h.cleaning
              ).length;

              //how much money was made this day?
              var money = this.monthTelling.filter((a) => {
                var aDate = new Date(a.date);
                return (
                  aDate.getFullYear() === h.year &&
                  aDate.getMonth() === h.month &&
                  aDate.getDate() === h.day
                );
              })[0];
              var calculateBonus: string | number = 0;
              var totalMade =
                Number(money?.end.realCash || 0) +
                Number(money?.end.realPin || 0) +
                Number(money?.internet || 0);

              if (
                totalMade / Math.min(worked, 4) >
                scheduleSetups.bonusRequirement
              ) {
                //qualify for bonus
                //are there more than 2 working after closing time
                var closingStaff = hours.filter(
                  (a) =>
                    a.endTime < 1200 &&
                    a.endTime >=
                      Number(scheduleSetups.closeTime.replace(":", "")) &&
                    a.year === h.year &&
                    a.month === h.month &&
                    a.day === h.day &&
                    a.cleaning === h.cleaning
                );
                console.log(closingStaff);
                if (closingStaff.length > 2) {
                  calculateBonus = "*";
                } else {
                  calculateBonus = Number(scheduleSetups.bonusAmount);
                }
              }

              //add the bonus to the correct spot

              this.table[
                1 +
                  this.days
                    .map(Number)
                    .indexOf(
                      new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                    )
              ][this.staff.indexOf(h.name) * 2 + 2] =
                typeof calculateBonus == "string"
                  ? calculateBonus
                  : (Math.round(calculateBonus * 100) / 100).toFixed(2);
            }

            //reviewing month for none cleaning
            //for each day put the hour in the correct spot
            let num = Number(
              this.table[
                1 +
                  this.days
                    .map(Number)
                    .indexOf(
                      new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                    )
              ][this.staff.indexOf(h.name) * 2 + 1]
            );
            num += Number(h.total);
            console.log(
              "adding" +
                h.total +
                " to " +
                num +
                "  on " +
                h.name +
                "  " +
                h.day +
                "  " +
                h.month +
                "  " +
                h.year
            );
            this.table[
              1 +
                this.days
                  .map(Number)
                  .indexOf(
                    new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                  )
            ][this.staff.indexOf(h.name) * 2 + 1] = (
              Math.round(num * 100) / 100
            ).toFixed(2);
          } else {
            let num = Number(
              this.table[
                1 +
                  this.days
                    .map(Number)
                    .indexOf(
                      new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                    )
              ][this.staff.indexOf(h.name) + 1]
            );
            num += Number(h.total);

            this.table[
              1 +
                this.days
                  .map(Number)
                  .indexOf(
                    new Date(h.year, h.month, h.day, 10, 0, 0, 0).getTime()
                  )
            ][this.staff.indexOf(h.name) + 1] = (
              Math.round(num * 100) / 100
            ).toFixed(2);
          }
        }
      }
    });

    //footers
    if (this.reviewMonth) {
      this.table[this.table.length - 3][0] = "Total - Bonus";
      this.table[this.table.length - 2][0] = "Total (incl)";
      for (let i = 1; i < this.staff.length * 2 + 3; i += 2) {
        let sum = 0;
        let bonusSume = 0;
        for (let j = 1; j < this.days.length + 2; j++) {
          sum += Number(this.table[j][i]);
          bonusSume += Number(this.table[j][i + 1]) || 0;
        }
        this.table[this.table.length - 3][i] = (
          Math.round(sum * 100) / 100
        ).toFixed(2);
        this.table[this.table.length - 3][i + 1] = (
          Math.round(bonusSume * 100) / 100
        ).toFixed(2);
        this.table[this.table.length - 2][i] = (
          Math.round((sum + bonusSume) * 100) / 100
        ).toFixed(2);
      }

      this.table[this.table.length - 1][0] = "Total / 7";
      for (let i = 1; i < this.staff.length * 2; i += 2) {
        this.table[this.table.length - 1][i] = Math.round(
          this.table[this.table.length - 2][i] / 7
        ).toFixed(0);
      }
    } else {
      //staff totals
      this.table[this.table.length - 1][0] = "Total";

      for (let i = 1; i < this.staff.length + 1; i++) {
        let sum = 0;
        for (let j = 1; j < this.days.length + 1; j++) {
          sum += Number(this.table[j][i]);
        }
        this.table[this.table.length - 1][i] = (
          Math.round(sum * 100) / 100
        ).toFixed(2);
      }
    }

    //day totals

    this.table[0][this.table[0].length - 1] = "Total (inc)";

    if (this.reviewMonth) {
      this.table[0][this.table[0].length - 3] = "Total (exc)";
      this.table[0][this.table[0].length - 2] = "Bonus";
      for (let i = 0; i <= this.days.length; i++) {
        let sum = 0;
        let bonusSum = 0;
        for (let j = 1; j <= this.staff.length * 2 + 1; j += 2) {
          sum += Number(this.table[i + 1][j]);
          console.log(
            this.table[i + 1][j + 1],
            typeof this.table[i + 1][j + 1]
          );
          bonusSum += Number(this.table[i + 1][j + 1]) || 0;
        }
        this.table[i + 1][this.staff.length * 2 + 1] = (
          Math.round(sum * 100) / 100
        ).toFixed(2);
        this.table[i + 1][this.staff.length * 2 + 2] = (
          Math.round(bonusSum * 100) / 100
        ).toFixed(2);
        this.table[i + 1][this.staff.length * 2 + 3] = (
          Math.round((sum + bonusSum) * 100) / 100
        ).toFixed(2);
      }
      var incSum = 0;
      var div7 = 0;
      for (let s = 1; s < this.staff.length * 2 + 1; s += 2) {
        incSum += Number(this.table[this.table.length - 2][s]);
        div7 += Number(this.table[this.table.length - 1][s]);
      }

      this.table[this.table.length - 2][this.table[0].length - 1] = incSum;
      this.table[this.table.length - 1][this.table[0].length - 1] = div7;
    } else {
      for (let i = 0; i <= this.days.length; i++) {
        let sum = 0;
        for (let j = 1; j <= this.staff.length; j++) {
          sum += Number(this.table[i + 1][j]);
        }
        this.table[i + 1][this.staff.length + 1] = (
          Math.round(sum * 100) / 100
        ).toFixed(2);
      }
    }
    console.log(this.table);
  }

  getHours() {
    if (
      this.startDate == "" ||
      this.endDate == "" ||
      this.bar == "" ||
      !this.bar
    ) {
      this.alert.nextEmit(
        AlertsService.error(
          "Missing Values",
          "Missing StartDate, EndDate or Bar"
        )
      );
      this.loading.nextEmit(null);
      return;
    }
    let start = new Date(this.startDate);

    let end = new Date(this.endDate);

    if (start >= end) {
      this.alert.nextEmit(
        AlertsService.error(
          "Dates in invalid order",
          "Start Date is after End Date"
        )
      );
      this.endDate = this.startDate;
      this.loading.nextEmit(null);
      return;
    }
    start.setHours(10);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(10);
    end.setMinutes(0);
    end.setSeconds(0);
    end.setMilliseconds(0);

    this.start = start;
    this.end = end;

    this.getDates(start, end);

    let startSplits = this.startDate
      .split("T")[0]
      .split("-")
      .map((x) => Number(x));
    let endSplits = this.endDate
      .split("T")[0]
      .split("-")
      .map((x) => Number(x));

    this.database.getHours(
      this.bar,
      startSplits[0],
      startSplits[1] - 1,
      startSplits[2],
      endSplits[0],
      endSplits[1] - 1,
      endSplits[2],
      this.cleaning
    );
    this.loading.nextEmit(null);
  }

  getDates(startDate: Date, stopDate: Date) {
    stopDate.setHours(23, 59, 59, 0);
    let temp = [];

    while (startDate <= stopDate) {
      temp.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
    this.days = temp;
  }

  filename = "";
  makeExcel() {
    if (this.cleaning) {
      this.filename =
        this.bar +
        "_cleaning_" +
        this.start.toISOString() +
        "-" +
        this.end.toISOString() +
        ".xlsx";
    } else {
      this.filename =
        this.bar +
        "_" +
        this.start.toISOString() +
        "-" +
        this.end.toISOString() +
        ".xlsx";
    }

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById("table"),
      { raw: true }
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, this.filename);
  }

  cancelShift() {
    this.selectedStaff = null;
    this.sDate = null;
    this.sStart = null;
    this.sEnd = null;
    this.addingShift = false;
  }

  resetShift() {
    this.selectedStaff = null;
    this.sDate = null;
    this.sStart = null;
    this.sEnd = null;
  }

  deleteShift(content: any) {
    if (!this.selectedStaff) {
      this.alert.nextEmit(AlertsService.error("Unknown Staff"));
      return;
    }

    if (!this.sDate) {
      this.alert.nextEmit(AlertsService.error("Unknown Date"));
      return;
    }

    var date = new Date(this.sDate);

    var shift: any = {};
    shift.cleaning = this.cleaning;
    shift.day = date.getDate();
    shift.month = date.getMonth();
    shift.year = date.getFullYear();
    try {
      shift.name = this.dbstaff.filter(
        (a) => a.uid === this.selectedStaff.uid
      )[0]!.firstName;
    } catch (e) {
      try {
        shift.name = this.dbstaff.filter(
          (a) => a.uid === this.selectedStaff.uid
        )[0]!.displayName;
      } catch (e) {
        shift.name = "na";
      }
    }

    shift.key = this.selectedStaff.uid;

    this.shift = Object.assign({}, shift);

    this.modal.open(content).result.then(
      (a) => {
        //delete

        this.database
          .deleteShift(this.bar, date, shift, this.cleaning)
          .then((a) => {
            this.table = null;
            this.alert.nextEmit(
              AlertsService.success(
                "Shift Deleted",
                shift.name + " on: " + this.sDate
              )
            );
            this.shift = null;
            this.getHours();
          })
          .catch((e) => {
            console.error(e);
            this.alert.nextEmit(
              AlertsService.error("Failed to delete shift", e)
            );
          });

        try {
          this.database.changedRecordOld(
            this.bi.bar,
            this.auth.user.uid,
            shift,
            "hours",
            date.getTime() + "_" + shift.key,
            "deleting"
          );
        } catch (e) {}
      },
      (b) => {}
    );
  }

  confirmShift() {
    var date = new Date(this.sDate);

    var shift: any = {};
    shift.cleaning = this.cleaning;
    shift.day = date.getDate();
    shift.month = date.getMonth();
    shift.year = date.getFullYear();

    if (!this.selectedStaff) {
      this.alert.nextEmit(AlertsService.error("Unknown Staff"));
      return;
    }

    if (!this.sDate) {
      this.alert.nextEmit(AlertsService.error("Unknown Date"));
      return;
    }
    if (!this.sStart || !this.sEnd) {
      this.alert.nextEmit(AlertsService.error("Unknown Start/End Times"));
      return;
    }

    shift.name = this.dbstaff.filter(
      (a) => a.uid === this.selectedStaff.uid
    )[0].firstName;
    shift.key = this.selectedStaff.uid;

    const start = this.sStart.split(":");
    const end = this.sEnd.split(":");
    var total = 0;
    if (Number(start[0]) > Number(end[0])) {
      total += Number(end[0]) + (24 - Number(start[0]));
    } else {
      total += Number(end[0]) - Number(start[0]);
    }

    var mins = -Number(start[1]) + Number(end[1]);

    while (mins >= 60) {
      total += 1;
      mins -= 60;
    }

    total += mins / 60;
    shift.total = total;

    shift.startTime = Number(start.join(""));
    shift.endTime = Number(end.join(""));

    if (
      !shift.day ||
      shift.endTime == null ||
      shift.startTime == null ||
      !shift.name ||
      !shift.key
    ) {
      alert("missing info?" + JSON.stringify(shift));

      return;
    }

    this.database
      .setShift(this.bar, date, shift, this.cleaning)
      .then((a) => {
        this.alert.nextEmit(
          AlertsService.success(
            "Shift addded",
            shift.name + " worked: " + shift.startTime + " - " + shift.endTime
          )
        );

        try {
          this.database.changedRecordOld(
            this.bi.bar,
            this.auth.user.uid,
            shift,
            "hours",
            date.getTime() + "_" + shift.key,
            "shift set"
          );
        } catch (e) {}
        this.selectedStaff = null;
        this.getHours();
      })
      .catch((e) => {
        this.alert.nextEmit(AlertsService.error("Failed to adds shift", e));
      });
  }

  hasRoleBetweenTimes(
    entries: any,
    scheduleDayInfo: ScheduleInfoDay
  ): { startTime: number; endTime: number }[] {
    const targetStart = Number(scheduleDayInfo.openTime.replace(":", ""));
    const targetEnd = Number(scheduleDayInfo.closeTime.replace(":", ""));
    let missingTimes: { startTime: number; endTime: number }[] = [];
    const convertToMinutes = (time) => {
      let hours = Math.floor(time / 100);
      let minutes = time % 100;
      return hours * 60 + minutes;
    };
    // Generate time slots in 30-minute intervals
    let currentTime = targetStart;
    while (currentTime !== targetEnd) {
      let nextTime = (currentTime + 30) % 2400;
      if (nextTime % 100 >= 60) {
        nextTime =
          (Math.floor(nextTime / 100) + 1) * 100 + ((nextTime % 100) - 60);
      }
      if (nextTime === 0) {
        nextTime = 2400;
      }

      // Check if there's an entry with role 2 within the time slot
      const hasRole2 = entries.some((entry) => {
        let start = convertToMinutes(entry.startTime);
        let end = convertToMinutes(entry.endTime);
        let now = convertToMinutes(currentTime);
        let next = convertToMinutes(nextTime);

        // Handle overnight shifts (if end < start, it means it crosses midnight)
        if (end < 1200) end += 2400; // Add 24 hours
        if (now < 1200) now += 2400;
        if (next < 1200) next += 2400;
        if (start < 1200) start += 2400;

        return (
          entry.role === 2 &&
          ((start <= now && end > now) ||
            (start < next && end >= next) ||
            (start >= now && end <= next))
        );
      });

      if (!hasRole2) {
        missingTimes.push({ startTime: currentTime, endTime: nextTime });
      }
      currentTime = nextTime === 2400 ? 0 : nextTime;
    }

    return missingTimes;
  }

  async dayInfractions(
    hours: any[],
    date: Date,
    scheduleDayInfo: ScheduleInfoDay
  ) {
    if (!scheduleDayInfo) {
      return [];
    }

    date.setHours(1, 0, 0, 0);

    if (!hours.length) {
      //add infraction for no staff
      return ["No Staff Present for this day\n"];
    }

    var infractions = [];

    //get schedule for the day
    var schedule: any = await this.database.getScheduleDay(this.bar, date);
    //schedule is object with date, list of staff string uids and list of times with key and start time as a number in 12h (i.e. 2200 = 10)

    if (!schedule) {
      return ["No Schedule for this day\n"];
    }

    //are there any staff not scheduled who are present
    var extraStaff = hours.filter(
      (a) => !schedule.times.map((b) => b.key).includes(a.key)
    );

    extraStaff.forEach((s) => {
      infractions.push("Staff member " + s.name + " was not scheduled\n");
    });

    //check if all scheduled staff are present and on time

    for (var time of schedule.times) {
      //list of times with key and start time as a number in 12h (i.e. 2200 = 10)
      //get the worked hours for the staff member
      var staff = this.dbstaff.filter((a) => a.uid === time.key)[0];
      if (!staff) {
        infractions.push("Missing Staff information: " + time.key + "\n");
        continue;
      }
      var workedHours = hours.filter((a) => a.key === time.key);

      if (workedHours.length == 0 || !workedHours[0]) {
        // report missing staff
        infractions.push(
          "Scheduled staff has no hours: " +
            staff.firstName +
            " " +
            staff.lastName +
            "\n"
        );
        continue;
      }

      if (workedHours.length > 1) {
        //report multiple shifts
        infractions.push(
          "Scheduled staff has multiple shifts: " +
            staff.firstName +
            " " +
            staff.lastName +
            "\n"
        );
        continue;
      }

      //convert the start time to 24h
      var t = (time.startTime + 12) * 100;

      if (workedHours[0].startTime > t) {
        infractions.push(
          "Staff member " +
            staff.firstName +
            " " +
            staff.lastName +
            " was late\n"
        );
      }

      if (workedHours[0].startTime < t) {
        infractions.push(
          "Staff member " +
            staff.firstName +
            " " +
            staff.lastName +
            " was early\n"
        );
      }
    }

    //was there all the time a person with role =2

    if (scheduleDayInfo.requiresSocialHygiene) {
      var intervals = this.hasRoleBetweenTimes(hours, scheduleDayInfo);

      var previousGroup = false;
      var infractionString = "";
      for (let interval of intervals) {
        if (!previousGroup) {
          infractionString +=
            "No staff with role 'Social Hygiene' present between " +
            interval.startTime +
            " and ";
        }

        // Check if the next interval is directly after the current one
        if (
          interval.endTime ===
            intervals[intervals.indexOf(interval) + 1]?.startTime ||
          (interval.endTime === 2400 &&
            intervals[intervals.indexOf(interval) + 1]?.startTime === 0)
        ) {
          previousGroup = true;
        } else {
          previousGroup = false;
        }

        if (!previousGroup) {
          infractionString += interval.endTime + "\n";
          infractions.push(infractionString);
          infractionString = "";
        }
      }
    }

    //was there more than 2 staff at closing and was the closing time correct
    var closingStaff = hours.filter(
      (a) =>
        a.endTime < 1200 &&
        a.endTime >= Number(scheduleDayInfo.closeTime.replace(":", ""))
    );

    if (closingStaff.length > 2) {
      infractions.push("More than 2 staff present at closing\n");
    }

    if (closingStaff.length < 2) {
      infractions.push("Less than 2 staff present at closing\n");
    }

    //are they closing after closing time
    for (var staff of closingStaff) {
      if (staff.endTime > Number(scheduleDayInfo.closeTime.replace(":", ""))) {
        infractions.push(
          "Staff member " +
            staff.name +
            " left after closing time (bonus should not be included in hours, the system adds this!)\n" +
            staff.endTime +
            " > " +
            scheduleDayInfo.closeTime
        );
      }
    }

    //is there anyone before opening time
    var openingStaff = hours.filter(
      (a) =>
        a.startTime > 1200 &&
        a.startTime <= Number(scheduleDayInfo.openTime.replace(":", ""))
    );

    for (var staff of openingStaff) {
      if (staff.startTime < Number(scheduleDayInfo.openTime.replace(":", ""))) {
        infractions.push(
          "Staff member " + staff.name + " arrived before opening time\n"
        );
      }
    }

    return infractions;
  }
  //reviews
  async calculateInfractions() {
    var infractions = [];
    for (var day of this.days) {
      var date = new Date(day);
      var dayHours = this.hours
        .filter(
          (h) =>
            h.day === date.getDate() &&
            h.month === date.getMonth() &&
            h.year === date.getFullYear()
        )
        .map((a) => {
          var realStaff = this.dbstaff.filter((b) => b.uid === a.key)[0];
          a.role = realStaff.role || 0;
          return a;
        });

      var obj = {
        hours: dayHours,
        date: date,
        infractions: await this.dayInfractions(
          dayHours,
          date,
          this.scheduleDayInfo.filter((a) => Number(a.id) == date.getDay())[0]
        ),
        reasoning: "",
      };

      infractions.push(obj);
    }
    return infractions;
  }

  async handleInfractions() {
    var date = new Date(this.infractions[0].date);
    var date2 = new Date();
    var group = new GenericMessageGroup(
      this.bar +
        "-" +
        date.getFullYear() +
        "-" +
        date.getMonth() +
        "-" +
        date.getDate() +
        "-hours",
      this.bar,
      date2.getTime(),
      [],
      this.infractions[0].date,
      "hours",
      this.auth.accessUser.firstName + " " + this.auth.accessUser.lastName
    );

    for (var i = 0; i < this.infractions.length; i++) {
      var infractionsString = "";
      if (!this.infractions[i].infractions.length) {
        continue;
      }

      var d = new Date(this.infractions[i].date);
      infractionsString += "Day " + d.toDateString() + "\n";
      for (var j = 0; j < this.infractions[i].infractions.length; j++) {
        infractionsString += this.infractions[i].infractions[j] + "\n";
      }

      infractionsString += "Reasoning: " + this.infractions[i].reasoning + "\n";
      var message = new GenericMessage(
        "message",
        infractionsString,
        {
          uid: this.auth.user.uid,
          email: this.auth.user.email,
          displayName:
            this.auth.accessUser.firstName +
            " " +
            this.auth.accessUser.lastName,
        },
        "hours",
        "hours",
        new Date(this.infractions[i].date),
        this.bar
      );
      message.date = d.getTime();
      group.messages.push(message);
    }

    var message = new GenericMessage(
      "message",
      "Has submitted their infractions and reasoning for the hours of " +
        this.bar +
        " on " +
        date.toDateString(),
      {
        uid: this.auth.user.uid,
        email: this.auth.user.email,
        displayName:
          this.auth.accessUser.firstName + " " + this.auth.accessUser.lastName,
      },
      "hours",
      "hours",
      date2,
      this.bar
    );
    this.database.sendGenericMessageEmailSchedule(message, group, "hours");
  }

  sortedInfractions(): Boolean {
    //has every infraction been resolved

    return (
      this.infractions.filter(
        (a) => a.infractions.length != 0 && a.reasoning == ""
      ).length == 0
    );
  }
  infractions: any[] = [];
  async approve(approved = true, modal = null) {
    this.loading.nextEmit("on");

    if (approved && this.bar != "Daga Beheer") {
      this.infractions = await this.calculateInfractions();
      console.log(this.infractions);
      if (this.infractions.filter((a) => a.infractions.length).length) {
        this.loading.hide();
        var result = await this.modal
          .open(modal, {
            centered: true,
            size: "lg",

            backdrop: "static",
          })
          .result.then(
            (result) => {
              this.review.infractions = this.infractions;
              return result;
            },
            (b) => {
              return null;
            }
          );
        this.loading.show();

        if (!result) {
          this.loading.nextEmit(null);
          this.alert.nextEmit(
            AlertsService.error(
              "Infractions not resolved",
              "Please resolve infractions before approving"
            )
          );
          return;
        }
        await this.handleInfractions();
        this.alert.nextEmit(
          AlertsService.success(
            "Infractions resolved",
            "Infractions resolved, approving hours"
          )
        );
      }
    }

    if (this.bar == "Daga Beheer" && this.auth.accessUser.access <= 4) {
      this.alert.nextEmit(
        AlertsService.error(
          "Invalid Acccess",
          "You do not have access to approve Daga Beheer hours, Sorry Jan"
        )
      );
      this.loading.nextEmit(null);
    } else {
      this.reviewingWeek = false;
      this.review.reviewed = approved;
      await this.database.setHourReview(this.bar, this.review);
      this.seeReviews();
    }
  }

  async seeMonthReviews() {
    var date = this.startDate ? new Date(this.startDate) : new Date();
    if (date.getDate() >= 21) {
      this.startDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        21,
        12,
        0,
        0
      ).toISOString();
      this.endDate = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        20,
        12,
        0,
        0
      ).toISOString();
    } else {
      this.startDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        21,
        12,
        0,
        0
      ).toISOString();
      this.endDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        20,
        12,
        0,
        0
      ).toISOString();
    }
    await this.getTelling();
    console.log(this.monthTelling);
    this.getHours();
  }

  monthTelling = [];
  async getTelling() {
    this.monthTelling = [];
    this.loading.show();
    var promises = [];
    var start = new Date(this.startDate);
    var end = new Date(this.endDate);
    while (start < end) {
      const date = new Date(start);
      var telling = this.database
        .getFinalTelling(this.bar, new Date(date))
        .then((a: any) => {
          a.date = date.getTime();
          return a;
        })
        .catch((e) => {
          console.error(e);
          return {
            date: date.getTime(),
          };
        });
      promises.push(telling);
      start.setDate(start.getDate() + 1);
    }

    this.monthTelling = await Promise.all(promises);
    this.loading.hide();
  }

  async seeReviews() {
    this.loading.nextEmit("on");
    this.reviews = [];
    this.review = {};

    this.reviews = await this.database.getHourReviews(this.bar);
    this.reviews = this.reviews.reverse();

    var date = new Date();
    date.setDate(date.getDate() - 1);
    while (date.getDay() < 6) {
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1,
        10,
        0,
        0,
        0
      );
    }

    this.review = {
      start: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 6,
        10,
        0,
        0
      ).getTime(),
      end: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        10,
        0,
        0
      ).getTime(),
      bar: this.bar,
      timeOfReview: new Date().getTime(),
      reviewed: null,
      reviewedBy:
        (this.auth.accessUser.firstName || "na").substring(0, 10) +
          " " +
          this.auth.accessUser.lastName[0] || "na",
      uid: this.auth.accessUser.uid,
    };

    this.startDate = new Date(this.review.start).toISOString().split("T")[0];
    this.endDate = new Date(this.review.end).toISOString().split("T")[0];

    const possibles = this.reviews.filter((a) => a.start === this.review.start);
    if (possibles.length) {
      this.review = possibles[0];
      this.reviews = this.reviews.filter((a) => a.start !== this.review.start);
    }
    this.getHours();
    this.reviewingWeek = true;
  }
}
