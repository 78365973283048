import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Declaration } from "src/app/Classes/declaration";
import { DeclarationReceipt } from "src/app/Classes/declaration-receipt";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { CurrencyPipe } from "@angular/common";
@Component({
  selector: "app-declaration-modal",
  templateUrl: "./declaration-modal.component.html",
  styleUrls: ["./declaration-modal.component.scss"],
})
export class DeclarationModalComponent {
  @Input("declaration") declaration: Declaration;
  canEdit = false;
  edited: boolean = false;
  page: number = 0;
  constructor(
    public loading: LoadingService,
    public alert: AlertsService,
    public firebase: StaffAppDatabase,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.canEdit = this.declaration.status === "submitted";
  }

  openItem(receipt: DeclarationReceipt) {
    window.open(receipt.imageDownloadUrl, "_blank");
  }

  async exportToExcel(declaration: any) {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [];

    // Declaration headers (in one row)
    const declarationHeaders = ["Label", "Value"];
    worksheetData.push(declarationHeaders);

    // Declaration data starts from B2 and C2
    const declarationData = [
      ["Bar", declaration.bar],
      ["ID", declaration.id],
      ["User ID", declaration.userId],
      ["User Name", declaration.userName],
      ["Date Created", new Date(declaration.dateCreated).toLocaleString()],
      [
        "Declaration Date",
        new Date(declaration.declarationDate).toLocaleString(),
      ],
      ["Declaration Name", declaration.declarationName],
      ["Declaration Description", declaration.declarationDescription],
      ["Status", declaration.status],
      [
        "Amount",
        new CurrencyPipe("nl-NL")
          .transform(declaration.amount, "EUR")
          .toString(),
      ],
    ];

    // Add declaration data to worksheet
    worksheetData.push(...declarationData);

    // Add an empty row to separate declaration from receipts
    worksheetData.push([]);

    // Receipt headers (start from row after declaration)
    const receiptHeaders = [
      "Description",
      "Date",
      "Merchant",
      "Category",

      "Amount (Excl)",
      "BTW (%)",
      "Total (incl)",
      "Image URL",
    ];
    worksheetData.push(receiptHeaders);

    var vats = 0;
    var ex21s = 0;
    var ex9s = 0;

    // Add receipt rows
    for (let receipt of declaration.receipts) {
      const imageUrl = await this.firebase.getDeclarationFile(
        declaration,
        receipt
      );
      var excl9 = receipt.amount9 / 1.09;
      var excl21 = receipt.amount21 / 1.21;

      var vat9 = receipt.amount9 - excl9;
      var vat21 = receipt.amount21 - excl21;

      vats += vat9 + vat21;
      ex21s += excl21;
      ex9s += excl9;

      const receiptRow = [
        receipt.description.substring(0, 15), // Shorten to avoid overflowing
        new Date(receipt.date).toLocaleDateString(),
        receipt.merchant.substring(0, 15),
        receipt.category.substring(0, 15),
        null,
        null,
        null,
        { f: `HYPERLINK("${imageUrl}", "View Image")` }, // This is the image URL
      ];

      worksheetData.push(receiptRow);

      if (excl21) {
        const receiptRow = [
          null,
          null,
          null,
          null,
          new CurrencyPipe("nl-NL").transform(excl21, "EUR").toString(),
          "21%",
          new CurrencyPipe("nl-NL")
            .transform(receipt.amount21, "EUR")
            .toString(),
        ];
        worksheetData.push(receiptRow);
      }
      if (excl9) {
        const receiptRow = [
          null,
          null,
          null,
          null,
          new CurrencyPipe("nl-NL").transform(excl9, "EUR").toString(),
          "9%",
          new CurrencyPipe("nl-NL")
            .transform(receipt.amount9, "EUR")
            .toString(),
        ];
        worksheetData.push(receiptRow);
      }

      //add subtotal for each receipt
      const receiptRow2 = [
        null,
        null,
        null,
        null,
        null,
        "Total",
        new CurrencyPipe("nl-NL")
          .transform(receipt.amount21 + receipt.amount9, "EUR")
          .toString(),
      ];

      worksheetData.push(receiptRow2);

      worksheetData.push([]); // Add an empty row after each receipt
      worksheetData.push([]);
    }

    // Add a final empty row to separate the receipts from the total
    worksheetData.push([]);

    // Add the total amount row

    worksheetData.push([
      null,
      null,
      null,
      "Total",
      (ex21s + ex9s).toFixed(2),
      vats.toFixed(2),
      declaration.amount.toFixed(2),
    ]);

    // Create the worksheet from the data array
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    worksheet["!cols"] = [
      { wch: 20 }, // Column A (Label)
      { wch: 40 }, // Column B (Value) - Make this wide
      { wch: 25 }, // Column C (Description)
      { wch: 20 }, // Column D (Merchant)
      { wch: 20 }, // Column E (Category)
      { wch: 15 }, // Column F (Amount)
      { wch: 25 }, // Column G (Receipt Image)
    ];
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Declaration Details");

    // Save the Excel file
    XLSX.writeFile(
      workbook,
      `receipt_${declaration.id}_${declaration.declarationName.replace(
        " ",
        "-"
      )}.xlsx`
    );
  }
  async exportToPDF(declaration: any) {
    const doc = new jsPDF();

    // Additional information for the invoice-like header
    doc.setFontSize(16);
    doc.text("Declaration Details", 10, 10);

    doc.setFontSize(12);
    const headerData = [
      { label: "Bar", value: declaration.bar },
      { label: "ID", value: declaration.id },
      { label: "User ID", value: declaration.userId },
      { label: "User Name", value: declaration.userName },
      {
        label: "Date Created",
        value: new Date(declaration.dateCreated).toLocaleString(),
      },
      {
        label: "Declaration Date",
        value: new Date(declaration.declarationDate).toLocaleString(),
      },
      { label: "Declaration Name", value: declaration.declarationName },
      {
        label: "Declaration Description",
        value: declaration.declarationDescription,
      },
      { label: "Status", value: declaration.status },
      {
        label: "Amount",
        value: new CurrencyPipe("nl-NL")
          .transform(declaration.amount, "EUR")
          .toString(),
      },
    ];

    let yPosition = 20; // Start below the header

    // Loop through header data and add it to the PDF
    headerData.forEach((item, index) => {
      doc.text(`${item.label}: ${item.value}`, 10, yPosition);
      yPosition += 10;
    });

    // Add a line to separate the header from the receipts
    doc.line(10, yPosition + 5, 200, yPosition + 5);
    yPosition += 15; // Increase space after the line

    // Loop through receipts and create the table with photo links
    //add a table header
    var index2 = 0;
    doc.setFontSize(12);
    doc.text("No:", 10, yPosition);
    doc.text("Date", 25, yPosition);
    doc.text("Description", 45, yPosition);
    doc.text("Category", 80, yPosition);
    doc.text("Excl BTW", 110, yPosition);
    doc.text("BTW", 140, yPosition);
    doc.text("Total (incl)", 180, yPosition);

    yPosition += 12; // Increase space after the table header
    doc.setFontSize(10);
    declaration.receipts.forEach((receipt, index) => {
      if (index > 0 && index % 5 === 0) {
        doc.addPage(); // Create a new page after 5 items
      }

      doc.text((index2 + 1).toString(), 10, yPosition);
      doc.text(new Date(receipt.date).toLocaleDateString(), 25, yPosition);
      doc.text(receipt.description.substring(0, 15), 45, yPosition);
      doc.text(receipt.category.substring(0, 15), 80, yPosition);

      if (receipt.amount21) {
        //amount21 is an amount including 21% VAT
        var amountExcl = receipt.amount21 / 1.21;
        doc.text(
          new CurrencyPipe("nl-NL").transform(amountExcl, "EUR").toString(),
          110,
          yPosition
        );

        doc.text("21%", 140, yPosition);

        doc.text(
          new CurrencyPipe("nl-NL")
            .transform(receipt.amount21, "EUR")
            .toString(),
          180,
          yPosition
        );
      }

      if (receipt.amount9) {
        if (receipt.amount21) {
          yPosition += 10;
          index2++;
          doc.text((index2 + 1).toString(), 10, yPosition);
        }
        // Add text content for each receipt
        var amountExcl = receipt.amount9 / 1.09;
        doc.text(
          new CurrencyPipe("nl-NL").transform(amountExcl, "EUR").toString(),
          110,
          yPosition
        );
        doc.text("9%", 140, yPosition);

        doc.text(
          new CurrencyPipe("nl-NL")
            .transform(receipt.amount9, "EUR")
            .toString(),
          180,
          yPosition
        );
      }
      yPosition += 10;
      index2++;
    });
    doc.setFontSize(14);
    doc.text("Total", 90, yPosition);
    //total without VAT
    var totalExcl = declaration.receipts.reduce((a, b) => {
      return a + b.amount21 / 1.21 + b.amount9 / 1.09;
    }, 0);

    doc.text(
      new CurrencyPipe("nl-NL").transform(totalExcl, "EUR").toString(),
      110,
      yPosition
    );

    //total VAT
    var totalVAT = declaration.receipts.reduce((a, b) => {
      return a + b.amount21 - b.amount21 / 1.21 + b.amount9 - b.amount9 / 1.09;
    }, 0);

    doc.text(
      new CurrencyPipe("nl-NL").transform(totalVAT, "EUR").toString(),
      140,
      yPosition
    );

    //total with VAT
    var total = declaration.receipts.reduce((a, b) => {
      return a + b.amount21 + b.amount9;
    }, 0);

    doc.text(
      new CurrencyPipe("nl-NL").transform(total, "EUR").toString(),
      180,
      yPosition
    );

    doc.setFontSize(12);
    // Save the PDF

    for (var index = 0; index < declaration.receipts.length; index++) {
      var receipt = declaration.receipts[index];
      doc.addPage();
      yPosition = 10;
      doc.text("Description: " + receipt.description, 10, yPosition);
      yPosition += 10;
      doc.text("Merchant: " + receipt.merchant, 10, yPosition);
      yPosition += 10;
      doc.text("Category: " + receipt.category, 10, yPosition);
      yPosition += 10;
      doc.text("Amount: " + receipt.amount.toString(), 10, yPosition);
      yPosition += 10;

      doc.text("Amount 21%: " + receipt.amount21.toString(), 10, yPosition);
      yPosition += 10;

      doc.text("Amount 9%: " + receipt.amount9.toString(), 10, yPosition);
      yPosition += 10;

      try {
        // Fetch the image as a Blob
        var url = await this.firebase.getDeclarationFile(declaration, receipt);
        const response = await fetch(url);
        const blob = await response.blob();
        const mimeType = blob.type;
        let imageType = "JPEG"; // Default type, in case the type is not recognized

        // Determine the image type based on the MIME type
        if (mimeType.includes("jpeg") || mimeType.includes("jpg")) {
          imageType = "JPEG";
        } else if (mimeType.includes("png")) {
          imageType = "PNG";
        } else if (mimeType.includes("gif")) {
          imageType = "GIF";
        } else {
          console.warn("Unsupported image type:", mimeType);
        }
        console.log("Image type:", imageType);
        // Convert the Blob to a base64 string
        const reader = new FileReader();
        var promise = new Promise((res, rej) => {
          reader.onloadend = function () {
            const base64Image = reader.result as string;
            // Add the image to the PDF
            console.log("Adding image to PDF");
            var image = new Image();
            image.src = base64Image;
            const imgProps = doc.getImageProperties(image);
            const pdfWidth = doc.internal.pageSize.getWidth();

            // Calculate the target image height, keeping the aspect ratio
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            // Set the maximum width for the image (200mm in this case)
            const maxWidth = 200; // mm
            const scaleFactor = pdfWidth > maxWidth ? maxWidth / pdfWidth : 1;

            // Apply scaling if necessary
            const finalWidth = pdfWidth * scaleFactor;
            const finalHeight = pdfHeight * scaleFactor;

            // Add the image to the PDF
            doc.addImage(
              image,
              imageType,
              5,
              yPosition,
              finalWidth,
              finalHeight
            );

            console.log(imageType, 0, yPosition, pdfWidth, pdfHeight);
            return res(true);
          };
          reader.onerror = function (error) {
            console.error("Error loading image:", error);
            return rej(error);
          };
          reader.readAsDataURL(blob); // Convert Blob to base64
        });

        await promise;
        console.log("Image added to PDF", yPosition);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    }
    doc.save(
      "receipt_" +
        declaration.id +
        "_" +
        declaration.declarationName.replace(" ", "-") +
        ".pdf"
    );
  }

  async saveDeclaration() {
    this.loading.show();
    var result = await this.firebase
      .updateDeclaration(this.declaration)
      .then((t) => null)
      .catch((e) => {
        return e;
      });

    if (result) {
      this.alert.nextEmit(
        AlertsService.error("Error", "Error saving declaration" + result)
      );
    } else {
      this.alert.nextEmit(
        AlertsService.success("Success", "Declaration saved")
      );
      this.edited = false;
    }

    this.loading.hide();
  }

  finalizeDeclaration() {
    this.declaration.status = "approved";
    this.canEdit = false;
    this.saveDeclaration();
  }

  async archiveDeclaration() {
    if (
      !confirm(
        "Are you sure you want to archive this declaration? You will not be easily able to access it again"
      )
    )
      return;
    this.declaration.status = "archived";
    await this.saveDeclaration();
    this.activeModal.close();
  }

  async deleteDeclaration() {
    if (
      !confirm(
        "Are you sure you want to delete this declaration? It will be lost forever"
      )
    )
      return;

    this.loading.show();
    var result = await this.firebase
      .deleteDeclaration(this.declaration)
      .then((t) => null)
      .catch((e) => {
        return e;
      });

    this.loading.hide();

    if (result) {
      this.alert.nextEmit(
        AlertsService.error("Error", "Error deleting declaration" + result)
      );
    } else {
      this.alert.nextEmit(
        AlertsService.success("Success", "Declaration deleted")
      );
      this.activeModal.close();
    }
  }

  close() {
    if (this.edited) {
      if (
        confirm("You have unsaved changes, are you sure you want to close?")
      ) {
        this.activeModal.close();
      }
    } else {
      this.activeModal.close();
    }
  }
}
