import { Component, OnInit, TemplateRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Auth } from "src/app/auth/auth";
import { GenericMessage } from "src/app/Classes/generic-message";
import { GenericMessageGroup } from "src/app/Classes/generic-message-group";
import { CalendarCreator } from "src/app/Classes/mycal/calendarCreator.service";
import { CalendarCreatorDay } from "src/app/Classes/mycal/CalendarCreatorDay";
import { ScheduleInfoDay } from "src/app/Classes/schedule-info-day";
import { Roles } from "src/app/Enums/roles.enum";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import {
  ChangeRecord,
  StaffAppDatabase,
} from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-schedule2",
  templateUrl: "./schedule2.component.html",
  styleUrls: ["./schedule2.component.css"],
})
export class Schedule2Component implements OnInit {
  schedule: any = {};
  requests: any = {};
  subs = [];
  staff = [];
  bar;
  selectedStaff;
  now = Date.now();
  today = new Date();
  addDays;
  WEEKDAYS = ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sat"];
  changes: ChangeRecord[] = [];
  page = 0;
  roles = new Roles().roles;

  selectedDay: number;
  selectedWeek: any[];

  public monthDays: CalendarCreatorDay[];

  public monthNumber: number = this.today.getMonth();
  public year: number = this.today.getFullYear();

  scheduleTarget: ScheduleInfoDay[] = [];
  public weekDaysName = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  edits = false;

  reason: any;

  scheduleSub;
  planSub;
  currentDate: Date = new Date();
  infractions = [];
  selectedInfractions = [];
  constructor(
    private db: StaffAppDatabase,
    private bi: BarInfo,
    public calendarCreator: CalendarCreator,
    public modal: NgbModal,
    public auth: Auth,
    public alert: AlertsService,
    public loading: LoadingService
  ) {}

  openReason(content, uid, reason) {
    this.reason = {
      name: this.staff.filter((a) => a.uid === uid)[0],
      reason: reason,
    };
    //console.log(this.reason)

    this.modal
      .open(content, { size: "lg" })
      .result.then((a) => {
        setTimeout(() => {
          this.reason = null;
        }, 350);
      })
      .catch((e) => {
        setTimeout(() => {
          this.reason = null;
        }, 350);
      });
  }

  calculateClass(d) {
    var day = this.selectedWeek[d];

    //is there a staff at 8pm?

    if (day.schedule.staff?.times?.length == 0) {
      return "";
    }

    var staff = day.schedule.staff;
    var times = day.schedule.times;

    var eight = staff.filter((a) => {
      var t = times.filter((b) => b.key === a.uid)[0];
      return t.startTime === 8;
    });

    if (eight.length === 0) {
      return "";
    }

    // so atleast one person is working at 8pm

    // are either of them social hygiene?

    eight.forEach((e) => {
      e.role = this.staff.filter((a) => a.uid === e.uid)[0].role;
    });

    var social = eight.filter((a) => a.role === 2);

    if (social.length > 0) {
      return "social hygiene";
    }

    // are either of them competent?

    var competent = eight.filter((a) => a.role === 1);
    return competent.length > 0 ? "competent" : "new";
  }
  blockingReason: string = "";
  async toggleWalletBlock(model) {
    var st = this.selectedStaff;
    this.blockingReason = "";
    if (st.isBlockedFromWallet) {
      //unblocking
      var e = await this.db
        .setStaffVariable("isBlockedFromWallet", st, false)
        .then((a) => null)
        .catch((e) => {
          console.error(e);
          return e;
        });
      await this.db
        .setStaffVariable("blockedReason", st, "")
        .then((a) => null)
        .catch((e) => {
          console.error(e);
          return e;
        });
      await this.db.setWalletBlocked(
        this.bar,
        this.selectedStaff,
        this.auth.accessUser,
        false,
        "unblocking"
      );
      if (e) {
        alert("failed to change wallet block: " + e);
        this.selectedStaff.isBlockedFromWallet = true;
      } else {
        this.selectedStaff.isBlockedFromWallet = false;
      }
    } else {
      this.modal
        .open(model, {
          size: "lg",
          fullscreen: true,
          backdrop: "static",
          backdropClass: "backdrop",
          windowClass: "second-modal",
        })
        .result.then(
          async (a) => {
            //block
            var e = await this.db
              .setStaffVariable("isBlockedFromWallet", st, true)
              .then((a) => null)
              .catch((e) => {
                console.error(e);
                return e;
              });
            await this.db
              .setStaffVariable("blockedReason", st, this.blockingReason)
              .then((a) => null)
              .catch((e) => {
                console.error(e);
                return e;
              });
            await this.db.setWalletBlocked(
              this.bar,
              this.selectedStaff,
              this.auth.accessUser,
              true,
              this.blockingReason
            );
            if (e) {
              if (e) {
                alert("failed to change wallet block: " + e);
                this.selectedStaff.isBlockedFromWallet = false;
              } else {
                this.staff.filter(
                  (a) => a.uid === st.uid
                )[0].isBlockedFromWallet = false;
              }
            } else {
              this.selectedStaff.isBlockedFromWallet = true;
            }
          },
          (b) => {
            //unblock
          }
        );
    }
  }
  ngOnInit(): void {
    this.createCalendar();

    this.subs.push(
      this.bi.barObserver.subscribe((value) => {
        if (value) {
          this.bar = value;
          this.getStaff(value);
        }
      })
    );

    this.getData();

    console.log(this.auth.user.access, this.auth.accessUser);
  }

  async getData() {
    var temp = await this.db.getScheduleInfoDays();
    //move the first item to the end
    temp.push(temp.shift());
    this.scheduleTarget = temp;
  }

  export() {
    const schedule = document.getElementById("week-requests");
    if (!schedule) return;

    // Format the date for the title
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Create a temporary wrapper to add a title dynamically
    const wrapper = document.createElement("div");
    wrapper.style.textAlign = "center";
    wrapper.style.fontSize = "18px";
    wrapper.style.fontWeight = "bold";
    wrapper.style.marginBottom = "20px";
    wrapper.style.color = "black";
    wrapper.innerText = `Schedule Report - ${formattedDate}`;

    // Clone the schedule and append it inside the wrapper (so we don't change the actual UI)
    const clonedSchedule = schedule.cloneNode(true) as HTMLElement;
    wrapper.appendChild(clonedSchedule);
    document.body.appendChild(wrapper); // Temporarily add to body for capturing

    html2canvas(wrapper, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      document.body.removeChild(wrapper); // Remove temp wrapper after capturing
      if (
        confirm(
          "Would you like to download the schedule as a PDF (Yes) else PNG (No)?"
        )
      ) {
        // **Option 2: Convert to PDF**
        const pdf = new jsPDF("landscape", "mm", "a4");
        const imgWidth = 297; // A4 width in mm (landscape)
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
        pdf.addImage(imgData, "PNG", 10, 20, imgWidth - 20, imgHeight);
        pdf.text(`Schedule Report - ${formattedDate}`, 10, 10); // Add title inside PDF
        pdf.save(`schedule_${today.toISOString().split("T")[0]}.pdf`);
        return;
      }
      // **Option 1: Download as PNG**
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `schedule_${today.toISOString().split("T")[0]}.png`;
      link.click();
    });
  }

  getStaff(value: string) {
    this.subs.push(
      this.db.getStaffAtBar(value).subscribe(async (values) => {
        var staff = [];
        values.forEach((s: any) => {
          if (this.selectedStaff && this.selectedStaff.uid === s.uid) {
            this.selectedStaff = s;
          }

          if (!s.userColor) {
            s.userColor = this.uidToColour(s.uid);
            console.log("default: " + s.userColor);
          } else {
            console.log("custom: " + s.userColor);
          }

          if (!Number.isInteger(s.target)) {
            s.target = 3;
          }

          if (!Number.isInteger(s.role)) {
            s.role = 0;
          }
          staff.push(s);
        });
        staff = staff.sort((a: any, b: any) => {
          if (a.target > b.target) {
            return -1;
          }

          if (a.target < b.target) {
            return 1;
          }

          return -a.role + b.role;
        });
        //console.log(staff);
        this.staff = [];
        this.staff = staff;
        console.log(this.staff);
        await this.getSchedule();
        await this.getStaffSchedules();
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => {
      a.unsubscribe();
    });

    if (this.scheduleSub) {
      this.scheduleSub.unsubscribe();
    }

    if (this.planSub) {
      this.planSub.unsubscribe();
    }
  }

  /* --------------- Database ------------------- */

  async getSchedule() {
    if (!this.currentDate) {
      this.currentDate = new Date();
    }

    const previousMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() - 1,
      1,
      1,
      0,
      0,
      0
    );
    const nextMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      1,
      1,
      0,
      0,
      0
    );

    var values = await this.db.getSchedule(this.bar, this.currentDate);
    console.log(values);
    values = values.concat(await this.db.getSchedule(this.bar, previousMonth));
    console.log(values);

    values = values.concat(await this.db.getSchedule(this.bar, nextMonth));
    console.log("schedule", values);
    this.schedule = {};
    values.forEach((val: any) => {
      try {
        var staff = [];
        let d = new Date(val.date);
        //console.log(d)
        d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 1, 0, 0, 0);

        if (val.staff) {
          val.staff.forEach((key) => {
            staff.push({
              name: this.getName(key),
              uid: key,
              userColor: this.getColor(key),
            });
          });

          this.schedule[d.getTime() + ""] = {
            date: d.getTime(),
            staff: staff,
            times: val.times ? val.times : [],
            infractions: val.infractions ? val.infractions : [],
          };
        }
      } catch (e) {}
    });
  }

  async getStaffSchedules() {
    if (!this.currentDate) {
      this.currentDate = new Date();
    }
    console.log("updating plan");
    const previousMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() - 1,
      1,
      1,
      0,
      0,
      0
    );
    const nextMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      1,
      1,
      0,
      0,
      0
    );
    this.requests = {};
    var values = (await this.db.getPlan(this.bar, this.currentDate)).map(
      (staff) => {
        const month = staff.payload.val();
        for (const [key, value] of Object.entries(month)) {
          if (!this.requests[staff.key]) this.requests[staff.key] = {};
          this.requests[staff.key][
            new Date(
              this.currentDate.getFullYear(),
              this.currentDate.getMonth(),
              Number(key),
              1,
              0,
              0,
              0
            ).getTime() + ""
          ] = value;
        }
      }
    );
    values = values.concat(
      (await this.db.getPlan(this.bar, previousMonth)).map((staff) => {
        const month = staff.payload.val();
        for (const [key, value] of Object.entries(month)) {
          if (!this.requests[staff.key]) this.requests[staff.key] = {};
          this.requests[staff.key][
            new Date(
              previousMonth.getFullYear(),
              previousMonth.getMonth(),
              Number(key),
              1,
              0,
              0,
              0
            ).getTime() + ""
          ] = value;
        }
      })
    );
    values = values.concat(
      (await this.db.getPlan(this.bar, nextMonth)).map((staff) => {
        const month = staff.payload.val();
        for (const [key, value] of Object.entries(month)) {
          if (!this.requests[staff.key]) this.requests[staff.key] = {};
          this.requests[staff.key][
            new Date(
              nextMonth.getFullYear(),
              nextMonth.getMonth(),
              Number(key),
              1,
              0,
              0,
              0
            ).getTime() + ""
          ] = value;
        }
      })
    );

    console.log("got requests", this.requests);
  }

  /* ---------- Listeners ------------------ */
  dayClicked(content, day, modalRef) {
    if (this.selectedWeek) {
      this.deselectWeek(content).then((a) => {
        this.setSelectedWeek(day, modalRef);
      });
    } else {
      this.setSelectedWeek(day, modalRef);
    }
  }

  async setSelectedWeek(day, modalRef) {
    this.selectedDay = (day.weekDayNumber + 6) % 7;

    const start2 = new Date(
      day.year,
      day.monthIndex,
      day.number - ((day.weekDayNumber + 6) % 7),
      1,
      0,
      0,
      0
    );

    const cutOffDate = new Date();
    const now = new Date();
    cutOffDate.setMonth(cutOffDate.getMonth() - 1);
    cutOffDate.setDate(20);

    if (start2.getTime() < cutOffDate.getTime()) {
      alert("You can't edit past schedules");
      return;
    }

    const looper2 = new Date(start2);
    this.selectedWeek = [{}, {}, {}];
    this.infractions = [];
    for (var i = 0; i < 7; i++) {
      var schedule: any = { staff: [], date: looper2.getTime() };
      if (this.schedule[looper2.getTime() + ""]) {
        schedule = this.schedule[looper2.getTime() + ""];
      }
      this.selectedWeek[i] = {
        index: i,
        date: looper2.getTime(),
        requests: {},
        schedule: Object.assign({}, schedule),
      };

      this.infractions.push(
        this.schedule[looper2.getTime() + ""]?.infractions
          ? this.schedule[looper2.getTime() + ""].infractions
          : {
              day: this.WEEKDAYS[new Date(looper2).getDay()],
              infractions: [],
              reasoning: "",
            }
      );

      looper2.setDate(looper2.getDate() + 1);
    }

    for (var s of this.staff) {
      var target =
        s.target != undefined && s.target != null && s.target >= -1
          ? s.target
          : 3;
      const start = new Date(
        day.year,
        day.monthIndex,
        day.number - ((day.weekDayNumber + 6) % 7),
        1,
        0,
        0,
        0
      );

      const looper = new Date(start);
      const k = s.uid;
      for (var i = 0; i < 7; i++) {
        var requests: any = {
          mainTarget: target,
          target: target,
          request: -1,
          reason: "",
        };

        try {
          if (this.requests[k][looper.getTime() + ""]) {
            Object.assign(requests, this.requests[k][looper.getTime() + ""]);
          }
        } catch (e) {}

        this.selectedWeek[i].requests[k] = requests;
        looper.setDate(looper.getDate() + 1);
      }
    }

    this.changes = (
      await this.db.getShortRecords(
        this.bar,
        "schedule",
        this.selectedWeek[0].date
      )
    ).reverse();

    this.modal.open(modalRef, {
      size: "xl",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    console.log(this.selectedWeek);
  }

  //possible needs or a.key
  contains(list: any[], item: any) {
    try {
      if (list.filter((a) => a.uid === item || a.key === item).length) {
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  /* --------------- Schedule ------------------- */

  getName(key) {
    let temp = this.staff.filter((a) => a.uid === key);
    if (temp && temp.length === 1) {
      return temp[0].firstName + " " + temp[0].lastName;
    } else {
      return key;
    }
  }

  getColor(key) {
    let temp = this.staff.filter((a) => a.uid === key);
    if (temp && temp.length === 1) {
      return temp[0].userColor;
    } else {
      return this.uidToColour(key);
    }
  }

  /* -------------------- Calendar Stuff ---------------------- */

  getScheduled(day: number, month: number, year: number) {
    const d = new Date(year, month, day, 1, 0, 0, 0);
    if (
      this.schedule[d.getTime() + ""] &&
      this.schedule[d.getTime() + ""].staff
    ) {
      return this.schedule[d.getTime() + ""].staff;
    } else {
      return [];
    }
  }

  createCalendar() {
    this.setMonthDays(this.calendarCreator.getCurrentMonth());
  }

  onNextMonth(content): void {
    if (this.selectedWeek) {
      this.deselectWeek(content)
        .then((a) => {
          this.monthNumber++;
          this.selectedWeek = null;
          if (this.monthNumber == 12) {
            this.monthNumber = 0;
            this.year++;
          }

          this.setMonth();
        })
        .catch((e) => {});
    } else {
      this.monthNumber++;

      if (this.monthNumber == 12) {
        this.monthNumber = 0;
        this.year++;
      }
      this.setMonth();
    }
  }

  onPreviousMonth(content): void {
    if (this.selectedWeek) {
      this.deselectWeek(content)
        .then((a) => {
          this.selectedWeek = null;
          this.monthNumber--;

          if (this.monthNumber < 0) {
            this.monthNumber = 11;
            this.year--;
          }

          this.setMonth();
        })
        .catch((e) => {});
    } else {
      this.monthNumber--;

      if (this.monthNumber < 0) {
        this.monthNumber = 11;
        this.year--;
      }

      this.setMonth();
    }
  }

  async setMonth() {
    this.currentDate = new Date(this.year, this.monthNumber, 1, 0, 0, 0, 0);

    await this.getSchedule();
    await this.getStaffSchedules();
    this.setMonthDays(
      this.calendarCreator.getMonth(this.monthNumber, this.year)
    );
  }

  private setMonthDays(days: CalendarCreatorDay[]): void {
    this.monthDays = days;
  }

  uidToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  calculateInfractions() {
    /* For each day of the selectedWeek
     * 1. Check if there is the correct amount of staff
     * 2. Check if the staff has the correct role (social hygiene must be present at 8 unless not required then competent must be present)
     * 3. Check if the staff have correct start times
     */
    var oldInfractions = [...this.infractions];
    this.infractions = [];
    for (var i = 0; i < this.selectedWeek.length; i++) {
      var dayInfractions = [];
      var day = this.selectedWeek[i];
      var rules = this.scheduleTarget[i];

      console.log(day, rules);
      var staff = day.schedule.staff;
      var times = day.schedule.times;

      //Check if there is the correct amount of staff
      if (staff.length < rules.numberOfStaff) {
        dayInfractions.push({
          day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
          message: "Not enough staff present",
        });
      }

      if (staff.length > rules.numberOfStaff) {
        dayInfractions.push({
          day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
          message: "Too many staff present",
        });
      }

      //Check if the staff have the correct role
      //If social hygiene is required
      if (rules.requiresSocialHygiene) {
        var staffAtEight = staff
          .filter(
            (a) =>
              times.filter((b) => b.key === a.uid && b.startTime === 8).length >
              0
          )
          .map((a) => a.uid);
        // do any of the staff have the correct role
        var hasStaff =
          this.staff.filter((a) => staffAtEight.includes(a.uid) && a.role === 2)
            .length > 0;
        if (!hasStaff) {
          dayInfractions.push({
            day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
            message: "No social hygiene present at 8",
          });
        }
      } else {
        var staffAtEight = staff
          .filter(
            (a) =>
              times.filter((b) => b.key === a.uid && b.startTime === 8).length >
              0
          )
          .map((a) => a.uid);
        // do any of the staff have the correct role
        var hasStaff =
          this.staff.filter((a) => staffAtEight.includes(a.uid) && a.role === 1)
            .length > 0;
        if (!hasStaff) {
          dayInfractions.push({
            day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
            message: "No competent present at 8",
          });
        }
      }
      var rulesTimes = [...rules.startTimes];
      //Check if the staff have the correct start times
      for (var j = 0; j < staff.length; j++) {
        var staffMember = staff[j];
        var time = times.filter((a) => a.key === staffMember.uid);
        if (time.length === 0) {
          dayInfractions.push({
            day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
            message: staffMember.name + " has no start time",
          });
        }

        //remove rule if staff has correct start time
        var timeNumber = time[0].startTime + 12 + ":00";
        //time number is 12h we need to convert to 24h
        if (timeNumber.includes("12")) {
          timeNumber = "00:00";
        }

        //remove from rules if staff has correct start time
        var index = rulesTimes.indexOf(timeNumber);
        if (index > -1) {
          rulesTimes.splice(index, 1);
        }
      }

      //add remaining rules to infractions
      rulesTimes.forEach((a) => {
        dayInfractions.push({
          day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
          message: "No staff starting at " + a,
        });
      });
      this.infractions.push({
        date: new Date(this.selectedWeek[i].date).getTime(),
        day: this.WEEKDAYS[new Date(this.selectedWeek[i].date).getDay()],
        dayOfWeek: new Date(this.selectedWeek[i].date).getDay(),
        infractions: dayInfractions,
        reasoning:
          oldInfractions.filter(
            (a) => a.date === new Date(this.selectedWeek[i].date).getTime()
          )[0]?.reasoning || "",
      });
    }
    return this.infractions;
  }

  async deselectWeek(content) {
    return new Promise((res, rej) => {
      try {
        if (!this.edits) {
          return res("done");
        }

        this.calculateInfractions();
        console.log(this.infractions);
        this.modal
          .open(content, {
            size: "large",
            fullscreen: true,
            backdrop: "static",
            backdropClass: "darker-backdrop",
          })
          .result.then(
            (result) => {
              if (result === "save" || result === "savenotify") {
                alert(
                  "Saving" + (result === "savenotify" ? " and notifying" : "")
                );
                this.saveWeek(result === "savenotify" ? true : false)
                  .then((a) => {
                    return res("done");
                  })
                  .catch((a) => {
                    alert("failed to save week!");
                    return rej(a);
                  });
              } else {
                return res("done");
              }
            },
            (reason) => {
              return rej(reason);
            }
          );
      } catch (e) {
        return rej(e);
      }
    });
  }

  click() {}

  async cancel(content) {
    await this.deselectWeek(content)
      .then((a) => {
        this.closeConfirmation(content);
      })
      .catch((e) => {});
  }

  closeConfirmation(content) {
    this.modal.dismissAll();
    this.loading.show();
    setTimeout(() => {
      this.selectedWeek = null;
      this.selectedDay = null;
      this.edits = false;
      this.loading.hide();
      this.setMonth();
    }, 350);
  }

  staffRequestClicked(staff, day) {
    this.edits = true;

    this.toggleSchedule(staff, day.index);
  }

  toggleSchedule(s, index) {
    if (
      this.selectedWeek[index].schedule.staff.filter((a) => a.uid == s.uid)
        .length == 0
    ) {
      this.selectedWeek[index].schedule.staff.push({
        uid: s.uid,
        name: s.firstName + " " + s.lastName,
      });
      if (!this.selectedWeek[index].schedule.times) {
        console.log("creating times for " + s.uid);
        this.selectedWeek[index].schedule.times = [];
      }
      if (!this.contains(this.selectedWeek[index].schedule.times, s.uid)) {
        console.log("adding time for " + s.uid);
        this.selectedWeek[index].schedule.times.push({
          key: s.uid,
          startTime: 8,
        });
      } else {
        console.log("already contains");
      }
    } else {
      this.selectedWeek[index].schedule.staff = this.selectedWeek[
        index
      ].schedule.staff.filter((a) => a.uid != s.uid);
      this.selectedWeek[index].schedule.times = this.selectedWeek[
        index
      ].schedule.times.filter((a) => a.key != s.uid);
    }
  }

  getStartTime(week: any, staff) {
    // //console.log(week, staff);
    if (week && staff && week.times) {
      const index = week.times.findIndex((a) => a.key === staff);
      ////console.log(week, index, staff)
      if (index >= 0) {
        return week.times[index].startTime;
      }
    }
    return "";
  }

  timeChanged(week: any, staff, event) {
    //console.log(event, event.target.value);
    if (week && staff && event) {
      const index = week.times.findIndex((a) => a.key === staff);
      //console.log(index, staff, event.target.value)
      if (index >= 0) {
        this.edits = true;
        week.times[index].startTime = Number(event.target.value);
        //console.log(week.times)
      }
    }
  }

  attemptClose(modal, reason) {
    for (var i = 0; i < this.infractions.length; i++) {
      if (
        this.infractions[i].infractions?.length &&
        this.infractions[i].reasoning === ""
      ) {
        alert("Please provide a reason for the infractions");
        return;
      }
    }
    modal.close(reason);
  }

  async saveWeek(notify = false) {
    console.log("saving week", notify);
    if (notify) {
      //check if there are any infractions
      var hasInfractions = this.infractions.filter(
        (a) => a.infractions.length > 0
      ).length;
      if (hasInfractions) {
        var date = new Date(this.selectedWeek[0].date);

        var date2 = new Date();
        var group = new GenericMessageGroup(
          this.bar +
            "-" +
            date.getFullYear() +
            "-" +
            date.getMonth() +
            "-" +
            date.getDate() +
            "-schedule",
          this.bar,
          date2.getTime(),
          [],
          this.selectedWeek[0].date,
          "schedule",
          this.auth.accessUser.firstName + " " + this.auth.accessUser.lastName
        );

        for (var i = 0; i < this.infractions.length; i++) {
          var infractionsString = "";
          if (!this.infractions[i].infractions.length) {
            continue;
          }

          infractionsString += "Day " + this.infractions[i].day + "\n";
          for (var j = 0; j < this.infractions[i].infractions.length; j++) {
            infractionsString +=
              this.infractions[i].infractions[j].message + "\n";
          }

          infractionsString +=
            "Reasoning: " + this.infractions[i].reasoning + "\n";
          var message = new GenericMessage(
            "message",
            infractionsString,
            {
              uid: this.auth.user.uid,
              email: this.auth.user.email,
              displayName:
                this.auth.accessUser.firstName +
                " " +
                this.auth.accessUser.lastName,
            },
            "schedule",
            "schedule",
            new Date(this.selectedWeek[i].date),
            this.bar
          );
          message.date = new Date(this.selectedWeek[i].date).getTime();
          group.messages.push(message);
        }

        var message = new GenericMessage(
          "message",
          "Has submitted their infractions and reasoning for the schedule of " +
            this.bar +
            " on " +
            date.toDateString(),
          {
            uid: this.auth.user.uid,
            email: this.auth.user.email,
            displayName:
              this.auth.accessUser.firstName +
              " " +
              this.auth.accessUser.lastName,
          },
          "schedule",
          "schedule",
          date2,
          this.bar
        );
        message.date = date2.getTime();
        this.db.sendGenericMessageEmailSchedule(message, group, "schedule");
      }

      const list = [
        ...new Set(
          [].concat.apply(
            [],
            this.selectedWeek.map((a) => a.schedule.staff.map((a) => a.uid))
          )
        ),
      ];

      const da2 = new Date(this.selectedWeek[0].date);
      //console.log(list,this.bar,da2.getTime());

      await this.db.triggerScheduleNotification(this.bar, da2.getTime(), list);
    }

    for (var i = 0; i < 7; i++) {
      const date = new Date(this.selectedWeek[i].date);
      console.log("saving date:" + date);

      try {
        //console.log('saving', date, this.selectedWeek[i].schedule.staff, this.selectedWeek[i].schedule.staff.map((a) => {return {key: a.uid, startTime: a.startTime}}));

        await this.db.saveSchedule(
          this.bar,
          {
            date: new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              1,
              0,
              0,
              0
            ).getTime(),

            staff: Object.values(
              this.selectedWeek[i].schedule.staff.map((a) => a.uid)
            ),

            times: this.selectedWeek[i].schedule.times,
            infractions:
              this.infractions.filter(
                (a) =>
                  a.dayOfWeek == new Date(this.selectedWeek[i].date).getDay()
              )[0] || {},
          },
          this.auth.accessUser.uid
        );
      } catch (e) {
        console.error(e);
        alert(
          "couldnt save: " +
            date.getDate() +
            "-" +
            this.WEEKDAYS[i] +
            "\n" +
            e.toString()
        );
      }
    }

    try {
      await this.db.changedRecordOld(
        this.db.bar,
        this.auth.accessUser.uid,
        this.selectedWeek,
        "schedule",
        this.selectedWeek[0].date
      );
    } catch (e) {
      console.error(e);
    }
    this.setMonth();
  }

  isSelectedDay(day) {
    if (!this.selectedWeek) {
      return false;
    }

    for (var i = 0; i < 7; i++) {
      try {
        const date = new Date(
          day.year,
          day.monthIndex,
          day.number + i,
          0,
          0,
          0,
          0
        );
        if (
          this.selectedWeek[i] &&
          this.sameDay(new Date(this.selectedWeek[i].date), date)
        ) {
          return true;
        }
      } catch (e) {}
    }

    return false;
  }

  sameMonth(a: any, b: any) {
    return new Date(a).getMonth() === new Date(b).getMonth();
  }

  sameDay(a: Date, b: Date) {
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    );
  }

  openStaffModal(staffmodal, s) {
    this.selectedStaff = Object.assign({}, s);
    this.modal.open(staffmodal, { size: "lg" }).result.then(
      (suc) => {
        if (
          !Number.isInteger(this.selectedStaff.target) &&
          !Number.isInteger(this.selectedStaff.role)
        ) {
          this.selectedStaff = {};
          return;
        }

        try {
          const target = Number(this.selectedStaff.target);

          if (
            Number.isInteger(target) &&
            target != (Number.isInteger(s.target) ? s.target : -1)
          ) {
            //console.log("saving target", Number(target));
            this.db
              .setStaffTarget(this.selectedStaff, Number(target))
              .then()
              .catch((e) => {
                alert("Failed to save target: " + e);
              });
            //save Target
          }
        } catch (e) {}

        try {
          const role = Number(this.selectedStaff.role);

          if (
            Number.isInteger(role) &&
            role != (Number.isInteger(s.role) ? s.role : -1)
          ) {
            //console.log("saving role", Number(role));
            this.db
              .setStaffRole(this.selectedStaff, Number(role))
              .then()
              .catch((e) => {
                alert("Failed to save target: " + e);
              });
            //save Role
          }
        } catch (e) {}

        try {
          const color = this.selectedStaff.userColor;
          if (color) {
            this.db.setStaffVariable("userColor", this.selectedStaff, color);
          }
        } catch (e) {}

        this.selectedStaff = {};
      },
      (err) => {
        this.selectedStaff = {};
      }
    );
  }
}
