import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NgxPrintDirective, NgxPrintService, PrintOptions } from "ngx-print";
declare var require: any;
import { read, utils, write, writeFile, writeFileAsync } from "xlsx";

import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import { el, he } from "date-fns/locale";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { AlertsService } from "../alerts/alerts.service";
import { InvoiceData } from "src/app/Components/Instellingen/invoice-splitter/invoice-splitter.component";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import jspdf from "jspdf";

@Component({
  selector: "app-pdfmaker",
  templateUrl: "./pdfmaker.component.html",
  styleUrls: ["./pdfmaker.component.css"],
})
export class PDFMakerComponent implements OnInit {
  ngOnInit(): void {
    if (this.data) {
      this.getEmailStatus();
    }
  }

  data;
  @Input("data") set dataSet(data: any) {
    console.log("got data", data);
    this.data = data;
    if (data) {
      this.getEmailStatus();
    }
  }
  @Input("showEmbellage") showEmbellage = true;
  @Input() type = "";

  title = "htmltopdf";

  constructor(
    private renderer: Renderer2,
    private print: NgxPrintDirective,
    public modal: NgbActiveModal,
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public printService: NgxPrintService
  ) {}

  @ViewChild("pdfTable") pdfTable: ElementRef;

  async downloadAsPDF() {}

  async getEmailStatus() {
    var email: any = await this.db.getInvoiceDrinksOrder(
      this.db.bar,
      this.data.information.key
    );
    console.log("email", email);
    if (email && email.emailStatus) {
      this.data.emailStatus = email.emailStatus;
    } else {
      this.data.emailStatus = "not sent";
    }
  }

  exportExcel() {
    let element = document.getElementById("invoice-table");
    let element2 = document.getElementById("total-table");

    const invoice = utils.table_to_sheet(element);
    const total = utils.table_to_sheet(element2);

    let a = utils.sheet_to_json(invoice, { header: 1 });
    let b = utils.sheet_to_json(total, { header: 1 });

    let worksheet = utils.json_to_sheet(a, { skipHeader: true });
    const cols = utils.decode_range(worksheet["!ref"]).e.r + 3;
    console.log(cols);
    console.log(utils.decode_range(worksheet["!ref"]));
    if (this.showEmbellage) {
      const emballage = utils.table_to_sheet(
        document.getElementById("emballage-table")
      );
      let c = utils.sheet_to_json(emballage, { header: 1 });
      utils.sheet_add_json(worksheet, c, {
        origin: "A" + cols,
        skipHeader: true,
      });
    }

    utils.sheet_add_json(worksheet, b, {
      origin: "I" + cols,
      skipHeader: true,
    });

    const wb = utils.book_new();
    utils.book_append_sheet(wb, worksheet, "worksheet");
    writeFile(
      wb,
      this.type +
        "-" +
        this.data.client.name +
        "-" +
        this.data.information.date +
        ".xls"
    );
  }

  async email() {
    var invoiceData = new InvoiceData();

    invoiceData.assignedBar = this.db.bar;
    invoiceData.orderId = this.data.information.key;

    //get bedrijfsinformatie
    var info: any = await this.db.getCompanyInfo(this.db.bar);
    if (!info.accountantemail) {
      this.alert.nextEmit(
        AlertsService.error("Geen accountant email gevonden")
      );
      return;
    }

    invoiceData.email = info.accountantemail;
    invoiceData.status = "pending";
    invoiceData.sender = this.data.client.email;
    invoiceData.type = "invoice";
    console.log(invoiceData);
    this.data.name =
      this.type +
      "-" +
      this.data.client.name +
      "-" +
      this.data.information.date +
      ".pdf";
    //make a pdf

    const element2 = document.getElementById("pdfTable");
    element2.style.overflow = "visible";
    element2.style.border = "none !important";

    const canvas = await html2canvas(element2);
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;

    const pdf = new jspdf("p", "mm", "a4");
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      0,
      position,
      imgWidth,
      imgHeight,
      "",
      "SLOW"
    );
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight + 5;
      pdf.addPage();
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        position,
        imgWidth,
        imgHeight,
        "",
        "SLOW"
      );
      heightLeft -= pageHeight + 5;
    }

    invoiceData.pdfUrl = pdf.output("datauristring");
    //open the pdf
    pdf.save(this.data.name);

    var [result, error] = await this.db.sendEmail(
      invoiceData,
      true,
      this.data.name,
      "Invoice: " +
        this.db.bar +
        " " +
        this.type +
        " " +
        this.data.information.date +
        " "
    );

    if (error) {
      this.alert.nextEmit(AlertsService.error("Email not sent", error));
      //save that the email was not sent
      invoiceData.emailStatus = "failed";
    } else {
      this.alert.nextEmit(AlertsService.good("Email sent", result));
      //save that the email was sent
      invoiceData.emailStatus = "sent";
    }

    this.data.emailStatus = invoiceData.emailStatus;

    this.db.saveInvoiceDrinksOrder(invoiceData);

    //

    return;
  }
}
