import { Injectable, EventEmitter, NgZone } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { LoadingComponent } from "../Packages/loading/loading.component";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private emit = new BehaviorSubject<any>({
    vari: null,
    percent: null,
    location: null,
  });

  modalRefs = [];
  timeout;

  constructor(public modal: NgbModal) {}

  listen() {
    return this.emit.asObservable();
  }

  show() {
    this.nextEmit("on");
  }

  hide() {
    this.nextEmit(null);
  }

  nextEmit(
    vari: string | null = null,
    percent: number | null = null,
    location: string | null = null
  ) {
    // console.log('next emit', vari, percent, location);
    // console.log(this.modal.activeInstances.length)

    if (!vari) {
      this.dismissAll();
      return;
    }

    var modalRef;
    if (this.modalRefs.length > 0) {
      modalRef = this.modalRefs[0];
    } else {
      modalRef = this.modal.open(LoadingComponent, {
        size: "sm",
        centered: true,
        backdrop: "static",
        keyboard: false,
        backdropClass: "loading-backdrop",
        windowClass: "loading-window",
        modalDialogClass: "loading-modal-dialog",
      });
      this.modalRefs.push(modalRef);
    }

    /*
    setTimeout(() => {
      this.emit.next({ vari: "off", percent: null, location: null });
      modalRef.close();
    }, 1000 * 60 * 3);*/

    if (this.timeout) {
      // console.log("clearing timeout");
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.emit.next({ vari: "off", percent: null, location: null });
      this.dismissAll();
    }, 1000 * 60 * 3);

    this.emit.next({ vari, location, percent });
  }

  dismissAll() {
    this.modalRefs.forEach((modalRef) => {
      try {
        modalRef.close();
      } catch (e) {
        console.log("error closing modal", e);
      }
    });
    this.modalRefs = [];
  }
}
