<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">
    {{ declaration.declarationName || "Declaration" }}
  </h3>
</div>

<div class="actions staff-info-header">
  <button
    (click)="page = 0"
    [class]="
      page == 0 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Info
  </button>

  <button
    (click)="page = 1"
    [class]="
      page == 1 ? 'green reactive-button-title' : 'reactive-button-title'
    "
  >
    Receipts
  </button>
</div>

<!--Editing-->
<div class="staff-info-content" *ngIf="page == 0">
  <div class="edit-row">
    <div class="edit-desc">ID:</div>
    <input class="edit" disabled [ngModel]="declaration.id" name="uid" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Name:</div>
    <input
      class="edit"
      [disabled]="!canEdit"
      (ngModelChange)="edited = true"
      [(ngModel)]="declaration.declarationName"
      name="name"
    />
  </div>

  <div class="edit-row">
    <div class="edit-desc">User Id:</div>
    <input class="edit" disabled [ngModel]="declaration.userId" name="uid" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">User Name:</div>
    <input class="edit" disabled [ngModel]="declaration.userName" name="uid" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Declaration Description:</div>
    <input
      class="edit"
      (ngModelChange)="edited = true"
      [disabled]="!canEdit"
      [(ngModel)]="declaration.declarationDescription"
      name="uid"
    />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Date Created:</div>
    <input
      class="edit"
      disabled
      [ngModel]="declaration.dateCreated | date : 'yyyy-MM-dd'"
      name="uid"
    />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Declaration Date:</div>
    <input
      class="edit"
      disabled
      [ngModel]="declaration.declarationDate | date : 'yyyy-MM-dd'"
      name="uid"
    />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Status:</div>
    <input
      class="edit"
      disabled
      [ngModel]="declaration.status | capitalize"
      name="uid"
    />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Amount:</div>
    <input
      class="edit"
      disabled
      [ngModel]="declaration.amount | currency : 'EUR' : 'symbol'"
      name="uid"
    />
  </div>
</div>

<div *ngIf="page == 1">
  <div class="my-table-header" style="padding: 0px 14px">
    <div class="my-table-item-sml"></div>
    <div class="my-table-item-large">
      <div>Name</div>
    </div>
    <div class="my-table-item-imp">
      <div>Merchant</div>
    </div>

    <div class="my-table-item-imp">
      <div>Category</div>
    </div>

    <div class="my-table-item-imp">
      <div>Amount</div>
    </div>

    <div class="my-table-item-imp">
      <div>Amount &#64; 21%</div>
    </div>

    <div class="my-table-item-imp">
      <div>Amount &#64; 9%</div>
    </div>
    <div class="my-table-item-imp">
      <i class="fas fa-camera"></i>
    </div>
  </div>
  <div class="my-table">
    <div *ngFor="let s of declaration.receipts" class="my-table-item">
      <div class="my-table-item-sml">
        <app-id-circle [id]="s.id"></app-id-circle>
      </div>
      <div class="my-table-item-large">
        {{ s.description }}
      </div>

      <div class="my-table-item-imp">
        {{ s.merchant }}
      </div>
      <div class="my-table-item-imp">
        {{ s.category }}
      </div>
      <div class="my-table-item-imp">
        {{ s.amount | currency : "EUR" }}
      </div>
      <input
        class="my-table-item-imp"
        [(ngModel)]="s.amount21"
        type="number"
        [disabled]="!canEdit"
        (keyup)="declaration.sum(); edited = true"
      />
      <input
        class="my-table-item-imp"
        [(ngModel)]="s.amount9"
        type="number"
        [disabled]="!canEdit"
        (keyup)="declaration.sum(); edited = true"
      />
      <button class="my-table-item-imp" (click)="openItem(s)">
        <i class="fas fa-camera"></i>
      </button>
    </div>
  </div>
</div>

<div
  class="actions staff-info-header"
  style="align-self: flex-end; margin-top: auto"
>
  <button
    color="accent"
    class="reactive-button-title green"
    [disabled]="!edited"
    (click)="saveDeclaration()"
  >
    Save
  </button>

  <button
    color="accent"
    class="reactive-button-title green"
    [disabled]="declaration.status == 'approved'"
    (click)="finalizeDeclaration()"
  >
    Approve
  </button>

  <button
    color="accent"
    class="reactive-button-title green"
    [disabled]="edited || declaration.status == 'created'"
    (click)="exportToExcel(declaration)"
  >
    XLSX
  </button>

  <button
    color="accent"
    class="reactive-button-title green"
    [disabled]="edited || declaration.status == 'created'"
    (click)="exportToPDF(declaration)"
  >
    PDF
  </button>

  <button
    color="warn"
    class="reactive-button-title orange"
    (click)="archiveDeclaration()"
  >
    Archive
  </button>
  <button
    color="warn"
    class="reactive-button-title orange"
    (click)="deleteDeclaration()"
  >
    Delete
  </button>
  <button color="warn" class="reactive-button-title red" (click)="close()">
    {{ edited ? "Cancel" : "Close" }}
  </button>
</div>
