<ng-template #newCompanyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      New Company
    </h4>
  </div>

  <div class="modal-body" style="color: black !important">
    <app-input
      name="Name"
      [model]="company.name"
      (changed)="company.name = $event"
    ></app-input>
    <app-input
      name="Address (street & no)"
      [model]="company.address"
      (changed)="company.address = $event"
    ></app-input>
    <app-input
      name="City"
      [model]="company.city"
      (changed)="company.city = $event"
    ></app-input>
    <app-input
      name="Country"
      [model]="company.state"
      (changed)="company.state = $event"
    ></app-input>
    <app-input
      name="ZIP"
      [model]="company.zip"
      (changed)="company.zip = $event"
    ></app-input>
    <app-input
      name="Phone"
      [model]="company.phone"
      (changed)="company.phone = $event"
    ></app-input>
    <app-input
      name="Email"
      [model]="company.email"
      (changed)="company.email = $event"
    ></app-input>
    <app-input
      name="KVK"
      [model]="company.kvk"
      (changed)="company.kvk = $event"
    ></app-input>
    <app-input
      name="IBAN"
      [model]="company.iban"
      (changed)="company.iban = $event"
    ></app-input>
    <app-input
      name="BTW"
      [model]="company.btw"
      (changed)="company.btw = $event"
    ></app-input>
    <input type="file" (change)="logoChanged($event)" />
    <img style="width: 100px; object-fit: contain" [src]="company.logo" />
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('delete')"
    >
      Delete
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('cancel')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<div class="flex-columns">
  <button class="action-button primary" (click)="newSave()">Create</button>
  <h3>Drafts/Saves</h3>
  <div *ngFor="let save of saves" (click)="this.invoice = save; isEdit = true">
    {{ save.key }}
  </div>

  <h3>Companies</h3>
  <button (click)="addCompany(newCompanyModal)">Add Company</button>
  <div
    *ngFor="let comp of companies"
    (click)="addCompany(newCompanyModal, false, comp)"
  >
    {{ comp.name }}
  </div>
</div>

<div class="flex-columns" *ngIf="isEdit">
  <button (click)="viewInvoice = true">View Invoice</button>
  <button (click)="saveInvoice()">Save</button>
  <button (click)="deleteInvoice()">Delete</button>
  <h2>Invoice Maker</h2>
  <app-input
    name="Company"
    type="select"
    [model]="invoice.company.id"
    (changed)="companyChanged(true, $event)"
    [list]="companiesStrings"
    listHideAll="true"
  ></app-input>
  <app-input
    name="Client"
    type="select"
    [model]="invoice.client.id"
    (changed)="companyChanged(false, $event)"
    [list]="companiesStrings"
    listHideAll="true"
  ></app-input>
  <app-input
    name="Date"
    type="date"
    [model]="invoice.date | date : 'yyyy-MM-dd'"
    (changed)="dateChanged(false, $event)"
  ></app-input>
  <app-input
    name="Due Date"
    type="date"
    [model]="invoice.dueDate | date : 'yyyy-MM-dd'"
    (changed)="dateChanged(true, $event)"
  ></app-input>
  <app-input
    name="Invoice Number"
    type="string"
    [model]="invoice.key"
    (changed)="invoice.key = $event"
  ></app-input>

  <div class="app-divider"></div>
  <h3>Products</h3>
  <button *ngIf="!product" (click)="newProduct()">Create Product</button>
  <ng-container *ngIf="product">
    <app-input
      name="Description"
      [model]="product.descripton"
      (changed)="product.description = $event"
    ></app-input>
    <app-input
      name="Amount"
      type="number"
      [model]="product.amount"
      (changed)="product.amount = $event; product.recalculate()"
    ></app-input>
    <app-input
      name="VAT"
      type="select"
      [model]="product.vatType"
      [list]="vatTypes"
      (changed)="product.vatType = $event; product.recalculate()"
      listHideAll="true"
    ></app-input>
    <app-input
      editable="false"
      name="Total"
      [model]="product.total"
    ></app-input>
    <button (click)="addProduct()">Add Product</button>
  </ng-container>

  <table class="tg">
    <thead>
      <tr>
        <td class="tg-0lax">Description</td>
        <td class="tg-0lax">Amount</td>
        <td class="tg-0lax">BTW 21%</td>
        <td class="tg-0lax">BTW 9%</td>
        <td class="tg-0lax">Totaal</td>
        <td class="tg-0lax">x</td>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let product of invoice.products">
        <td>{{ product.description }}</td>
        <td>{{ product.amount | currency : "EUR" }}</td>
        <td>
          {{ (product.vatType == 21 ? product.vat : 0) | currency : "EUR" }}
        </td>
        <td>
          {{ (product.vatType == 9 ? product.vat : 0) | currency : "EUR" }}
        </td>
        <td>
          {{ product.total | currency : "EUR" }}
        </td>
        <td (click)="removeProduct(product)">x</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td>Total</td>
        <td>{{ invoice.total21 | currency : "EUR" }}</td>
        <td>{{ invoice.total9 | currency : "EUR" }}</td>
        <td>{{ invoice.total | currency : "EUR" }}</td>
      </tr>
    </tfoot>
  </table>
</div>
<div class="flex-columns" *ngIf="viewInvoice" style="position: absolute">
  <button (click)="viewInvoice = false">Close</button>
  <button (click)="makePDF()">Too PDF</button>
  <h2>New Invoice</h2>
  <div class="pdf" #pdf>
    <div class="pdf-header">
      <div class="pdf-logo-name">
        <img class="pdf-logo" [src]="invoice.company.logo" />
      </div>

      <div class="pdf-company-info" style="align-items: flex-end">
        <div>{{ invoice.company.name }}</div>
        <div>{{ invoice.company.address }}</div>
        <div>{{ invoice.company.zip }} {{ invoice.company.city }}</div>
        <div *ngIf="invoice.client.state != invoice.company.state">
          {{ invoice.company.state }}
        </div>
        <div>{{ invoice.company.email }}</div>
        <div class="labelled-div">
          <label> IBAN: </label>
          <div>{{ invoice.company.iban }}</div>
        </div>

        <div class="labelled-div">
          <label> BTW: </label>
          <div>{{ invoice.company.btw }}</div>
        </div>
        <div class="labelled-div">
          <label> KVK: </label>
          <div>{{ invoice.company.kvk }}</div>
        </div>
      </div>
    </div>

    <div class="pdf-info">
      <div class="pdf-company-info">
        <div>{{ invoice.client.name }}</div>
        <div>{{ invoice.client.address }}</div>
        <div>{{ invoice.client.zip }} {{ invoice.client.city }}</div>
        <div *ngIf="invoice.client.state != invoice.company.state">
          {{ invoice.client.state }}
        </div>
        <div>{{ invoice.client.email }}</div>
        <div class="labelled-div" *ngIf="invoice.client.iban">
          <label> IBAN: </label>
          <div>{{ invoice.client.iban }}</div>
        </div>

        <div class="labelled-div" *ngIf="invoice.client.btw">
          <label> BTW: </label>
          <div>{{ invoice.client.btw }}</div>
        </div>
        <div class="labelled-div" *ngIf="invoice.client.kvk">
          <label> KVK: </label>
          <div>{{ invoice.client.kvk }}</div>
        </div>
      </div>
      <div class="pdf-company-info" style="align-items: flex-end">
        <div class="labelled-div">
          <label> Invoice Number: </label>
          <div>{{ invoice.key }}</div>
        </div>
        <div class="labelled-div">
          <label> Date: </label>
          <div>{{ invoice.date | date : "yyyy-MM-dd" }}</div>
        </div>
        <div class="labelled-div">
          <label> Due Date: </label>
          <div>{{ invoice.dueDate | date : "yyyy-MM-dd" }}</div>
        </div>
      </div>
    </div>

    <table class="pdf-table" class="tg">
      <thead>
        <tr>
          <td class="tg-0lax">Description</td>
          <td class="tg-0lax">Amount</td>
          <td class="tg-0lax">BTW 21%</td>
          <td class="tg-0lax">BTW 9%</td>
          <td class="tg-0lax">Totaal</td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let product of invoice.products">
          <td>{{ product.description }}</td>
          <td>{{ product.amount | currency : "EUR" }}</td>
          <td>
            {{ (product.vatType == "21" ? product.vat : 0) | currency : "EUR" }}
          </td>
          <td>
            {{ (product.vatType == "9" ? product.vat : 0) | currency : "EUR" }}
          </td>
          <td>
            {{ product.total | currency : "EUR" }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td style="font-size: 16px; font-weight: bold">Total</td>
          <td style="font-size: 16px; font-weight: bold">
            {{ invoice.total21 | currency : "EUR" }}
          </td>
          <td style="font-size: 16px; font-weight: bold">
            {{ invoice.total9 | currency : "EUR" }}
          </td>
          <td style="font-size: 16px; font-weight: bold">
            {{ invoice.total | currency : "EUR" }}
          </td>
        </tr>
      </tfoot>
    </table>

    <div class="pdf-footer"></div>
  </div>
</div>
