<ng-template #confirmation let-modal>
  <div class="modal-header staff-info-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Actions for week {{ selectedWeek[0].date | date : "LLLL dd" }} to
      {{ selectedWeek[6].date | date : "LLLL dd" }}
    </h4>
  </div>

  <div class="modal-body">
    <div class="week-requests">
      <div class="week-staff" style="">
        <div class="weekname" style="width: 260px">
          <div
            class="day-request"
            ngbTooltip="Role (New, Competent, Social Hygiene)"
            style="margin: 0px 4px; border: transparent !important"
            [style.background-color]="'transparent'"
          >
            <i class="fas fa-ranking-star"></i>
          </div>
          <div style="font-size: x-small; width: 140px">Name</div>
          <div ngbTooltip="Targeted Shifts Per Week" style="font-size: x-small">
            Target
          </div>
        </div>

        <div
          *ngFor="let request of selectedWeek; let i = index"
          [ngClass]="{ dayofweek: i === selectedDay }"
          class="weekday"
          style="white-space: nowrap"
          tooltipClass="white-space-pre-line"
          triggers="hover focus"
          [ngbTooltip]="scheduleTarget[i].pretty()"
        >
          {{ request.date | date : "EEE" }} &nbsp;
          <b>{{ request.date | date : "dd" }}</b>
        </div>
      </div>
      <div class="week-staff" *ngFor="let st of staff">
        <div class="weekname" style="width: 260px">
          <div
            class="day-request"
            style="
              margin: 0px 4px;

              --fa-primary-color: #eeeeee;
              --fa-secondary-color: #2e2e2e;
            "
            [ngbTooltip]="st.role | scheduleCompetency : true | capitalize"
            [style.background-color]="st.userColor"
          >
            <i
              style="padding-top: 2px"
              [class]="st.role | scheduleCompetency"
            ></i>
          </div>
          <div
            style="
              white-space: nowrap;
              word-wrap: unset;
              overflow: hidden;
              width: 140px;
            "
          >
            {{ st | name : "short" }}
          </div>
          <div
            class="target-container"
            [class.red]="
              selectedWeek[0].requests[st.uid].target !=
                selectedWeek[0].requests[st.uid].mainTarget ||
              selectedWeek[0].requests[st.uid].reason != ''
            "
          >
            {{ selectedWeek[0].requests[st.uid].target }} /
            {{ selectedWeek[0].requests[st.uid].mainTarget }}
          </div>
        </div>

        <div
          *ngFor="let d of WEEKDAYS; let i = index"
          [ngClass]="{ dayofweek: i === selectedDay }"
          class="weekday"
        >
          <div
            *ngIf="selectedWeek[i].requests && selectedWeek[i].requests[st.uid]"
            class="day-request"
            [ngClass]="{
              red: selectedWeek[i].requests[st.uid].request === 0,
              orange: selectedWeek[i].requests[st.uid].request === 1,
              green: selectedWeek[i].requests[st.uid].request === 2,
              gray: selectedWeek[i].requests[st.uid].request === -1,
              today:
                selectedWeek[i].requests[st.uid].request === 2 &&
                i === selectedDay
            }"
          >
            <div
              *ngIf="contains(selectedWeek[i].schedule.staff, st.uid)"
              class="working"
            >
              w
            </div>
          </div>
          <div
            class="day-select-container"
            *ngIf="contains(selectedWeek[i].schedule.staff, st.uid)"
          >
            &nbsp;{{ getStartTime(selectedWeek[i].schedule, st.uid) }}pm
          </div>
        </div>
      </div>

      <div class="week-staff">
        <!--Totals -->
        <div class="weekname" style="width: 260px">
          <div style="width: 30px; height: 30px; border-radius: 50px"></div>
          <div style="font-size: x-small; width: 140px"></div>
          <div style="font-size: x-small">Working / Target</div>
        </div>
        <div
          *ngFor="let d of WEEKDAYS; let i = index"
          class="weekday"
          style="border-color: transparent"
        >
          <div
            class="day-request"
            style="
              border-radius: 50px;
              --fa-primary-color: #eeeeee;
              --fa-secondary-color: #2e2e2e;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [ngClass]="{
                red: selectedWeek[i].schedule?.times?.length != scheduleTarget[i].numberOfStaff,
                orange: !selectedWeek[i].schedule?.times?.length,
                green: selectedWeek[i].schedule?.times?.length == scheduleTarget[i].numberOfStaff,
              }"
          >
            <i
              style="padding-right: 1px"
              [class]="calculateClass(i) | scheduleCompetency"
            ></i>
          </div>
          <div>
            &nbsp; {{ selectedWeek[i].schedule?.times?.length }}/{{
              scheduleTarget[i].numberOfStaff
            }}
          </div>
        </div>
      </div>
      <div class="week-staff">
        <!--Totals -->
        <div class="weekname" style="width: 260px">
          <div style="width: 30px; height: 30px; border-radius: 50px"></div>
          <div style="font-size: x-small; width: 140px"></div>
          <div style="font-size: x-small">Issues</div>
        </div>
        <div
          *ngFor="let d of WEEKDAYS; let i = index"
          class="weekday"
          style="border-color: transparent"
        >
          <button
            class="action-button"
            [class.red]="infractions[i].infractions.length"
            [class.green]="!infractions[i].infractions.length"
            [class.selected]="selectedInfractions === infractions[i]"
            [class.orange]="infractions[i].reasoning != ''"
            (click)="
              selectedInfractions == infractions[i]
                ? (selectedInfractions = null)
                : (selectedInfractions = infractions[i])
            "
          >
            &nbsp; {{ infractions[i].infractions.length }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="selectedInfractions?.infractions?.length">
      <h3>Issues on {{ selectedInfractions.date | date : "dd EEEE" }}</h3>
      <div *ngFor="let infraction of selectedInfractions.infractions">
        <div>
          <b style="color: black">{{ infraction.message }}</b>
        </div>
      </div>
    </div>
    <app-input
      *ngIf="selectedInfractions?.infractions?.length"
      style="justify-self: flex-end"
      name="Reasoning"
      placeholder="Please explain all the issues (this will only be saved if you 'Save, Send notifiction and Quit')"
      [model]="selectedInfractions.reasoning"
      (changed)="selectedInfractions.reasoning = $event"
    ></app-input>
  </div>
  <label style="justify-self: flex-end"
    ><i style="color: black"
      >Click on an issues button to see the issues</i
    ></label
  >

  <div
    class="actions staff-info-footer"
    style="justify-content: stretch; align-items: stretch"
  >
    <button
      color="accent"
      class="reactive-button-title green"
      type="button"
      (click)="attemptClose(modal, 'savenotify')"
    >
      Save, Send Notification and Quit
    </button>
    <button
      color="accent"
      class="reactive-button-title green"
      type="button"
      (click)="modal.close('save')"
    >
      Save and Quit
    </button>
    <button
      color="accent"
      class="reactive-button-title red"
      type="button"
      (click)="modal.close('quit')"
    >
      Quit
    </button>
    <button
      color="accent"
      class="reactive-button-title orange"
      type="button"
      (click)="modal.dismiss('cancel')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #BlockedConfirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Are you sure you want to block {{ selectedStaff | name : "full" }} from
      using their wallet to drink?
    </h4>
  </div>

  <div class="modal-body">
    <label style="color: black">Reason:</label>
    <input [(ngModel)]="blockingReason" type="text" />
  </div>

  <div class="modal-footer">
    <button
      [disabled]="!blockingReason"
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('block')"
    >
      Yes (Block)
    </button>

    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('cancel')"
    >
      Cancel (Dont Block)
    </button>
  </div>
</ng-template>

<ng-template #staffModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="color: black">
      Staff {{ selectedStaff | name : "full" }}
    </h4>
  </div>

  <div class="modal-content">
    <label style="color: black">Target Days Per Week:</label>
    <input
      [(ngModel)]="selectedStaff.target"
      type="number"
      min="0"
      max="7"
      step="1"
    />

    <br />
    <label style="color: black">Can Use Wallet To Drink:</label>
    <app-input
      [model]="
        selectedStaff.isBlockedFromWallet == undefined ||
        !selectedStaff.isBlockedFromWallet
          ? true
          : false
      "
      type="checkbox"
      style="color: black"
      [titleClass]="'edit-desc-hidden'"
      (changed)="toggleWalletBlock(BlockedConfirmation)"
    ></app-input>
    <span style="color: black" *ngIf="selectedStaff.isBlockedFromWallet">
      Current Reason: {{ selectedStaff.blockedReason }}
    </span>

    <br />
    <label style="color: black">Role:</label>
    <select
      [ngbTooltip]="'Only management can change this'"
      [disabled]="auth.accessUser.access <= 3"
      [(ngModel)]="selectedStaff.role"
      style="color: black"
    >
      <option *ngFor="let r of roles; let i = index" [value]="i">
        {{ r }}
      </option>
    </select>

    <label style="color: black">Custom User Color:</label>
    <input [(ngModel)]="selectedStaff.userColor" type="color" />
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
    >
      Save and Quit
    </button>

    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('cancel')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-body">
    <h2 style="color: black">{{ reason.name | name : "full" }}</h2>
    <div style="color: black">{{ reason.reason }}</div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #schedule let-modal style="color: black !important">
  <div class="modal-header">
    <b>Schedule: </b> &nbsp; {{ selectedWeek[0].date | date : "EEE dd LLLL" }} -
    {{ selectedWeek[6].date | date : "EEE dd LLLL" }}
  </div>

  <div class="actions staff-info-header">
    <button
      (click)="page = 0"
      [class]="
        page == 0 ? 'green reactive-button-title' : 'reactive-button-title'
      "
    >
      Schedule
    </button>
    <button
      (click)="page = 2"
      [class]="
        page == 2 ? 'green reactive-button-title' : 'reactive-button-title'
      "
    >
      Changes
    </button>
    <button
      (click)="page = 1"
      [class]="
        page == 1 ? 'green reactive-button-title' : 'reactive-button-title'
      "
    >
      Keys / Info
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="page == 0">
      <div id="week-requests" class="week-requests" #weekRequests>
        <div class="week-staff" style="">
          <div class="weekname" style="width: 260px">
            <div
              class="day-request"
              ngbTooltip="Role (New, Competent, Social Hygiene)"
              style="margin: 0px 4px; border: transparent !important"
              [style.background-color]="'transparent'"
            >
              <i class="fas fa-ranking-star"></i>
            </div>
            <div style="font-size: x-small; width: 140px">Name</div>
            <div
              ngbTooltip="Targeted Shifts Per Week"
              style="font-size: x-small"
            >
              Target
            </div>
          </div>

          <div
            *ngFor="let request of selectedWeek; let i = index"
            [ngClass]="{ dayofweek: i === selectedDay }"
            class="weekday"
            style="white-space: nowrap"
            tooltipClass="white-space-pre-line"
            triggers="manual"
            #t="ngbTooltip"
            (click)="t.open()"
            [ngbTooltip]="scheduleTarget[i].pretty()"
          >
            {{ request.date | date : "EEE" }} &nbsp;
            <b>{{ request.date | date : "dd" }}</b>
          </div>
        </div>
        <div class="week-staff" *ngFor="let st of staff">
          <div class="weekname" style="width: 260px">
            <div
              class="day-request"
              style="
                margin: 0px 4px;

                --fa-primary-color: #eeeeee;
                --fa-secondary-color: #2e2e2e;
              "
              [ngbTooltip]="st.role | scheduleCompetency : true | capitalize"
              [style.background-color]="st.userColor"
            >
              <i
                style="padding-top: 2px"
                [class]="st.role | scheduleCompetency"
              ></i>
            </div>
            <div
              (click)="openStaffModal(staffModal, st)"
              style="
                white-space: nowrap;
                word-wrap: unset;
                overflow: hidden;
                width: 140px;
              "
            >
              {{ st | name : "short" }}
            </div>
            <div
              class="target-container"
              [class.red]="
                selectedWeek[0].requests[st.uid].target !=
                  selectedWeek[0].requests[st.uid].mainTarget ||
                selectedWeek[0].requests[st.uid].reason != ''
              "
              (click)="
                openReason(
                  content1,
                  st.uid,
                  selectedWeek[0].requests[st.uid].reason
                )
              "
            >
              {{ selectedWeek[0].requests[st.uid].target }} /
              {{ selectedWeek[0].requests[st.uid].mainTarget }}
            </div>
          </div>

          <div
            *ngFor="let d of WEEKDAYS; let i = index"
            [ngClass]="{ dayofweek: i === selectedDay }"
            class="weekday"
          >
            <div
              *ngIf="
                selectedWeek[i].requests && selectedWeek[i].requests[st.uid]
              "
              class="day-request"
              [ngClass]="{
                red: selectedWeek[i].requests[st.uid].request === 0,
                orange: selectedWeek[i].requests[st.uid].request === 1,
                green: selectedWeek[i].requests[st.uid].request === 2,
                gray: selectedWeek[i].requests[st.uid].request === -1,
                today:
                  selectedWeek[i].requests[st.uid].request === 2 &&
                  i === selectedDay
              }"
              (click)="staffRequestClicked(st, selectedWeek[i])"
            >
              <div
                *ngIf="contains(selectedWeek[i].schedule.staff, st.uid)"
                class="working"
              >
                w
              </div>
            </div>
            <div
              class="day-select-container"
              *ngIf="contains(selectedWeek[i].schedule.staff, st.uid)"
            >
              <select
                class="day-select"
                [disabled]="selectedWeek[i].future"
                [ngModel]="getStartTime(selectedWeek[i].schedule, st.uid)"
                (change)="timeChanged(selectedWeek[i].schedule, st.uid, $event)"
              >
                <option value="1">1pm</option>
                <option value="2">2pm</option>
                <option value="3">3pm</option>
                <option value="4">4pm</option>
                <option value="5">5pm</option>
                <option value="6">6pm</option>
                <option value="7">7pm</option>
                <option value="8">8pm</option>
                <option value="9">9pm</option>
                <option value="10">10pm</option>
                <option value="11">11pm</option>
                <option value="12">12am</option>
              </select>
            </div>
          </div>
        </div>

        <div class="week-staff">
          <!--Totals -->
          <div class="weekname" style="width: 260px">
            <div style="width: 30px; height: 30px; border-radius: 50px"></div>
            <div style="font-size: x-small; width: 140px"></div>
            <div style="font-size: x-small">Working / Target</div>
          </div>
          <div
            *ngFor="let d of WEEKDAYS; let i = index"
            class="weekday"
            style="border-color: transparent"
          >
            <div
              class="day-request"
              style="
                border-radius: 50px;
                --fa-primary-color: #eeeeee;
                --fa-secondary-color: #2e2e2e;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              [ngClass]="{
                red: selectedWeek[i].schedule?.times?.length != scheduleTarget[i].numberOfStaff,
                orange: !selectedWeek[i].schedule?.times?.length,
                green: selectedWeek[i].schedule?.times?.length == scheduleTarget[i].numberOfStaff,
              }"
            >
              <i
                style="padding-right: 1px"
                [class]="calculateClass(i) | scheduleCompetency"
              ></i>
            </div>
            <div>
              &nbsp; {{ selectedWeek[i].schedule?.times?.length }}/{{
                scheduleTarget[i].numberOfStaff
              }}
            </div>
          </div>
        </div>
      </div></ng-container
    >

    <ng-container *ngIf="page == 1">
      <div class="request-key-container">
        <div class="day-request red"></div>
        <div><b>Can't </b> Work</div>
      </div>

      <div class="request-key-container">
        <div class="day-request orange"></div>
        <div><b>Maybe </b> Possible</div>
      </div>
      <div class="request-key-container">
        <div class="day-request green"></div>
        <div><b>Perfect </b> Day</div>
      </div>

      <div class="request-key-container">
        <div class="day-request gray"></div>
        <div><b>N</b>ot <b>A</b>nswered</div>
      </div>
      <div class="request-key-container">
        <div class="day-request gray">
          <div class="working">w</div>
        </div>
        <div><b>Scheduled </b> To Work</div>
      </div>
      <div class="divider"></div>
      <div class="request-key-container">
        <div class="day-request gold">
          <i [class]="'social hygiene' | scheduleCompetency"></i>
        </div>
        <div><b>Social Hygiene</b></div>
      </div>
      <div class="request-key-container">
        <div class="day-request silver">
          <i [class]="'competent' | scheduleCompetency"></i>
        </div>
        <div><b>Competent</b></div>
      </div>
      <div class="request-key-container">
        <div class="day-request bronze">
          <i [class]="'new' | scheduleCompetency"></i>
        </div>
        <div><b>Beginner</b></div>
      </div>
    </ng-container>
    <ng-container *ngIf="page == 2"
      ><div class="legend changes-box" style="">
        <div *ngFor="let change of changes">
          {{ change.userName }} - {{ change.dateString }}
        </div>
      </div></ng-container
    >
  </div>

  <div class="actions staff-info-header">
    <button
      color="accent"
      class="reactive-button-title green"
      [disabled]="!edits"
      (click)="cancel(confirmation)"
    >
      Save
    </button>

    <button
      color="warn"
      class="reactive-button-title"
      [ngClass]="{ orange: !edits, red: !!edits }"
      (click)="closeConfirmation()"
    >
      {{ edits ? "Cancel" : "Close" }}
    </button>

    <button
      color="warn"
      class="reactive-button-title orange"
      (click)="export()"
    >
      Export
    </button>
  </div>
</ng-template>
<div class="calendar-header">
  <div style="flex-grow: 1"></div>
  <button class="calendar-button" (click)="onPreviousMonth(confirmation)">
    <i class="fas fa-chevron-left"></i>
  </button>
  <div class="calendar-month">
    {{ calendarCreator.getMonthName(monthNumber) }} {{ year }}
  </div>
  <button class="calendar-button" (click)="onNextMonth(confirmation)">
    <i class="fas fa-chevron-right"></i>
  </button>
  <div style="flex-grow: 1"></div>
</div>
<div class="calendar">
  <div style="flex: 0 1 0%; min-height: auto !important; height: 1.7rem">
    <div
      *ngFor="let weekDay of weekDaysName"
      class="day day-name"
      style="min-height: auto !important"
    >
      <div>{{ weekDay }}</div>
    </div>
  </div>
  <div
    class="calendar-week"
    [ngClass]="{ 'selected-week': isSelectedDay(monthDays[0]) }"
  >
    <div
      *ngFor="let day of monthDays.slice(0, 7); let i = index"
      class="day"
      (click)="day.number && dayClicked(confirmation, day, schedule)"
    >
      <div>
        {{ day.number }}
      </div>
      <div class="schedule-circle-holder">
        <div
          class="schedule-circle"
          *ngFor="let st of getScheduled(day.number, day.monthIndex, day.year)"
          [style.background-color]="st.userColor"
        >
          <i
            [class]="st.uid | scheduleCompetency : false : true : this.staff"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div
    class="calendar-week"
    [ngClass]="{ 'selected-week': isSelectedDay(monthDays[7]) }"
  >
    <div
      *ngFor="let day of monthDays.slice(7, 14); let i = index"
      class="day"
      (click)="dayClicked(confirmation, day, schedule)"
    >
      <div>
        {{ day.number }}
      </div>
      <div class="schedule-circle-holder">
        <div
          class="schedule-circle"
          *ngFor="let st of getScheduled(day.number, day.monthIndex, day.year)"
          [style.background-color]="st.userColor"
        >
          <i
            [class]="st.uid | scheduleCompetency : false : true : this.staff"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div
    class="calendar-week"
    [ngClass]="{ 'selected-week': isSelectedDay(monthDays[14]) }"
  >
    <div
      *ngFor="let day of monthDays.slice(14, 21); let i = index"
      class="day"
      (click)="dayClicked(confirmation, day, schedule)"
    >
      <div>
        {{ day.number }}
      </div>
      <div class="schedule-circle-holder">
        <div
          class="schedule-circle"
          *ngFor="let st of getScheduled(day.number, day.monthIndex, day.year)"
          [style.background-color]="st.userColor"
        >
          <i
            [class]="st.uid | scheduleCompetency : false : true : this.staff"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div
    class="calendar-week"
    [ngClass]="{ 'selected-week': isSelectedDay(monthDays[21]) }"
  >
    <div
      *ngFor="let day of monthDays.slice(21, 28); let i = index"
      class="day"
      (click)="dayClicked(confirmation, day, schedule)"
    >
      <div>
        {{ day.number }}
      </div>
      <div class="schedule-circle-holder">
        <div
          class="schedule-circle"
          *ngFor="let st of getScheduled(day.number, day.monthIndex, day.year)"
          [style.background-color]="st.userColor"
        >
          <i
            [class]="st.uid | scheduleCompetency : false : true : this.staff"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div
    class="calendar-week"
    [ngClass]="{ 'selected-week': isSelectedDay(monthDays[28]) }"
  >
    <div
      *ngFor="let day of monthDays.slice(28, 35); let i = index"
      class="day"
      (click)="dayClicked(confirmation, day, schedule)"
    >
      <div>
        {{ day.number }}
      </div>
      <div class="schedule-circle-holder">
        <div
          class="schedule-circle"
          *ngFor="let st of getScheduled(day.number, day.monthIndex, day.year)"
          [style.background-color]="st.userColor"
        >
          <i
            [class]="st.uid | scheduleCompetency : false : true : this.staff"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="monthDays.length > 35"
    class="calendar-week"
    [ngClass]="{ 'selected-week': isSelectedDay(monthDays[35]) }"
  >
    <div
      *ngFor="let day of monthDays.slice(35, 42); let i = index"
      class="day"
      (click)="dayClicked(confirmation, day, schedule)"
    >
      <div>
        {{ day.number }}
      </div>
      <div class="schedule-circle-holder">
        <div
          class="schedule-circle"
          *ngFor="let st of getScheduled(day.number, day.monthIndex, day.year)"
          [style.background-color]="st.userColor"
        >
          <i
            [class]="st.uid | scheduleCompetency : false : true : this.staff"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>
