import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Declaration } from "src/app/Classes/declaration";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { DeclarationModalComponent } from "../declaration-modal/declaration-modal.component";

@Component({
  selector: "app-declarations",
  templateUrl: "./declarations.component.html",
  styleUrls: ["./declarations.component.scss"],
})
export class DeclarationsComponent {
  declarations: Declaration[] = [];
  sortBy: {
    key: string;
    reverse: boolean;
  } = {
    key: "declarationDate",
    reverse: true,
  };
  constructor(
    public loading: LoadingService,
    public alert: AlertsService,
    public firebase: StaffAppDatabase,
    public modal: NgbModal
  ) {
    this.getData();
  }

  async getData() {
    this.declarations = [];
    this.loading.show();
    this.declarations = await this.firebase
      .getDeclarations()
      .then()
      .catch((error) => {
        this.alert.nextEmit(AlertsService.error("Error", error));
        return [];
      });
    console.log(this.declarations);
    this.loading.hide();
  }

  setSortBy(key, changeDirection = true) {
    console.log("sortuing on", key, changeDirection);
    if (this.sortBy.key === key && !changeDirection) {
    } else if (this.sortBy.key === key) {
      this.sortBy.reverse = !this.sortBy.reverse;
    } else {
      this.sortBy.key = key;
      this.sortBy.reverse = false;
    }

    this.declarations = this.declarations.sort((a, b) => {
      if (this.sortBy.key.includes(".length")) {
        var key = this.sortBy.key.split(".")[0];
        if (a[key].length < b[key].length) {
          return this.sortBy.reverse ? 1 : -1;
        } else if (a[key].length > b[key].length) {
          return this.sortBy.reverse ? -1 : 1;
        } else {
          return 0;
        }
      }

      if (a[this.sortBy.key] === undefined || a[this.sortBy.key] === null) {
        return 1;
      }
      if (b[this.sortBy.key] === undefined || b[this.sortBy.key] === null) {
        return -1;
      }

      if (a[this.sortBy.key] < b[this.sortBy.key]) {
        return this.sortBy.reverse ? 1 : -1;
      } else if (a[this.sortBy.key] > b[this.sortBy.key]) {
        return this.sortBy.reverse ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  openItem(item) {
    var modal = this.modal.open(DeclarationModalComponent, {
      size: "xl",
    });

    modal.componentInstance.declaration = item;

    modal.result.then(
      (result) => {
        this.getData();
      },
      (dismiss) => {
        this.getData();
      }
    );
  }
}
