<div class="my-table-header" style="padding: 0px 14px">
  <div class="my-table-item-sml">
    <app-id-circle [id]="1"></app-id-circle>
  </div>
  <div class="my-table-item-large" (click)="setSortBy('declarationName')">
    <div>Name</div>
    <i
      [style.display]="sortBy.key == 'declarationName' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="my-table-item-imp" (click)="setSortBy('status')">
    <div>Status</div>
    <i
      [style.display]="sortBy.key == 'status' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="my-table-item-imp" (click)="setSortBy('declarationDate')">
    <div>Date</div>
    <i
      [style.display]="sortBy.key == 'declarationDate' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="my-table-item-imp" (click)="setSortBy('amount')">
    <div>Amount</div>
    <i
      [style.display]="sortBy.key == 'amount' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>

  <div class="my-table-item-imp" (click)="setSortBy('receipts.length')">
    <div>Receipts</div>
    <i
      [style.display]="sortBy.key == 'receipts.length' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>

  <div class="my-table-item-imp" (click)="setSortBy('user')">
    <div>User</div>
    <i
      [style.display]="sortBy.key == 'user' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
</div>

<div class="my-table">
  <div
    *ngFor="let s of declarations"
    class="my-table-item"
    [ngClass]="{
      redBorder: s.status == 'created',
      orangeBorder: s.status == 'submitted',
      greenBorder: s.status == 'approved'
    }"
    (click)="openItem(s)"
  >
    <div class="my-table-item-sml"></div>
    <div class="my-table-item-large">
      {{ s.declarationName }}
    </div>

    <div class="my-table-item-imp">
      {{ s.status | capitalize }}
    </div>
    <div class="my-table-item-imp">
      {{ s.declarationDate | date : "yyyy-MM-dd" }}
    </div>
    <div class="my-table-item-imp">
      {{ s.amount | currency : "EUR" }}
    </div>
    <div class="my-table-item-imp">
      {{ s.receipts.length }}
    </div>

    <div class="my-table-item-imp">
      {{ s.userName }}
    </div>
  </div>
</div>

<div class="actions staff-info-header" style="justify-content: space-evenly">
  <div
    style="display: flex; flex-direction: row; align-items: center; gap: 5px"
  >
    <app-id-circle [backgroundColor]="'green'"></app-id-circle>
    <label for="">Approved</label>
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center; gap: 5px"
  >
    <app-id-circle [backgroundColor]="'#ffcc00'"></app-id-circle
    ><label for="">Submitted</label>
  </div>
  <div
    style="display: flex; flex-direction: row; align-items: center; gap: 5px"
  >
    <app-id-circle [backgroundColor]="'red'"></app-id-circle
    ><label for="">Created but not submitted</label>
  </div>
</div>
